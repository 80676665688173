import * as Actions from '../actions';


const initialState = {
	selectedSourceAgent: "",
	agent2AgentSubsData: [],//akisx
	deleteAgent2AgentSub: null,
	agentSubData: null,
	isMultiAgent2AgentSubs: false,
	searchForTargetAgents: ""
};

const agent2AgentSubsReducer = (state = initialState, action) => {
	//console.log("action type:"+JSON.stringify(action));
	switch (action.type) {
		case Actions.SET_SEARCH_FOR_TARGET_AGENTS : {
			return {
				...state,
				searchForTargetAgents: action.searchForTargetAgents
			}
		}
		case Actions.IS_MULTI_AGENT2AGENT: {
			return {
				...state,
				isMultiAgent2AgentSubs: action.payload
			}
		}
		case Actions.GET_AGENT2AGENTSUBS: {
			console.log("GET_AGENT2AGENTSUBS reducer ", action.payload)
			if(action.payload) {
				return {
					...state,
					agent2AgentSubsData: action.payload
				};
			}else {
				return {
					...state
				}
			}
			
		}
		case Actions.SET_SELECTED_SOURCE_AGENT: {
			return {
				...state,
				selectedSourceAgent: action.selectedSourceAgent
			};
		}
		case Actions.CLEAR_SELECTED_SOURCE_AGENT: {
			return {
				...state,
				selectedSourceAgent: action.selectedSourceAgent,
				agent2AgentSubsData: [] //delete data when selection is none
			};
		}
		case Actions.SAVE_AGENT2AGENTSUBS: {
			let agentsSub= state.agent2AgentSubsData
			for (let i = 0; i < action.payload.length; i++) {
				for (let j = 0; j < agentsSub.length; j++) {
					if (agentsSub[j]._id === action.payload[i]._id) {
						agentsSub[j] = action.payload[i];
						break;
					}
				}
			}
			return {
				...state,
				agent2AgentSubsData: agentsSub,
				
			};
		}
		case Actions.SAVE_MULTI_AGENTS2AGENT_SUBS : {
			//console.log("SAVE_MULTI_AGENTS2AGENT_SUBS ", action)
			let newSubs = []
			let subObject = action.payload.agentSubscription
			let targetIdsList = action.targetIdsList
			let subIdsList = action.payload.targetIdsList			
			//console.log("SAVE_MULTI_AGENTS2AGENT_SUBS2", subObject, targetIdsList, subIdsList)
			
			targetIdsList.map((id,i)=>{
				let sub = Object.assign({},subObject)
				sub.targetAgentId = id
				sub._id = subIdsList[i]
				//console.log("subbbbbbbbbbbbbb ",sub)
				newSubs.push(sub)
			})
			console.log("SAVE_MULTI_AGENTS2AGENT_SUBS 3 ", newSubs, state.agent2AgentSubsData.concat(newSubs))

			return {
				...state,
				agent2AgentSubsData: state.agent2AgentSubsData.concat(newSubs)
			};			
			
		}
		case Actions.DELETE_AGENTSUB: {
			return {
				...state,
				agent2AgentSubsData: state.agent2AgentSubsData.filter(({_id}) => _id !== action.payload._id)
			};
			
		}
		case Actions.NEW_AGENTSUB: {
			console.log("NEW_AGENTSUB ", action.payload)

			if(state.selectedSourceAgent !== "") {
				return {
					...state,
					agent2AgentSubsData: state.agent2AgentSubsData.concat(action.payload)
				};
			}else {
				console.log("NEW_AGENTSUB 2", action.payload)
				return {
					...state
				};
			}
			
		}
		case Actions.GET_AGENTSUB: {
			return {
				...state,
				agentSubData: action.payload
			};
		}
		case Actions.CLEAR_AGENT2AGENTSUBS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default agent2AgentSubsReducer;
