import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const NEW_LICENSE = '[MANAGE-COMMAND APP] NEW LICENSE'; //akisx
export const GET_LICENSE = '[MANAGE-COMMAND APP] GET LICENSE';//akisx
export const GET_LICENSE_ITEMS = '[MANAGE-COMMAND APP] GET LICENSE ITEMS';//akisx
export const SAVE_LICENSE = '[MANAGE-COMMAND APP] SAVE LICENSE'; //akisx
export const DELETE_LICENSE = '[MANAGE-COMMAND APP] DELETE LICENSE'; //akisx
export const SET_LICENSE_DIALOG_STATUS = '[MANAGE-COMMAND APP] SET LICENSE DIALOG STATUS';
export const GET_LICENSE_DATA = '[LICENSES] GET LICENSE DATA';
export const CLEAR_LICENCE_STORE ='[LICENCES] CLEAR LICENCE STORE' 
export const GET_TENANT_ADMIN_LICENSE_DATA = '[LICENSES] GET TENANT ADMIN LICENSE DATA'

export function getTenantAdminLicenseData() {
	const request = axios.get('/EpttaAdmin/GetLicenseManagerStatus')

	return dispatch=>
		request.then(response=>
			dispatch({
				type: GET_TENANT_ADMIN_LICENSE_DATA,
				payload: response.data
			})
		).catch(e=>{
			if(e?.response && e.response?.data) {
				if(e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				}else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}
				
				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		})
}

export function setLicenseDialogStatus(status) {
	
	return {
		type: SET_LICENSE_DIALOG_STATUS,
		licendeDialogStatus: status
	}
}

export function clearLicenceStore() {
	return dispatch=>
		dispatch({
			type:CLEAR_LICENCE_STORE
		})
}

export function getLicenseData () {	
	const request = axios.get('EpttaTenantAdmin/GetLicenseManagerStatus')
	
	return dispatch => 
		request.then(response =>
			dispatch({
				type: GET_LICENSE_DATA,
				payload: response.data
			})	
		)
}

export function newLicense(data) {
	console.log("license newLicense")
	const request = axios.post('EpttaTenantAdmin/License', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: 'License Saved' }));

			return dispatch({
				type: NEW_LICENSE, 
				payload: response.data
			});
		}).catch(e => {
			if(e?.response && e.response?.data) {
				if(e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				}else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}
				
				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}

export function getLicenseItems(data) {
	console.log("license get items")

	const request = axios.get(`EpttaTenantAdmin/Tenants/Count/${data._id}`); //need fix
	
	return dispatch =>
		request.then(response => {
			//console.log("get agent items response: "+JSON.stringify(response.data));
			dispatch({
				type: GET_LICENSE_ITEMS,
				payload: response.data
			});
			window.confirm("Are you sure you wish to delete this License? Items Info: "+JSON.stringify(response.data)) &&
			dispatch(deleteLicense(data));
		});
}

export function getLicense(params) {
	console.log("license get license")

	// const request = axios.get(`EpttaTenantAdmin/License/${params.tenantsId}`);//need paris get by id

	// return dispatch =>
	// 	request.then(response =>
	// 		dispatch({
	// 			type: GET_LICENSE,
	// 			payload: response.data
	// 		})
	// 	);
	return ({
		type: GET_LICENSE,
		payload: {
			"_id": "1234214",
			"created": "",
			"expirtationDate": "",
			"maxUsers": "",
			"maxOnlineUsers":"",
			"maxOnlineCommandCenters":"",
			"description": "new",
			"id": "sdadasda",
			"lastUpdate": "",
			"name": "new"
		}
	})
}

export function saveLicense(data) {
	const request = axios.put('EpttaTenantAdmin/License', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: 'License Saved' }));
			//console.log("save response: " +JSON.stringify(response));

			return dispatch({
				type: SAVE_LICENSE,
				payload: data
			});
		}).catch(e => {
			if(e?.response && e.response?.data) {
				if(e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				}else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}
				
				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}

export function deleteLicense(data) {
	const request = axios.delete(`EpttaTenantAdmin/Tenant?licenseId=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: 'License Deleted' }));
			//console.log("delete response: " +JSON.stringify(response));

			return dispatch({
				type: DELETE_LICENSE,
				payload: data
			});
		})
}

export function newLicenseTemplate(licenseId) {
	console.log("license template")
	
	const data = {
		"_id": licenseId,
		"created": "",
		"expirtationDate": "",
		"maxUsers": "",
		"maxOnlineUsers":"",
		"maxOnlineCommandCenters":"",
		"maxCommandCenters":"",
		"description": "new",
		"id": licenseId,
		"lastUpdate": "",
		"name": "new"
	  }

	return {
		type: GET_LICENSE,
		payload: data
	};
}

