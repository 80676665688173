import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';
//import {useTranslation} from 'react-i18next';

export const GET_GEO_DATA = '[EPTTA-MAP APP] GET GEO DATA'; //akisx
export const SET_SELECTED_CHANNEL_FILTER = '[EPTTA-MAP APP] SET SELECTED CHANNEL FILTER';
export const SET_SELECTED_FOCUS_ON_MAP = '[EPTTA-MAP APP] SET SELECTED FOCUS ON MAP';
export const CLEAR_SELECTED_FOCUS_ON_MAP = '[EPTTA-MAP APP] CLEAR SELECTED FOCUS ON MAP';
export const SET_MAP_GEO_DIALOG_STATUS = '[EPTTA-MAP APP] SET MAP GEO DIALOG STATUS';
export const SET_MAP_DATA_FILTERS_STATUS = '[EPTTA-MAP APP] SET MAP DATA FILTERS STATUS';
export const UPDATE_GEO_ITEM_DATA = '[EPTTA-MAP APP] UPDATE GEO ITEM DATA';
export const DELETE_GEO_ITEM_DATA = '[EPTTA-MAP APP] DELETE GEO ITEM DATA';
export const SET_SHOW_CONTROLS = '[EPTTA-MAP APP] SET SHOW CONTROLS';
export const SET_MAX_CLUSTER_RADIUS = '[EPTTA-MAP APP] SET MAX CLUSTER RADIUS';
//export const CREATE_GEO_ITEM_DATA = '[EPTTA-MAP APP] CREATE GEO ITEM DATA';
export const SET_FILTERS_DATA = '[EPTTA-MAP APP] SET FILTERS DATA';
export const ADD_NEW_GEO_ITEM = '[EPTTA-MAP APP] ADD NEW GEO ITEM';
export const SET_RERENDER = '[EPTTA-MAP APP] SET RERENDER';
export const GET_TRACKING_HISTORY_DATA = '[EPTTA-MAP APP] GET TRACKING HISTORY DATA';
export const CLEAR_TRACKING_HISTORY_ITEM_DATA = '[EPTTA-MAP APP] CLEAR TRACKING HISTORY ITEM DATA'
export const SET_TRACKING_HISTORY_ACTIONS_DIALOG_STATUS = '[EPTTA-MAP APP] SET TRACKING HISTORY ACTIONS DIALOG STATUS'
export const SET_SHOW_TRACK_PLAYER = '[EPTTA-MAP APP] SET SHOW TRACK PLAYER'
export const SET_DYNAMIC_MAP_GROUP_ACTIONS_DIALOG_STATUS = '[EPTTA-MAP APP] SET DYNAMIC MAP GROUP ACTIONS DIALOG STATUS'
export const SET_DYNAMIC_CIRCLE_EVENT = '[EPTTA-MAP APP] SET DYNAMIC CIRCLE EVENT'
export const CLEAR_MAP_STORE = '[EPTTA-MAP APP] CLEAR MAP STORE'
export const SET_EPTTA_MAP = '[EPTTA-MAP APP] SET EPTTA MAP'
//const {t} = useTranslation('manageAgents');

export function getGeoData() {
	const request = axios.get(`EpttaAdmin/GeoFences`);
	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_GEO_DATA,
				payload: response.data
			})
		);
}

export function getTrackingHistory(startDateTime, endDateTime, targetAgentId) {
	const request = axios.get(`EpttaAgent/AgentLocations/LocationHistory/${targetAgentId}?from=${startDateTime}&to=${endDateTime}`)
	return dispatch =>
		request.then(response => {

			if (response.data[0]) {
				dispatch({
					type: GET_TRACKING_HISTORY_DATA,
					payload: response.data
				})
			} else {
				dispatch(showMessage({ variant: 'error', message: `NO_TRACKING_HISTORY_DATA` }));
				//dispatch(showMessage({ variant: 'error', message: `${t('NO_TRACKING_HISTORY_DATA')}` }));

			}

		}
		);
}

//Api Messages
export function createGeoItem(geoItemData, tenantId, pointIconFileName) {
	var newName;
	var newDescription;
	var newGeoItemData = geoItemData
	let autonGenString = Math.random().toString(36).substring(7)

	if (newGeoItemData.geometry.type === "Polygon") {
		newName = "Polygon_" + autonGenString
		newDescription = "New Polygon"
		newGeoItemData.properties.name = newName
		newGeoItemData.properties.description = newDescription
		newGeoItemData.properties.fillColor = ""
	} else if (newGeoItemData.geometry.type === "LineString") {
		newName = "WayPoint_" + autonGenString
		newDescription = "New WayPoint"
		newGeoItemData.properties.name = newName
		newGeoItemData.properties.description = newDescription
	} else {
		if (newGeoItemData.properties.radius > 0) {

			//debugger;
			newName = "Circle_" + autonGenString
			newDescription = "New Circle"
			newGeoItemData.properties.name = newName
			newGeoItemData.properties.description = newDescription
			newGeoItemData.properties.fillColor = ""
			newGeoItemData.geometry.type = "Polygon"
			newGeoItemData.geometry.coordinates = newGeoItemData.geometry.coordinates //reverse 
		} else {
			newName = "POI_" + autonGenString
			newDescription = "New POI"
			newGeoItemData.properties.name = newName
			newGeoItemData.properties.description = newDescription
			newGeoItemData.properties.icon = "flag.svg" //or var pointIconFileName
		}
	}

	let fixedGeoItemData = {
		_id: "new",
		action: "",
		agentsInside: 0,
		created: "",
		tenantId: tenantId,
		name: newName,
		description: newDescription,
		features: {
			type: "FeatureCollection",
			features: [
				{
					type: "Feature",
					properties: newGeoItemData.properties,
					geometry: newGeoItemData.geometry
				}
			]
		},
		lastUpdate: ""
	}



	const request = axios.post(`EpttaAgent/GeoFences`, fixedGeoItemData);

	return dispatch =>
		request.then(response => {

		}
		);
}

export function updateGeoItem(geoItemData) {
	const request = axios.put(`EpttaAgent/GeoFences`, geoItemData);

	return dispatch =>
		request.then(response => {
		}
		);
}

export function deleteGeoItem(geoItem) {
	const request = axios.delete(`EpttaAgent/GeoFences/${geoItem._id}`);

	return dispatch =>
		request.then(response => {

		}
		);
}


export function setEpttaMap(epttaMap) {
	return {
		type: SET_EPTTA_MAP,
		payload: epttaMap
	}
}


//Socket messages
export function geofenceAdded(geofenceAddedData) {
	return {
		type: ADD_NEW_GEO_ITEM,
		payload: geofenceAddedData
	}
}

export function updateGeoItemFromSocket(geoItemData) {

	return dispatch =>
		dispatch({
			type: UPDATE_GEO_ITEM_DATA,
			payload: geoItemData,
		})
}

export function deleteGeoItemFromSocket(geoItem) {

	return dispatch => (
		dispatch({
			type: DELETE_GEO_ITEM_DATA,
			payload: geoItem
		})
	);
}



export function setFiltersData(_filtersData) {
	return {
		type: SET_FILTERS_DATA,
		filtersData: _filtersData
	}
}

export function setSelectedChannelFilter(channelId) {
	return dispatch =>
		dispatch({
			type: SET_SELECTED_CHANNEL_FILTER,
			selectedChannelId: channelId
		})
}

export function setMapDataFiltersStatus(status) {

	return dispatch =>
		dispatch({
			type: SET_MAP_DATA_FILTERS_STATUS,
			mapDataFiltersStatus: status
		})
}

export function setFocusOnMap(onMapData) {
	return dispatch =>
		dispatch({
			type: SET_SELECTED_FOCUS_ON_MAP,
			mapFocusedData: onMapData
		})
}

export function clearFocusOnMap() {
	return dispatch =>
		dispatch({
			type: CLEAR_SELECTED_FOCUS_ON_MAP,
		})
}

export function setMapDialogStatus(status) {
	return {
		type: SET_MAP_GEO_DIALOG_STATUS,
		mapGeoDialogStatus: status
	}
}

export function setShowControls(status) {

	return dispatch =>
		dispatch({
			type: SET_SHOW_CONTROLS,
			show: status
		})
}

export function setMaxClusterRadius(radius) {
	return dispatch =>
		dispatch({
			type: SET_MAX_CLUSTER_RADIUS,
			radius: radius
		})
}

export function setRerender(status) {
	return dispatch =>
		dispatch({
			type: SET_RERENDER,
			reRender: status
		})
}

export function clearTrackingHistory(trackingItemId) {
	return dispatch =>
		dispatch({
			type: CLEAR_TRACKING_HISTORY_ITEM_DATA,
			trackingItemId: trackingItemId
		})
}

export function setTrackingHistoryActionsDialogStatus(status) {
	return {
		type: SET_TRACKING_HISTORY_ACTIONS_DIALOG_STATUS,
		trackingHistoryStatus: status
	}
}

export function setShowTrackPlayer(status, trackingItemID) {
	return {
		type: SET_SHOW_TRACK_PLAYER,
		trackPlayer: status,
		trackingItemID: trackingItemID
	}
}

export function setDynamicMapGroupActionsDialogStatus(status) {
	return {
		type: SET_DYNAMIC_MAP_GROUP_ACTIONS_DIALOG_STATUS,
		dynamicMapGroupDialogStatus: status
	}
}

export function setDynamicCircleEvent(event) {

	return {
		type: SET_DYNAMIC_CIRCLE_EVENT,
		dynamicCircleEvent: event
	}
}

export function clearMapStore() {
	return {
		type: CLEAR_MAP_STORE,
	};
}