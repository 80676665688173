import * as Actions from '../actions';

const initialState = {
	selectedSourceAgent: "",
	selectedTargetChannel: "",
	channelsSubsData: null,//akisx
	deleteChannelSub: null,
	channelSubData: null,
	isMultiChannelsSubs: false
};

const channelsSubsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.IS_MULTI_CHANNELS_SUBS: {
		

			return {
				...state,
				isMultiChannelsSubs: action.payload
			}
		}
		case Actions.GET_CHANNELSSUBS: {
			return {
				...state,
				channelsSubsData: action.payload,
			};
			
		}
		case Actions.SET_SELECTED_CSOURCE_AGENT: {
			return {
				...state,
				selectedSourceAgent: action.selectedSourceAgent,
			};	
		}
		case Actions.SET_SELECTED_CTARGET_CHANNEL: {
			return {
				...state,
				selectedTargetChannel: action.selectedTargetChannel,
			};
			
		}
		case Actions.SAVE_CHANNELSSUBS: {
			let savedChannelSubs = action.payload
			var newSubsFilter = state.channelsSubsData

			for (let i = 0; i <savedChannelSubs.length; i++) {
				for (let j = 0; j < newSubsFilter.length; j++) {
					if (newSubsFilter[j]._id === savedChannelSubs[i]._id) {
						newSubsFilter[j] = savedChannelSubs[i];
						break;
					}
				}
			}

			return {
				...state,
				channelsSubsData: newSubsFilter,				
			};
		}
		case Actions.DELETE_CHANNELSUB: {
			let filter1 = state.channelsSubsData?.filter(({_id}) => _id !== action.payload._id)
			console.log("delete channelSub ", filter1)
			return {
				...state,
				channelsSubsData: filter1,
			};
			
		}
		case Actions.NEW_CHANNELSUB: {
			console.log("NEW_CHANNELSUB reducer ", action.payload)

			let concat1 = state.channelsSubsData.concat(action.payload)
			return {
				...state,
				channelsSubsData: concat1,
			};
		}
		case Actions.SAVE_MULTI_CHANNEL_SUBS:{
			console.log("SAVE_MULTI_CHANNEL_SUBS reducer ", action.payload)
			let newSubs = []

			let subObject = action.payload.channelSubscription
			let channelIdsList = action.channelIdsList
			let subIdsList = action.payload.channelIdsList			

			channelIdsList.map((id,i)=>{
				let sub = Object.assign({},subObject)
				sub.channelId = id
				sub._id = subIdsList[i]
				newSubs.push(sub)
			})			

			return{
				...state,
				channelsSubsData: state.channelsSubsData.concat(newSubs)
			}
		}
		case Actions.GET_CHANNELSUB: {
			return {
				...state,
				channelSubData: action.payload
			};
		}
		case Actions.CLEAR_CHANNEL_SUBS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default channelsSubsReducer;
