const locale = {
	APPLICATIONS: 'تطبيقات',
	DASHBOARDS: 'لوحات',
	MANAGEAGENTS: '????',
	AGENTSMENU: '',
	NEWAGENT: '',
	AGENTDETAILS: '',
	AGENTTOAGENTSUBS: '',
	CHANNELSUBS: '',
	GROUPSUBS: '',
	MANAGECHANNELS: '????',
	CHANNELSMENU: '',
	NEWCHANNEL: '',
	MANAGEGROUPS: ' ',
	GROUPSMENU: '',
	NEWGROUP: ' ',
	CHANNELDETAILS: '',
	TENANTSETTINGS: '',
	CALENDAR: 'التقويم',
	ECOMMERCE: 'التجارة الإلكترونية',
	ACADEMY: 'الأكاديمية',
	MAIL: 'بريد',
	TODO: 'لكى يفعل',
	FILE_MANAGER: 'مدير الملفات',
	CONTACTS: 'جهات الاتصال',
	CHAT: 'دردشة',
	SCRUMBOARD: 'مجلس',
	NOTES: 'ملاحظات',
};

export default locale;
