export const SET_CONFIRMATION_DIALOG_STATUS ='[MANAGE-COMMAND APP] SET CONFIRMATION DIALOG STATUS' 
export const SET_CONFIRMATION_ACTION_TYPE = '[MANAGE-COMMAND APP] SET CONFIRMATION ACTION TYPE'
export const SET_CONFIRMATION_DIALOG_CONTENT = '[MANAGE-COMMAND APP] SET CONFIRMATION DIALOG CONTENT'
export const SET_CONFIRMATION_SELECTED_ITEM = '[MANAGE-COMMAND APP] SET CONFIRMATION SELECTED ITEM'

export function setConfirmationDialogStatus(status) {
    return {
        type: SET_CONFIRMATION_DIALOG_STATUS,
        confirmationDialogStatus: status
    }
}

export function setConfirmationDialogContent(content) {
    return {
        type: SET_CONFIRMATION_DIALOG_CONTENT,
        confirmationDialogContent: content
    }
}

export function setConfirmationActionType(type){
    return {
        type : SET_CONFIRMATION_ACTION_TYPE,
        confirmationActionType : type
    }
}
export function setConfirmationSelectedItem(item){
    return{
        type: SET_CONFIRMATION_SELECTED_ITEM,
        confirmationSelectedItem: item
    }
}