import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	adminsData: null,//akisx
	searchText: '',
	adminData: null,
	delAdmin: null,
	adminDialogData:{
		id:"new",
		state:false
	},
	// adminDialogStatusState: false,
	// adminDialogStatusId: 'new'
};

const adminsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_ADMINS: {
			if(action.payload.length > 0) {				
				
				let sortetData = _.sortBy(action.payload,"name")
				sortetData.unshift(action.authUser)
				//console.log("sort channels by name before save: "+JSON.stringify(sortetData))
				return {
					...state,
					adminsData: sortetData
				};
			}else {

				return {
					...state,
					adminsData: [action.authUser]
				};
			}
		}
		case Actions.SET_ADMINS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_ADMIN: {
			//console.log("Save new admin! "+JSON.stringify(action.payload))
			//console.log("Clear admin list! "+JSON.stringify(state.adminData))
			return {
				...state,
				adminsData: state.adminsData.concat(action.payload)
			};
		}
		case Actions.GET_ADMIN: {
			return {
				...state,
				adminData: action.payload
			};
		}
		case Actions.SAVE_ADMIN: {
			//console.log("Update admin! "+JSON.stringify(action.payload))
			let data = state.adminsData.filter((data) => data._id !== action.payload._id)
			let data2 = data.concat(action.payload)
			return {
				...state,
				adminData: action.payload,
				adminsData: data2
				
			};
		}
		case Actions.DELETE_ADMIN: {
			return {
				...state,
				delAdmin: action,
				adminData: null,
				adminsData: state.adminsData.filter((data) => data._id !== action.payload._id)
			};
		}
		case Actions.ADMIN_DIALOG_DATA: {
			
			// return {
			// 	...state,
			// 	adminDialogStatusState: action.payload.state,
			// 	adminDialogStatusId: action.payload.id
			// }
			return {
				...state,
				adminDialogData: {
					state: action.payload.state,
					id: action.payload.id
				}
			}
		}
		case Actions.CLEAR_ADMINS: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default adminsReducer;
