import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	weatherEpttaMap: null,    
    weatherForecastAnalysisPoints: null,
};


const weatherForecastReducer = (state = initialState, action)=>{
    switch(action.type) {
        case Actions.SET_WEATHER_EPTTA_MAP : {
            return {
                ...state,
                weatherEpttaMap: action.weatherEpttaMap
            }
        }
        case Actions.GET_WEATHER_FORECAST_ANALYSIS_POINTS : {
            
            return {
                ...state,
                weatherForecastAnalysisPoints: action.weatherForecastAnalysisPoints,
            }
        }
        
        case Actions.CLEAR_WEATHER_FORECAST_STORE : {
            return initialState;
        }
        default : {
            return state;
        }
    }
}

export default weatherForecastReducer