import { authRoles } from 'react/app/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import ar from '../i18n/ar';
import en from '../i18n/en';
import tr from '../i18n/tr';
import gr from '../i18n/gr'; //Akisx

i18next.addResourceBundle('en', 'manageChannels', en);
i18next.addResourceBundle('tr', 'manageChannels', tr);
i18next.addResourceBundle('ar', 'manageChannels', ar);
i18next.addResourceBundle('gr', 'manageChannels', gr); //Akisx

const ChannelsAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/channels-management/channels/:channelId/:channelName?',
			component: React.lazy(() => import('./channels/Channels'))
		},
		{
			path: '/apps/channels-management/channels',
			component: React.lazy(() => import('./channels/Channels'))
		},
		{
			path: '/apps/channels-management/channelsSubsStatus',
			component: React.lazy(() => import('./channelsSubsStatus/ChannelsSubsStatus'))
		},
		// {
		// 	path: '/apps/channels-management/channelsSubs/:channelSubId/:channelSubName?',
		// 	component: React.lazy(() => import('./channelsSubs/ChannelSub'))
		// },
		// {
		// 	path: '/apps/channels-management/channelsSubs',
		// 	component: React.lazy(() => import('./channelsSubs/ChannelsSubs'))
		// },
		{
			path: '/apps/channels-management/channelsSubs/:channelSubId/:channelSubName?',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/channelsSubs/ChannelSub'))
		},
		{
			path: '/apps/channels-management/channelsSubs',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/channelsSubs/ChannelsSubs'))
		},
		{
			path: '/apps/channels-management',
			component: () => <Redirect to="/apps/channels-management/channels" />
		}
	]
};

export default ChannelsAppConfig;
