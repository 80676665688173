import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	// superAdminsData: [],//akisx
	superAdminsData: null,//akisx
	searchText: '',
	superAdminData: null,
	delSuperAdmin: null,
	superAdminDialogData: {
		id: "new",
		state:false
	},
	// superAdminDialogStatusState: false,
	// superAdminDialogStatusId: 'new'
};

const superAdminsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_SUPER_ADMINS: {
			if(action.payload.length > 0 ) {
                let myData = action.payload
                myData.unshift(action.authUser)
				return {
					...state,
					superAdminsData: myData
				};
			}else {

				return {
					...state,
					superAdminsData: [action.authUser]
				};
			}
		}
		case Actions.SET_SUPER_ADMINS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_SUPER_ADMIN: {
			//console.log("Save new superAdmin! "+JSON.stringify(action.payload))
			//console.log("Clear superAdmin list! "+JSON.stringify(state.superAdminData))
			return {
				...state,
				superAdminsData: state.superAdminsData.concat(action.payload)
			};
		}
		case Actions.GET_SUPER_ADMIN: {
			return {
				...state,
				superAdminData: action.payload
			};
		}
		case Actions.SAVE_SUPER_ADMIN: {
			//console.log("Update superAdmin! "+JSON.stringify(action.payload))
			let data = state.superAdminsData.filter((data) => data._id !== action.payload._id)
			let data2 = data.concat(action.payload)
			return {
				...state,
				superAdminData: action.payload,
				superAdminsData: data2
				
			};
		}
		case Actions.DELETE_SUPER_ADMIN: {
			return {
				...state,
				delSuperAdmin: action,
				superAdminData: null,
				superAdminsData: state.superAdminsData.filter((data) => data._id !== action.payload._id)
			};
		}
		case Actions.SUPPERADMIN_DIALOG_DATA: {
			
			return {
				...state,
				superAdminDialogData:{
					state: action.payload.state,
					id:action.payload.id
				}
			}
			// return {
			// 	...state,
			// 	superAdminDialogStatusState: action.payload.state,
			// 	superAdminDialogStatusId: action.payload.id
			// }
		}
		case Actions.CLEAR_SUPER_ADMINS_STORE: {
			return {
				...initialState
			}
		}
		default: {
			return state;
		}
	}
};

export default superAdminsReducer;
