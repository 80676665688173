import FuseLayoutConfigs from '@fuse/layouts/FuseLayoutConfigs';
import _ from '@lodash';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import * as AuthActions from 'react/app/auth/store/actions';
import * as Actions from 'react/app/store/actions';
import * as MyActions from 'react/app/main/apps/store/actions';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';//akisxs
import secureStorageKeys from 'react/app/auth/secureStorageKeys'
import { secureLocalStorage } from 'react/app/services/jwtService'
import { turnOnLogger } from 'react/app/App'


const useStyles = makeStyles(theme => ({
	root: {},
	formControl: {
		margin: '6px 0',
		width: '100%',
		'&:last-child': {
			marginBottom: 0
		}
	},
	group: {},
	formGroupTitle: {
		position: 'absolute',
		top: -10,
		left: 8,
		fontWeight: 600,
		padding: '0 4px',
		backgroundColor: theme.palette.background.paper
	},
	formGroup: {
		position: 'relative',
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: 2,
		padding: '12px 12px 0 12px',
		margin: '24px 0 16px 0',
		'&:first-of-type': {
			marginTop: 16
		}
	}
}));


var showChartsAttempts = 0;

function FuseSettings(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user.data);
	const upUser = useSelector(({ auth }) => auth.user.srvData);
	const themes = useSelector(({ fuse }) => fuse.settings.themes);
	const settings = useSelector(({ fuse }) => fuse.settings.current);
	const { t } = useTranslation('manageAgents');
	const tooltipState = useSelector(({ fuse }) => fuse.settings.tooltipState);
	const dgnaManagementState = useSelector(({ fuse }) => fuse.settings.dgnaManagementState);
	const showChartsState = useSelector(({ fuse }) => fuse.settings.showChartsState);
	const debuggerState = useSelector(({ fuse }) => fuse.settings.debuggerState);

	const [showChartsLogs, setShowChartsLogs] = useState(false)

	var base64 = require('base-64');
	const serverUrlKey = 'jwt_server_url'
	const classes = useStyles(props);
	let brand = process.env.REACT_APP_BRAND


	const [serverURL, setServerURL] = useState(getServerURL());//akisx

	useEffect(() => {
		let expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + 60);
		let theme = themes[settings.theme.main];

		document.cookie = `bgColor=${theme.palette.background.default};expires=${expirationDate.toUTCString()};path=/`;
		document.cookie = `secondaryMain=${theme.palette.secondary.main};expires=${expirationDate.toUTCString()};path=/`;

	}, [settings.theme, themes])


	function handleServerUrlChange(value) {
		setServerURL(value)
		//localStorage.setItem('jwt_server_url', value);
		secureLocalStorage.set(secureStorageKeys.serverUrlKey, value)
	}

	function getServerURL() {
		return secureLocalStorage.get(secureStorageKeys.serverUrlKey)
	}

	function handleChange(event) {
		const newSettings = _.set(
			_.merge({}, settings),
			event.target.name,
			event.target.type === 'checkbox' ? event.target.checked : event.target.value
		);

		/**
		 * If layout style changes,
		 * Reset Layout Configuration
		 */
		if (event.target.name === 'layout.style' && event.target.value !== settings.layout.style) {
			newSettings.layout.config = {};
		}

		if (user.role === 'guest') {
			dispatch(Actions.setDefaultSettings(newSettings));
		} else {
			dispatch(AuthActions.updateUserSettings(newSettings, upUser));
		}
	}

	const ThemeSelect = ({ value, name, handleThemeChange }) => {
		return (
			<Select className="w-full" value={value} onChange={handleThemeChange} name={name}>
				{Object.entries(themes).map(([key, val]) => (
					<MenuItem
						key={key}
						value={key}
						className="m-8 mt-0 rounded-lg"
						style={{
							backgroundColor: val.palette.background.default,
							color: val.palette.text.primary,
							border: `1px solid ${val.palette.divider}`
						}}
					>
						{_.startCase(key)}
						<div
							className="flex w-full h-8 block absolute bottom-0 left-0 right-0"
							style={{
								borderTop: `1px solid ${val.palette.divider}`
							}}
						>
							<div className="w-1/4 h-8" style={{ backgroundColor: val.palette.primary.main }} />
							<div className="w-1/4 h-8" style={{ backgroundColor: val.palette.secondary.main }} />
							<div className="w-1/4 h-8" style={{ backgroundColor: val.palette.error.main }} />
							<div className="w-1/4 h-8" style={{ backgroundColor: val.palette.background.paper }} />
						</div>
					</MenuItem>
				))}
			</Select>
		);
	};

	const LayoutSelect = () => (
		<FormControl component="fieldset" className={classes.formControl}>
			<FormLabel component="legend" className="text-14">
				{/* Style */}
			</FormLabel>

			<RadioGroup
				aria-label="Layout Style"
				name="layout.style"
				className={classes.group}
				value={settings.layout.style}
				onChange={handleChange}
			>
				{/* {Object.entries(FuseLayoutConfigs).map(([key, layout]) => (
					<FormControlLabel key={key} value={key} control={<Radio />} label={layout.title} />
				))} */}

			</RadioGroup>
		</FormControl>
	);

	function ServerURLSelect() {
		return (
			<FormControl component="fieldset" className={classes.formControl}>
				<FormLabel component="legend" className="text-14">
					Server
				</FormLabel>

				<Paper className="flex items-center w-full px-8 py-4 rounded-8" elevation={1}>
					<Input
						name="server"
						placeholder={'URL'}
						className="flex flex-1 w-full"
						disableUnderline
						fullWidth
						value={serverURL}
						onChange={ev => handleServerUrlChange(ev.target.value)}
					/>
				</Paper>
			</FormControl>
		)

	};

	function Debugger() {
		return (
			<FormControl component="fieldset" className={classes.formControl}>
				<FormLabel component="legend" className="text-14">
					Debbuger
				</FormLabel>

				<FormLabel className="flex items-center w-full mt-4 px-8 py-4 rounded-8" elevation={1}>
					<Switch
						color="secondary"
						onChange={ev => handleDebbugerStateChange(!debuggerState)}
						checked={debuggerState}
					/>
				</FormLabel>
			</FormControl>
		)
	}


	function handleShowChartsLogs() {

		showChartsAttempts = showChartsAttempts + 1

		if (showChartsAttempts === 3) {
			setShowChartsLogs(true)
			showChartsAttempts = 0
			dispatch(MyActions.enableMultiImportMapProviders(true))
		}
	}

	function AppVersion() {
		let appVersion = process.env.REACT_APP_VERSION

		return (
			<FormControl onClick={ev => handleShowChartsLogs()} component="fieldset" className={classes.formControl}>
				<FormLabel component="legend" className="text-14">
					Application Version
				</FormLabel>

				<FormLabel className="flex items-center w-full mt-4 px-8 py-4 rounded-8" elevation={1}>
					{appVersion}
				</FormLabel>
			</FormControl>
		)

	};

	const DirectionSelect = () => (
		<FormControl component="fieldset" className={classes.formControl}>
			<FormLabel component="legend" className="text-14">
				Direction
			</FormLabel>

			<RadioGroup
				aria-label="Layout Style"
				name="direction"
				className={classes.group}
				value={settings.direction}
				onChange={handleChange}
				row
			>
				<FormControlLabel key="rtl" value="rtl" control={<Radio />} label="RTL" />
				<FormControlLabel key="ltr" value="ltr" control={<Radio />} label="LTR" />
			</RadioGroup>
		</FormControl>
	);


	function handleTooltipChange(event) {
		dispatch(Actions.toolTipState(event.target.checked))
	}

	function handleDgnaManagement(event) {
		dispatch(Actions.dgnaManagementState(event.target.checked))
	}
	const getForm = (form, prefix) =>
		Object.entries(form).map(([key, formControl]) => {
			const target = prefix ? `${prefix}.${key}` : key;
			if (formControl.title !== "Mode" && formControl.title !== "Toolbar" && formControl.title !== "Footer" && formControl.title !== "Scrollable Area") {
				switch (formControl.type) {
					case 'radio': {
						return (
							<FormControl key={target} component="fieldset" className={classes.formControl}>
								<FormLabel component="legend" className="text-14">
									{formControl.title}
								</FormLabel>
								<RadioGroup
									aria-label={formControl.title}
									name={`layout.config.${target}`}
									className={classes.group}
									value={_.get(settings.layout.config, target)}
									onChange={handleChange}
									row={formControl.options.length < 4}
								>
									{formControl.options.map(opt => (
										<FormControlLabel
											key={opt.value}
											value={opt.value}
											control={<Radio />}
											label={opt.name}
										/>
									))}
								</RadioGroup>
							</FormControl>
						);
					}
					case 'switch': {
						return (
							<FormControl key={target} component="fieldset" className={classes.formControl}>
								<FormControlLabel
									classes={
										{
											// root: "flex-row-reverse justify-end pl-16"
										}
									}
									control={
										<Switch
											name={`layout.config.${target}`}
											checked={_.get(settings.layout.config, target)}
											onChange={handleChange}
											aria-label={formControl.title}
										/>
									}
									label={
										<FormLabel component="legend" className="text-14">
											{formControl.title}
										</FormLabel>
									}
								/>
							</FormControl>
						);
					}
					case 'group': {
						return (
							<div key={target} className={classes.formGroup}>
								<Typography className={classes.formGroupTitle} color="textSecondary">
									{formControl.title}
								</Typography>

								{getForm(formControl.children, key)}
							</div>
						);
					}
					default: {
						return '';
					}
				}
			}

		});

	function LayoutConfig() {
		const { form } = FuseLayoutConfigs[settings.layout.style];
		return getForm(form);
	}

	function handleShowChartsChange(ev) {
		dispatch(Actions.showChartsState(ev.target.checked))
	}
	function handleDebbugerStateChange(checked) {

		dispatch(Actions.debuggerState(checked))
		turnOnLogger(checked)
	}

	return (
		<div className={classes.root}>
			{upUser?.user?._id ? null : ServerURLSelect()}

			{upUser?.user?._id &&
				<>
					<div className={classes.formGroup}>
						{/* <Typography className={classes.formGroupTitle} color="textSecondary">
					Layout
				</Typography>

				<LayoutSelect /> */}

						<LayoutConfig />

						<Typography className="my-16 text-12 italic" color="textSecondary">
							*Not all option combinations are available
						</Typography>
					</div>

					{brand !== "protecting" && brand !== "active" && brand !== "nova" && brand !== "systelios" &&
						<div className={clsx(classes.formGroup, 'pb-16')}>
							<Typography className={classes.formGroupTitle} color="textSecondary">
								Theme
							</Typography>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend" className="text-14">
									Main
								</FormLabel>
								<ThemeSelect value={settings.theme.main} name="theme.main" handleThemeChange={handleChange} />
							</FormControl>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend" className="text-14">
									Navbar
								</FormLabel>
								<ThemeSelect value={settings.theme.navbar} name="theme.navbar" handleThemeChange={handleChange} />
							</FormControl>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend" className="text-14">
									Toolbar
								</FormLabel>
								<ThemeSelect value={settings.theme.toolbar} name="theme.toolbar" handleThemeChange={handleChange} />
							</FormControl>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend" className="text-14">
									Footer
								</FormLabel>
								<ThemeSelect value={settings.theme.footer} name="theme.footer" handleThemeChange={handleChange} />
							</FormControl>
						</div>
					}

					<FormControl component="fieldset" className={classes.formControl}>
						<FormLabel component="legend" className="text-14" color="secondary">
							{t("ENABLEDGNA")}
						</FormLabel>
						<Switch
							onClick={handleDgnaManagement}
							aria-label="Tooltips"
							name="tooltips"
							checked={dgnaManagementState}
						/>
					</FormControl>

					<FormControl component="fieldset" className={classes.formControl}>
						<FormLabel component="legend" className="text-14" color="secondary" >
							{t("TOOLTIPS")}
						</FormLabel>
						<Switch
							onClick={handleTooltipChange}
							aria-label="Tooltips"
							name="tooltips"
							checked={tooltipState}
						/>
					</FormControl>

					<DirectionSelect />
				</>
			}
			<AppVersion />

			{showChartsLogs &&
				<>
					{user.role === "superAdmin" &&
						<FormControl component="fieldset" className={classes.formControl}>
							<FormLabel component="legend" className="text-14" color="secondary" >
								Show Charts
							</FormLabel>
							<Switch
								onClick={handleShowChartsChange}
								aria-label="ShowCharts"
								name="ShowCharts"
								checked={showChartsState}
							/>
						</FormControl>
					}
					<Debugger />
				</>
			}
		</div>
	);
}

export default React.memo(FuseSettings);
