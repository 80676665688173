import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	tenantsData: [],//akisx
	searchText: '',
	tenantData: null,
	delTenant: null,
	tenantDialogData:{
		state:false,
		id:"new"	
	},
	// tenantDialogStatusState : false,
	// tenantDialogStatusId : "new"
};

const tenantsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_TENANTS: {
			if(action.payload.length > 0) {
				const sortetData = _.sortBy(action.payload,"name")
				return {
					...state,
					tenantsData: sortetData
				};
			}else {

				return {
					...state,
					tenantsData: []
				};
			}
		}
		case Actions.SET_TENANTS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_TENANT: {
			let data = state.tenantsData.concat(action.payload)
			return {
				...state,
				tenantsData: data
			};
		}
		case Actions.GET_TENANT: {
			return {
				...state,
				tenantData: action.payload
			};
		}
		case Actions.SAVE_TENANT: {
			let data = state.tenantsData.filter((data) => data._id !== action.payload._id)
			let data2 = data.concat(action.payload)
			return {
				...state,
				tenantData: action.payload,
				tenantsData: data2
			};
		}
		case Actions.DELETE_TENANT: {
			return {
				...state,
				delTenant: action,
				tenantData: null,
				tenantsData: state.tenantsData.filter((data) => data._id !== action.payload._id)
			};
		}
		case Actions.TENANT_DIALOG_DATA : {
			
			return{
				...state,
				tenantDialogData:{
					state:action.payload.state,
					id:action.payload.id
				}
			}			
		}
		case Actions.CLEAR_TENANTS_STORE: {
			return {
				...initialState
			}
		}
		default: {
			return state;
		}
	}
};

export default tenantsReducer;
