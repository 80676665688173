export const SET_SETTINGS = '[SETTINGS] SET SETTINGS';
export const SET_DEFAULT_SETTINGS = '[SETTINGS] SET DEFAULT SETTINGS';
export const SET_INITIAL_SETTINGS = '[SETTINGS] SET INITIAL SETTINGS';
export const RESET_DEFAULT_SETTINGS = '[SETTINGS] RESET DEFAULT SETTINGS';
export const TOOLTIP_STATE = '[SETTINGS] TOOLTIP STATE';
export const DGNAMANAGEMENT_STATE = '[SETTINGS] DGNAMANAGEMENT STATE';
export const SHOWCHARTS_STATE = '[SETTINGS] SHOWCHARTS STATE';
export const DEBUGGER_STATE = '[SETTINGS] DEBUGGER STATE';
export const MAXDELAY_STATE = '[SETTINGS] MAXDELAY STATE';

export function setSettings(value) {
	return {
		type: SET_SETTINGS,
		value
	};
}

export function setDefaultSettings(value) {
	return {
		type: SET_DEFAULT_SETTINGS,
		value
	};
}

export function setInitialSettings() {
	return {
		type: SET_INITIAL_SETTINGS
	};
}

export function resetSettings(value) {
	return {
		type: RESET_DEFAULT_SETTINGS,
		value
	};
}


export function toolTipState (state) {	
	//console.log("tooltip state",state)
	return {
		type: TOOLTIP_STATE,
		payload: state
	}
}

export function dgnaManagementState (state) {	
	//console.log("tooltip state",state)
	return {
		type: DGNAMANAGEMENT_STATE,
		payload: state
	}
}

export function showChartsState(state){
	return {
		type: SHOWCHARTS_STATE,
		payload: state
	}
}

export function debuggerState (state) {
	
	// console.log("debugger state",state)

	return {
		type: DEBUGGER_STATE,
		payload: state
	}
}

export function maxDelayState(value){
	return {
		type: MAXDELAY_STATE,
		payload:value
	}
}
