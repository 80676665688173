import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import Icon from '@material-ui/core/Icon';

export const epttaDynamicChannelIcon = (iconColor, thisClassName = '') => {
    return (
        <SvgIcon className={thisClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path fill={iconColor} d="M12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11M4.22,4.22C5.65,2.79 8.75,3.43 12,5.56C15.25,3.43 18.35,2.79 19.78,4.22C21.21,5.65 20.57,8.75 18.44,12C20.57,15.25 21.21,18.35 19.78,19.78C18.35,21.21 15.25,20.57 12,18.44C8.75,20.57 5.65,21.21 4.22,19.78C2.79,18.35 3.43,15.25 5.56,12C3.43,8.75 2.79,5.65 4.22,4.22M15.54,8.46C16.15,9.08 16.71,9.71 17.23,10.34C18.61,8.21 19.11,6.38 18.36,5.64C17.62,4.89 15.79,5.39 13.66,6.77C14.29,7.29 14.92,7.85 15.54,8.46M8.46,15.54C7.85,14.92 7.29,14.29 6.77,13.66C5.39,15.79 4.89,17.62 5.64,18.36C6.38,19.11 8.21,18.61 10.34,17.23C9.71,16.71 9.08,16.15 8.46,15.54M5.64,5.64C4.89,6.38 5.39,8.21 6.77,10.34C7.29,9.71 7.85,9.08 8.46,8.46C9.08,7.85 9.71,7.29 10.34,6.77C8.21,5.39 6.38,4.89 5.64,5.64M9.88,14.12C10.58,14.82 11.3,15.46 12,16.03C12.7,15.46 13.42,14.82 14.12,14.12C14.82,13.42 15.46,12.7 16.03,12C15.46,11.3 14.82,10.58 14.12,9.88C13.42,9.18 12.7,8.54 12,7.97C11.3,8.54 10.58,9.18 9.88,9.88C9.18,10.58 8.54,11.3 7.97,12C8.54,12.7 9.18,13.42 9.88,14.12M18.36,18.36C19.11,17.62 18.61,15.79 17.23,13.66C16.71,14.29 16.15,14.92 15.54,15.54C14.92,16.15 14.29,16.71 13.66,17.23C15.79,18.61 17.62,19.11 18.36,18.36Z" /></svg>
        </SvgIcon>
    )
}

export const epttaManageAccountIcon = (iconColor, thisClassName = '') => {
    return (
        <SvgIcon className={thisClassName}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill={iconColor}><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><path d="M4,18v-0.65c0-0.34,0.16-0.66,0.41-0.81C6.1,15.53,8.03,15,10,15c0.03,0,0.05,0,0.08,0.01c0.1-0.7,0.3-1.37,0.59-1.98 C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26c-0.42-0.6-0.75-1.28-0.97-2H4z" /><path d="M10,12c2.21,0,4-1.79,4-4s-1.79-4-4-4C7.79,4,6,5.79,6,8S7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 c-1.1,0-2-0.9-2-2S8.9,6,10,6z" /><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" /></g></g></svg>
        </SvgIcon>
    )
}

export const epttaCommandLogo = (iconColor, thisClassName = '') => {
    return (

        <svg fill={iconColor} className={thisClassName} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 67 10" version="1.1">
            <g id="surface1">
                <path d="M 1.574219 0.375 C 1.320312 0.402344 1.128906 0.453125 0.917969 0.554688 C 0.542969 0.734375 0.265625 1.050781 0.167969 1.414062 L 0.136719 1.527344 L 0.132812 2.878906 C 0.128906 3.835938 0.132812 4.261719 0.140625 4.347656 C 0.195312 4.84375 0.550781 5.234375 1.113281 5.421875 C 1.40625 5.519531 1.046875 5.511719 4.921875 5.515625 L 8.367188 5.515625 L 8.367188 4.164062 L 5.253906 4.15625 L 2.140625 4.15625 L 2.03125 4.128906 C 1.742188 4.0625 1.59375 3.910156 1.535156 3.636719 C 1.515625 3.535156 1.511719 3.46875 1.511719 2.945312 C 1.511719 2.289062 1.515625 2.25 1.597656 2.085938 C 1.679688 1.914062 1.800781 1.816406 2.011719 1.757812 L 2.105469 1.730469 L 5.234375 1.722656 L 8.359375 1.71875 L 8.367188 0.367188 L 4.992188 0.371094 C 3.132812 0.371094 1.59375 0.375 1.574219 0.375 Z M 1.574219 0.375 " />
                <path d="M 10.972656 0.375 C 10.714844 0.402344 10.527344 0.453125 10.316406 0.554688 C 9.941406 0.734375 9.664062 1.050781 9.566406 1.414062 L 9.535156 1.527344 L 9.53125 2.878906 C 9.527344 3.835938 9.53125 4.261719 9.539062 4.347656 C 9.597656 4.84375 9.949219 5.234375 10.511719 5.421875 C 10.804688 5.519531 10.539062 5.511719 13.570312 5.515625 C 15.496094 5.515625 16.304688 5.515625 16.386719 5.503906 C 16.800781 5.457031 17.164062 5.296875 17.410156 5.042969 C 17.617188 4.839844 17.722656 4.613281 17.753906 4.328125 C 17.769531 4.179688 17.769531 1.734375 17.753906 1.585938 C 17.726562 1.328125 17.632812 1.121094 17.453125 0.914062 C 17.261719 0.699219 17.023438 0.550781 16.710938 0.453125 C 16.449219 0.371094 16.691406 0.375 13.707031 0.375 C 12.222656 0.375 10.992188 0.375 10.972656 0.375 Z M 15.765625 1.738281 C 16.023438 1.773438 16.199219 1.886719 16.292969 2.074219 C 16.375 2.242188 16.375 2.242188 16.375 2.941406 C 16.375 3.566406 16.375 3.574219 16.347656 3.667969 C 16.273438 3.933594 16.125 4.066406 15.847656 4.128906 L 15.730469 4.15625 L 11.550781 4.15625 L 11.433594 4.128906 C 11.144531 4.0625 10.992188 3.914062 10.933594 3.636719 C 10.914062 3.535156 10.914062 3.46875 10.914062 2.945312 C 10.914062 2.289062 10.914062 2.25 10.996094 2.085938 C 11.089844 1.890625 11.234375 1.789062 11.492188 1.742188 C 11.601562 1.722656 15.621094 1.71875 15.765625 1.738281 Z M 15.765625 1.738281 " />
                <path d="M 18.9375 1.046875 L 18.9375 1.722656 L 20.296875 1.722656 L 21.726562 3.621094 L 23.15625 5.515625 L 24.027344 5.515625 L 25.445312 3.644531 L 26.863281 1.769531 L 26.867188 3.644531 L 26.871094 5.515625 L 28.242188 5.515625 L 28.242188 0.367188 L 27.25 0.371094 L 26.253906 0.371094 L 24.929688 2.117188 C 24.199219 3.078125 23.601562 3.863281 23.597656 3.863281 C 23.59375 3.863281 22.992188 3.078125 22.261719 2.117188 L 20.929688 0.367188 L 18.9375 0.367188 Z M 18.9375 1.046875 " />
                <path d="M 29.417969 1.046875 L 29.417969 1.722656 L 30.777344 1.722656 L 32.203125 3.621094 L 33.636719 5.515625 L 34.503906 5.515625 L 35.925781 3.644531 L 37.34375 1.769531 L 37.347656 3.644531 L 37.347656 5.515625 L 38.722656 5.515625 L 38.722656 0.367188 L 37.726562 0.371094 L 36.734375 0.371094 L 35.40625 2.117188 C 34.679688 3.078125 34.078125 3.863281 34.074219 3.863281 C 34.070312 3.863281 33.46875 3.078125 32.738281 2.117188 L 31.40625 0.367188 L 29.417969 0.367188 Z M 29.417969 1.046875 " />
                <path d="M 41.285156 0.382812 C 41.066406 0.40625 40.839844 0.472656 40.640625 0.570312 C 40.332031 0.726562 40.074219 1.007812 39.976562 1.285156 C 39.9375 1.398438 39.90625 1.554688 39.90625 1.652344 L 39.90625 1.722656 L 46.746094 1.722656 L 46.746094 2.292969 L 39.894531 2.292969 L 39.894531 5.515625 L 41.28125 5.515625 L 41.28125 3.578125 L 46.746094 3.578125 L 46.746094 5.515625 L 48.132812 5.515625 L 48.132812 3.589844 C 48.132812 2.292969 48.128906 1.625 48.121094 1.550781 C 48.089844 1.28125 47.980469 1.058594 47.773438 0.847656 C 47.550781 0.621094 47.265625 0.476562 46.871094 0.394531 C 46.785156 0.375 46.566406 0.375 44.078125 0.375 C 42.59375 0.371094 41.335938 0.375 41.285156 0.382812 Z M 41.285156 0.382812 " />
                <path d="M 49.292969 2.941406 L 49.292969 5.515625 L 50.679688 5.515625 L 50.679688 1.722656 L 52.964844 1.722656 C 54.242188 1.722656 55.320312 1.730469 55.414062 1.734375 C 55.835938 1.765625 56.023438 1.890625 56.113281 2.210938 L 56.140625 2.300781 L 56.144531 3.910156 L 56.144531 5.515625 L 57.53125 5.515625 L 57.527344 3.523438 C 57.523438 1.359375 57.527344 1.480469 57.460938 1.292969 C 57.289062 0.796875 56.785156 0.449219 56.136719 0.382812 C 56.054688 0.371094 54.929688 0.367188 52.652344 0.367188 L 49.292969 0.367188 Z M 49.292969 2.941406 " />
                <path d="M 58.703125 1.046875 L 58.703125 1.722656 L 61.835938 1.726562 L 64.964844 1.730469 L 65.050781 1.757812 C 65.171875 1.789062 65.234375 1.820312 65.304688 1.871094 C 65.414062 1.953125 65.484375 2.074219 65.53125 2.234375 C 65.546875 2.304688 65.550781 2.375 65.550781 2.941406 C 65.550781 3.566406 65.550781 3.574219 65.523438 3.667969 C 65.449219 3.929688 65.304688 4.066406 65.027344 4.128906 L 64.917969 4.15625 L 62.503906 4.15625 L 60.089844 4.164062 L 60.089844 2.292969 L 58.703125 2.292969 L 58.703125 5.515625 L 62.085938 5.515625 C 64.324219 5.515625 65.503906 5.515625 65.570312 5.503906 C 65.988281 5.453125 66.339844 5.292969 66.589844 5.042969 C 66.792969 4.839844 66.898438 4.613281 66.929688 4.328125 C 66.945312 4.179688 66.945312 1.734375 66.929688 1.585938 C 66.902344 1.328125 66.808594 1.121094 66.628906 0.914062 C 66.4375 0.699219 66.199219 0.550781 65.890625 0.453125 C 65.621094 0.367188 65.949219 0.375 62.140625 0.371094 L 58.703125 0.367188 Z M 58.703125 1.046875 " />
                <path d="M 18.9375 3.90625 L 18.9375 5.515625 L 20.324219 5.515625 L 20.324219 2.292969 L 18.9375 2.292969 Z M 18.9375 3.90625 " />
                <path d="M 29.417969 3.90625 L 29.417969 5.515625 L 30.800781 5.515625 L 30.800781 2.292969 L 29.417969 2.292969 Z M 29.417969 3.90625 " />
                <path d="M 14.578125 6.390625 C 14.070312 6.472656 13.667969 6.765625 13.464844 7.207031 C 13.347656 7.464844 13.296875 7.746094 13.308594 8.050781 C 13.332031 8.488281 13.476562 8.851562 13.742188 9.117188 C 13.863281 9.238281 13.96875 9.3125 14.132812 9.394531 C 14.347656 9.5 14.503906 9.539062 14.777344 9.5625 C 15.207031 9.597656 15.613281 9.5 15.945312 9.277344 L 16.003906 9.234375 L 16.003906 8.027344 L 15.054688 8.027344 L 15.054688 8.148438 C 15.054688 8.21875 15.058594 8.289062 15.066406 8.304688 C 15.082031 8.351562 15.132812 8.359375 15.390625 8.359375 L 15.628906 8.359375 L 15.628906 9.035156 L 15.582031 9.0625 C 15.5 9.101562 15.328125 9.167969 15.246094 9.1875 C 15.066406 9.226562 14.773438 9.226562 14.589844 9.183594 C 14.167969 9.085938 13.875 8.78125 13.773438 8.324219 C 13.746094 8.195312 13.734375 7.914062 13.75 7.769531 C 13.789062 7.472656 13.890625 7.238281 14.070312 7.054688 C 14.261719 6.855469 14.5 6.761719 14.820312 6.746094 C 15.132812 6.730469 15.351562 6.789062 15.621094 6.953125 C 15.78125 7.050781 15.816406 7.039062 15.921875 6.867188 L 15.984375 6.765625 L 15.945312 6.730469 C 15.746094 6.554688 15.476562 6.433594 15.191406 6.390625 C 15.039062 6.367188 14.726562 6.371094 14.578125 6.390625 Z M 14.578125 6.390625 " />
                <path d="M 44.886719 6.382812 C 44.410156 6.457031 44.09375 6.835938 44.121094 7.296875 C 44.136719 7.519531 44.210938 7.683594 44.355469 7.816406 C 44.460938 7.914062 44.652344 8.003906 44.96875 8.109375 C 45.257812 8.203125 45.375 8.261719 45.464844 8.339844 C 45.582031 8.445312 45.621094 8.597656 45.582031 8.777344 C 45.546875 8.933594 45.457031 9.054688 45.320312 9.128906 C 45.203125 9.191406 45.128906 9.207031 44.96875 9.207031 C 44.734375 9.207031 44.59375 9.15625 44.378906 8.996094 C 44.3125 8.945312 44.246094 8.90625 44.230469 8.90625 C 44.152344 8.898438 44.132812 8.917969 44.046875 9.058594 L 43.984375 9.160156 L 44.058594 9.230469 C 44.230469 9.390625 44.425781 9.488281 44.675781 9.542969 C 44.804688 9.570312 45.113281 9.570312 45.230469 9.542969 C 45.613281 9.457031 45.867188 9.214844 45.960938 8.851562 C 45.992188 8.734375 46 8.484375 45.972656 8.382812 C 45.9375 8.226562 45.847656 8.097656 45.714844 7.996094 C 45.597656 7.90625 45.46875 7.84375 45.167969 7.742188 C 44.839844 7.625 44.722656 7.570312 44.628906 7.472656 C 44.570312 7.417969 44.550781 7.386719 44.535156 7.335938 C 44.472656 7.117188 44.574219 6.890625 44.78125 6.792969 C 44.875 6.746094 44.953125 6.730469 45.085938 6.730469 C 45.269531 6.730469 45.398438 6.769531 45.570312 6.882812 C 45.695312 6.964844 45.71875 6.972656 45.765625 6.953125 C 45.796875 6.9375 45.816406 6.914062 45.867188 6.8125 L 45.929688 6.691406 L 45.886719 6.65625 C 45.816406 6.59375 45.675781 6.503906 45.589844 6.46875 C 45.425781 6.402344 45.339844 6.382812 45.121094 6.378906 C 45.011719 6.375 44.90625 6.378906 44.886719 6.382812 Z M 44.886719 6.382812 " />
                <path d="M 50.496094 6.382812 C 50.019531 6.457031 49.703125 6.835938 49.730469 7.296875 C 49.746094 7.519531 49.820312 7.683594 49.964844 7.816406 C 50.070312 7.914062 50.261719 8.003906 50.578125 8.109375 C 50.863281 8.203125 50.984375 8.261719 51.074219 8.339844 C 51.191406 8.445312 51.230469 8.597656 51.191406 8.777344 C 51.15625 8.933594 51.066406 9.054688 50.929688 9.128906 C 50.8125 9.191406 50.734375 9.207031 50.578125 9.207031 C 50.34375 9.207031 50.203125 9.15625 49.988281 8.996094 C 49.921875 8.945312 49.855469 8.90625 49.839844 8.90625 C 49.761719 8.898438 49.742188 8.917969 49.65625 9.058594 L 49.59375 9.160156 L 49.667969 9.230469 C 49.839844 9.390625 50.035156 9.488281 50.285156 9.542969 C 50.410156 9.570312 50.71875 9.570312 50.835938 9.542969 C 51.21875 9.457031 51.476562 9.214844 51.570312 8.851562 C 51.601562 8.734375 51.609375 8.484375 51.582031 8.382812 C 51.546875 8.226562 51.457031 8.097656 51.324219 7.996094 C 51.207031 7.90625 51.074219 7.84375 50.777344 7.742188 C 50.449219 7.625 50.328125 7.570312 50.238281 7.472656 C 50.179688 7.417969 50.160156 7.386719 50.144531 7.335938 C 50.082031 7.117188 50.183594 6.890625 50.386719 6.792969 C 50.484375 6.746094 50.5625 6.730469 50.695312 6.730469 C 50.878906 6.730469 51.007812 6.769531 51.179688 6.882812 C 51.300781 6.964844 51.328125 6.972656 51.375 6.953125 C 51.40625 6.9375 51.425781 6.914062 51.476562 6.8125 L 51.539062 6.691406 L 51.496094 6.65625 C 51.425781 6.59375 51.285156 6.503906 51.199219 6.46875 C 51.035156 6.402344 50.949219 6.382812 50.730469 6.378906 C 50.621094 6.375 50.515625 6.378906 50.496094 6.382812 Z M 50.496094 6.382812 " />
                <path d="M 3.976562 7.972656 L 3.976562 9.535156 L 4.398438 9.535156 L 4.398438 6.410156 L 3.976562 6.410156 Z M 3.976562 7.972656 " />
                <path d="M 5.175781 7.972656 L 5.175781 9.535156 L 5.550781 9.535156 L 5.546875 8.328125 C 5.542969 7.203125 5.546875 7.125 5.5625 7.144531 C 5.574219 7.15625 5.96875 7.675781 6.441406 8.300781 C 6.914062 8.925781 7.316406 9.449219 7.332031 9.46875 C 7.386719 9.527344 7.414062 9.535156 7.554688 9.535156 L 7.6875 9.535156 L 7.6875 6.410156 L 7.308594 6.410156 L 7.3125 7.609375 C 7.316406 8.269531 7.3125 8.804688 7.308594 8.800781 C 7.304688 8.796875 6.902344 8.269531 6.417969 7.628906 C 5.933594 6.988281 5.523438 6.449219 5.503906 6.4375 C 5.476562 6.410156 5.460938 6.410156 5.324219 6.410156 L 5.175781 6.410156 Z M 5.175781 7.972656 " />
                <path d="M 8.132812 6.585938 L 8.132812 6.765625 L 9.140625 6.765625 L 9.140625 9.535156 L 9.550781 9.535156 L 9.550781 6.765625 L 10.558594 6.765625 L 10.558594 6.410156 L 8.132812 6.410156 Z M 8.132812 6.585938 " />
                <path d="M 11.007812 7.972656 L 11.007812 9.535156 L 12.921875 9.535156 L 12.917969 9.363281 L 12.914062 9.183594 L 12.171875 9.183594 L 11.429688 9.179688 L 11.429688 8.652344 L 11.433594 8.128906 L 12.636719 8.121094 L 12.636719 7.789062 L 11.433594 7.78125 L 11.429688 7.265625 L 11.429688 6.753906 L 12.917969 6.753906 L 12.917969 6.410156 L 11.007812 6.410156 Z M 11.007812 7.972656 " />
                <path d="M 16.636719 7.972656 L 16.636719 9.535156 L 17.0625 9.535156 L 17.0625 8.226562 L 17.242188 8.226562 C 17.445312 8.226562 17.492188 8.238281 17.546875 8.292969 C 17.570312 8.3125 17.773438 8.59375 18.003906 8.914062 C 18.394531 9.460938 18.425781 9.5 18.472656 9.515625 C 18.511719 9.535156 18.558594 9.535156 18.726562 9.535156 L 18.929688 9.53125 L 18.46875 8.894531 C 18.214844 8.542969 17.988281 8.234375 17.957031 8.207031 L 17.90625 8.160156 L 17.984375 8.132812 C 18.324219 8.03125 18.558594 7.789062 18.625 7.484375 C 18.648438 7.375 18.652344 7.167969 18.628906 7.058594 C 18.570312 6.785156 18.375 6.578125 18.082031 6.480469 C 17.886719 6.417969 17.882812 6.417969 17.242188 6.414062 L 16.636719 6.40625 Z M 17.695312 6.753906 C 17.960938 6.789062 18.128906 6.898438 18.199219 7.085938 C 18.230469 7.167969 18.242188 7.347656 18.222656 7.441406 C 18.175781 7.664062 18.015625 7.816406 17.769531 7.886719 C 17.691406 7.90625 17.632812 7.910156 17.371094 7.917969 L 17.0625 7.921875 L 17.0625 6.742188 L 17.328125 6.742188 C 17.476562 6.742188 17.640625 6.746094 17.695312 6.753906 Z M 17.695312 6.753906 " />
                <path d="M 20.191406 6.417969 C 20.179688 6.429688 18.960938 9.523438 18.960938 9.53125 C 18.960938 9.535156 19.050781 9.535156 19.15625 9.535156 L 19.351562 9.53125 L 19.386719 9.488281 C 19.414062 9.457031 19.460938 9.351562 19.570312 9.0625 L 19.714844 8.679688 L 21.09375 8.679688 L 21.15625 8.835938 C 21.1875 8.925781 21.253906 9.09375 21.300781 9.214844 C 21.347656 9.335938 21.394531 9.449219 21.40625 9.46875 C 21.445312 9.527344 21.480469 9.535156 21.671875 9.535156 C 21.847656 9.535156 21.851562 9.535156 21.84375 9.511719 C 21.839844 9.5 21.566406 8.796875 21.230469 7.949219 L 20.625 6.410156 L 20.410156 6.410156 C 20.292969 6.410156 20.191406 6.414062 20.191406 6.417969 Z M 20.730469 7.726562 C 20.863281 8.066406 20.96875 8.351562 20.96875 8.359375 C 20.96875 8.367188 20.792969 8.371094 20.402344 8.371094 C 19.871094 8.371094 19.839844 8.371094 19.84375 8.347656 C 19.847656 8.339844 19.964844 8.023438 20.109375 7.652344 C 20.25 7.277344 20.375 6.945312 20.382812 6.914062 C 20.390625 6.882812 20.398438 6.851562 20.402344 6.84375 C 20.40625 6.839844 20.429688 6.894531 20.453125 6.96875 C 20.476562 7.042969 20.601562 7.382812 20.730469 7.726562 Z M 20.730469 7.726562 " />
                <path d="M 21.917969 6.585938 L 21.917969 6.765625 L 22.929688 6.765625 L 22.929688 9.535156 L 23.339844 9.535156 L 23.339844 6.765625 L 24.347656 6.765625 L 24.347656 6.410156 L 21.917969 6.410156 Z M 21.917969 6.585938 " />
                <path d="M 24.78125 7.972656 L 24.78125 9.535156 L 26.695312 9.535156 L 26.691406 9.363281 L 26.6875 9.183594 L 25.945312 9.183594 L 25.203125 9.179688 L 25.207031 8.652344 L 25.210938 8.128906 L 26.414062 8.121094 L 26.414062 7.789062 L 25.210938 7.78125 L 25.207031 7.265625 L 25.203125 6.753906 L 26.695312 6.753906 L 26.695312 6.410156 L 24.78125 6.410156 Z M 24.78125 7.972656 " />
                <path d="M 27.269531 7.972656 L 27.269531 9.535156 L 27.894531 9.535156 C 28.511719 9.535156 28.652344 9.527344 28.8125 9.496094 C 29.308594 9.382812 29.679688 9.058594 29.851562 8.585938 C 29.976562 8.234375 29.984375 7.765625 29.867188 7.402344 C 29.695312 6.871094 29.269531 6.511719 28.710938 6.425781 C 28.625 6.414062 28.457031 6.410156 27.9375 6.410156 L 27.269531 6.410156 Z M 28.644531 6.773438 C 28.859375 6.808594 29.046875 6.902344 29.183594 7.039062 C 29.304688 7.160156 29.375 7.277344 29.4375 7.457031 C 29.558594 7.804688 29.539062 8.277344 29.394531 8.589844 C 29.332031 8.726562 29.269531 8.816406 29.171875 8.910156 C 29.070312 9.003906 28.957031 9.074219 28.828125 9.121094 C 28.664062 9.179688 28.589844 9.183594 28.125 9.183594 L 27.699219 9.183594 L 27.691406 6.753906 L 28.117188 6.753906 C 28.441406 6.753906 28.570312 6.757812 28.644531 6.773438 Z M 28.644531 6.773438 " />
                <path d="M 31.328125 6.417969 C 31.328125 6.421875 31.542969 7.125 31.808594 7.980469 L 32.285156 9.535156 L 32.664062 9.535156 L 33.078125 8.277344 C 33.441406 7.175781 33.496094 7.019531 33.503906 7.054688 C 33.507812 7.074219 33.691406 7.640625 33.910156 8.3125 L 34.3125 9.535156 L 34.691406 9.535156 L 35.167969 7.980469 C 35.433594 7.125 35.648438 6.421875 35.648438 6.417969 C 35.648438 6.414062 35.5625 6.410156 35.457031 6.410156 C 35.242188 6.410156 35.21875 6.417969 35.183594 6.507812 C 35.160156 6.578125 34.558594 8.621094 34.535156 8.726562 C 34.519531 8.777344 34.507812 8.839844 34.503906 8.863281 L 34.492188 8.910156 L 34.472656 8.828125 C 34.449219 8.726562 34.425781 8.65625 34.019531 7.4375 C 33.851562 6.933594 33.707031 6.503906 33.695312 6.484375 C 33.660156 6.417969 33.636719 6.410156 33.503906 6.410156 C 33.308594 6.410156 33.363281 6.292969 32.9375 7.582031 C 32.734375 8.183594 32.554688 8.730469 32.539062 8.796875 L 32.507812 8.921875 L 32.484375 8.804688 C 32.46875 8.738281 32.3125 8.1875 32.132812 7.582031 C 31.835938 6.566406 31.808594 6.472656 31.773438 6.441406 L 31.738281 6.410156 L 31.535156 6.410156 C 31.421875 6.410156 31.328125 6.414062 31.328125 6.417969 Z M 31.328125 6.417969 " />
                <path d="M 36.105469 7.972656 L 36.105469 9.535156 L 36.527344 9.535156 L 36.527344 6.410156 L 36.105469 6.410156 Z M 36.105469 7.972656 " />
                <path d="M 36.984375 6.585938 L 36.984375 6.765625 L 37.992188 6.765625 L 37.992188 9.535156 L 38.40625 9.535156 L 38.40625 6.765625 L 39.414062 6.765625 L 39.414062 6.410156 L 36.984375 6.410156 Z M 36.984375 6.585938 " />
                <path d="M 39.847656 7.972656 L 39.847656 9.535156 L 40.269531 9.535156 L 40.273438 8.828125 L 40.277344 8.117188 L 41.105469 8.113281 L 41.9375 8.109375 L 41.9375 9.535156 L 42.359375 9.535156 L 42.359375 6.410156 L 41.9375 6.410156 L 41.929688 7.792969 L 40.277344 7.792969 L 40.269531 6.410156 L 39.847656 6.410156 Z M 39.847656 7.972656 " />
                <path d="M 46.570312 7.972656 L 46.570312 9.535156 L 47.195312 9.535156 C 47.8125 9.535156 47.953125 9.527344 48.113281 9.496094 C 48.609375 9.382812 48.984375 9.058594 49.152344 8.585938 C 49.28125 8.234375 49.285156 7.765625 49.167969 7.402344 C 49 6.871094 48.570312 6.511719 48.015625 6.425781 C 47.929688 6.414062 47.757812 6.410156 47.238281 6.410156 L 46.570312 6.410156 Z M 47.949219 6.773438 C 48.164062 6.808594 48.347656 6.902344 48.484375 7.039062 C 48.605469 7.160156 48.675781 7.277344 48.738281 7.457031 C 48.859375 7.804688 48.84375 8.277344 48.699219 8.589844 C 48.632812 8.726562 48.570312 8.816406 48.476562 8.910156 C 48.375 9.003906 48.257812 9.074219 48.128906 9.121094 C 47.964844 9.179688 47.894531 9.183594 47.429688 9.183594 L 47 9.183594 L 46.992188 6.753906 L 47.421875 6.753906 C 47.746094 6.753906 47.871094 6.757812 47.949219 6.773438 Z M 47.949219 6.773438 " />
                <path d="M 53.28125 7.972656 L 53.28125 9.535156 L 55.195312 9.535156 L 55.195312 9.363281 L 55.191406 9.183594 L 54.445312 9.183594 L 53.707031 9.179688 L 53.707031 8.652344 L 53.710938 8.128906 L 54.914062 8.121094 L 54.914062 7.789062 L 53.710938 7.78125 L 53.707031 7.265625 L 53.707031 6.753906 L 55.195312 6.753906 L 55.195312 6.410156 L 53.28125 6.410156 Z M 53.28125 7.972656 " />
                <path d="M 55.769531 7.972656 L 55.769531 9.535156 L 56.144531 9.535156 L 56.144531 8.292969 C 56.140625 7.609375 56.144531 7.054688 56.148438 7.0625 C 56.152344 7.066406 56.390625 7.5 56.679688 8.027344 C 56.96875 8.554688 57.214844 8.996094 57.226562 9.015625 C 57.265625 9.070312 57.3125 9.089844 57.410156 9.082031 C 57.496094 9.078125 57.5 9.074219 57.539062 9.03125 C 57.5625 9.003906 57.816406 8.550781 58.101562 8.019531 L 58.621094 7.058594 L 58.621094 9.535156 L 58.984375 9.535156 L 58.984375 6.410156 L 58.789062 6.410156 C 58.648438 6.410156 58.585938 6.414062 58.570312 6.421875 C 58.5625 6.429688 58.320312 6.867188 58.035156 7.390625 C 57.488281 8.390625 57.460938 8.445312 57.417969 8.550781 C 57.390625 8.613281 57.390625 8.613281 57.378906 8.585938 C 57.375 8.570312 57.355469 8.519531 57.335938 8.476562 C 57.285156 8.367188 56.230469 6.46875 56.203125 6.433594 C 56.179688 6.410156 56.167969 6.410156 55.972656 6.410156 L 55.769531 6.410156 Z M 55.769531 7.972656 " />
                <path d="M 59.761719 7.972656 L 59.761719 9.535156 L 60.136719 9.535156 L 60.132812 8.292969 C 60.132812 7.609375 60.132812 7.054688 60.136719 7.0625 C 60.140625 7.066406 60.378906 7.5 60.667969 8.027344 C 60.957031 8.554688 61.203125 8.996094 61.21875 9.015625 C 61.257812 9.070312 61.300781 9.089844 61.402344 9.082031 C 61.484375 9.078125 61.488281 9.074219 61.527344 9.03125 C 61.550781 9.003906 61.804688 8.550781 62.089844 8.019531 L 62.613281 7.058594 L 62.613281 9.535156 L 62.976562 9.535156 L 62.976562 6.410156 L 62.777344 6.410156 C 62.636719 6.410156 62.574219 6.414062 62.5625 6.421875 C 62.550781 6.429688 62.308594 6.867188 62.023438 7.390625 C 61.480469 8.390625 61.449219 8.445312 61.40625 8.550781 C 61.382812 8.613281 61.378906 8.613281 61.371094 8.585938 C 61.363281 8.570312 61.34375 8.519531 61.324219 8.476562 C 61.273438 8.367188 60.21875 6.46875 60.191406 6.433594 C 60.167969 6.410156 60.160156 6.410156 59.964844 6.410156 L 59.761719 6.410156 Z M 59.761719 7.972656 " />
            </g>
        </svg>
    )
}

export const epttaCommandDarkfonLogo = (iconColor, thisClassName = '') => {
    return (


        <svg fill={iconColor} className={thisClassName} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 69 14" version="1.1">
            <g id="surface1">
                <path d="M 1.457031 0.496094 C 0.824219 0.558594 0.324219 0.867188 0.0859375 1.339844 C -0.0078125 1.523438 0 1.382812 0 3.144531 L 0 4.722656 L 0.03125 4.816406 C 0.15625 5.199219 0.503906 5.507812 0.980469 5.667969 C 1.285156 5.765625 0.910156 5.757812 4.917969 5.761719 L 8.480469 5.765625 L 8.480469 4.375 L 2.042969 4.367188 L 1.929688 4.339844 C 1.632812 4.273438 1.476562 4.117188 1.417969 3.835938 C 1.398438 3.734375 1.394531 3.664062 1.394531 3.128906 C 1.394531 2.457031 1.398438 2.414062 1.480469 2.246094 C 1.566406 2.070312 1.691406 1.972656 1.910156 1.910156 L 2.007812 1.882812 L 5.242188 1.875 L 8.472656 1.871094 L 8.480469 0.488281 L 4.988281 0.488281 C 3.070312 0.488281 1.480469 0.492188 1.457031 0.496094 Z M 1.457031 0.496094 " />
                <path d="M 11.175781 0.496094 C 10.910156 0.523438 10.714844 0.574219 10.5 0.675781 C 10.109375 0.863281 9.820312 1.1875 9.71875 1.558594 L 9.6875 1.675781 L 9.683594 3.058594 C 9.683594 4.039062 9.683594 4.476562 9.695312 4.5625 C 9.753906 5.074219 10.117188 5.476562 10.699219 5.667969 C 11 5.765625 10.726562 5.757812 13.863281 5.761719 C 15.855469 5.765625 16.6875 5.761719 16.773438 5.753906 C 17.203125 5.703125 17.578125 5.539062 17.832031 5.277344 C 18.042969 5.070312 18.15625 4.835938 18.1875 4.546875 C 18.203125 4.394531 18.203125 1.886719 18.1875 1.738281 C 18.160156 1.46875 18.0625 1.257812 17.875 1.046875 C 17.679688 0.828125 17.429688 0.671875 17.109375 0.574219 C 16.835938 0.488281 17.085938 0.496094 14 0.492188 C 12.46875 0.492188 11.195312 0.492188 11.175781 0.496094 Z M 16.132812 1.890625 C 16.398438 1.925781 16.582031 2.042969 16.675781 2.234375 C 16.761719 2.410156 16.761719 2.40625 16.761719 3.125 C 16.761719 3.765625 16.761719 3.773438 16.734375 3.871094 C 16.65625 4.140625 16.503906 4.277344 16.214844 4.34375 L 16.09375 4.367188 L 11.773438 4.367188 L 11.652344 4.34375 C 11.355469 4.273438 11.195312 4.121094 11.136719 3.835938 C 11.117188 3.734375 11.113281 3.664062 11.113281 3.128906 C 11.113281 2.457031 11.117188 2.414062 11.199219 2.25 C 11.296875 2.046875 11.445312 1.945312 11.710938 1.894531 C 11.828125 1.875 15.984375 1.871094 16.132812 1.890625 Z M 16.132812 1.890625 " />
                <path d="M 19.410156 1.183594 L 19.410156 1.875 L 20.816406 1.875 L 22.292969 3.820312 L 23.773438 5.761719 L 24.222656 5.765625 L 24.675781 5.765625 L 26.140625 3.84375 L 27.609375 1.925781 L 27.609375 3.84375 L 27.613281 5.765625 L 29.03125 5.765625 L 29.03125 0.488281 L 26.976562 0.488281 L 25.605469 2.277344 C 24.851562 3.261719 24.230469 4.070312 24.230469 4.070312 C 24.226562 4.070312 23.601562 3.261719 22.847656 2.277344 L 21.46875 0.488281 L 19.410156 0.488281 Z M 19.410156 1.183594 " />
                <path d="M 30.246094 1.183594 L 30.246094 1.875 L 31.652344 1.875 L 33.128906 3.820312 L 34.609375 5.761719 L 35.058594 5.765625 L 35.507812 5.765625 L 36.976562 3.84375 L 38.441406 1.925781 L 38.445312 3.84375 L 38.449219 5.765625 L 39.867188 5.765625 L 39.867188 0.488281 L 37.8125 0.488281 L 36.441406 2.277344 C 35.6875 3.261719 35.066406 4.070312 35.0625 4.070312 C 35.058594 4.070312 34.4375 3.261719 33.679688 2.277344 L 32.304688 0.488281 L 30.246094 0.488281 Z M 30.246094 1.183594 " />
                <path d="M 42.519531 0.5 C 42.289062 0.523438 42.058594 0.59375 41.851562 0.695312 C 41.535156 0.855469 41.265625 1.140625 41.164062 1.429688 C 41.125 1.542969 41.09375 1.703125 41.09375 1.804688 L 41.09375 1.875 L 48.167969 1.875 L 48.167969 2.460938 L 41.082031 2.460938 L 41.082031 5.765625 L 42.511719 5.765625 L 42.511719 3.777344 L 48.167969 3.777344 L 48.167969 5.765625 L 49.597656 5.765625 L 49.597656 3.789062 C 49.597656 2.460938 49.59375 1.777344 49.585938 1.699219 C 49.554688 1.421875 49.441406 1.195312 49.226562 0.980469 C 48.996094 0.746094 48.699219 0.597656 48.296875 0.515625 C 48.203125 0.496094 47.976562 0.496094 45.40625 0.492188 C 43.871094 0.492188 42.570312 0.496094 42.519531 0.5 Z M 42.519531 0.5 " />
                <path d="M 50.796875 3.125 L 50.796875 5.765625 L 52.230469 5.765625 L 52.230469 1.875 L 54.59375 1.875 C 55.914062 1.875 57.03125 1.882812 57.125 1.890625 C 57.566406 1.917969 57.757812 2.046875 57.851562 2.375 L 57.878906 2.46875 L 57.882812 4.117188 L 57.882812 5.765625 L 59.316406 5.765625 L 59.3125 3.71875 C 59.308594 1.503906 59.3125 1.628906 59.246094 1.433594 C 59.066406 0.925781 58.542969 0.570312 57.875 0.5 C 57.789062 0.492188 56.628906 0.488281 54.273438 0.488281 L 50.796875 0.488281 Z M 50.796875 3.125 " />
                <path d="M 60.527344 1.183594 L 60.527344 1.875 L 63.765625 1.878906 L 67 1.882812 L 67.09375 1.910156 C 67.214844 1.945312 67.28125 1.976562 67.355469 2.027344 C 67.464844 2.113281 67.539062 2.234375 67.585938 2.402344 C 67.605469 2.472656 67.609375 2.542969 67.609375 3.125 C 67.609375 3.765625 67.609375 3.773438 67.582031 3.871094 C 67.503906 4.136719 67.351562 4.277344 67.070312 4.339844 L 66.953125 4.367188 L 61.960938 4.375 L 61.960938 2.460938 L 60.527344 2.460938 L 60.527344 5.765625 L 64.027344 5.765625 C 66.339844 5.765625 67.558594 5.761719 67.625 5.753906 C 68.257812 5.675781 68.742188 5.363281 68.949219 4.894531 L 68.992188 4.796875 L 68.992188 1.492188 L 68.957031 1.414062 C 68.796875 1.023438 68.433594 0.722656 67.957031 0.574219 C 67.679688 0.488281 68.019531 0.496094 64.082031 0.492188 L 60.527344 0.488281 Z M 60.527344 1.183594 " />
                <path d="M 19.410156 4.113281 L 19.410156 5.765625 L 20.84375 5.765625 L 20.84375 2.460938 L 19.410156 2.460938 Z M 19.410156 4.113281 " />
                <path d="M 30.246094 4.113281 L 30.246094 5.765625 L 31.679688 5.765625 L 31.679688 2.460938 L 30.246094 2.460938 Z M 30.246094 4.113281 " />
                <path d="M 15.28125 6.40625 C 14.753906 6.488281 14.339844 6.789062 14.128906 7.242188 C 14.007812 7.503906 13.953125 7.792969 13.96875 8.105469 C 14 8.746094 14.296875 9.230469 14.820312 9.480469 C 15.042969 9.589844 15.203125 9.632812 15.488281 9.652344 C 15.929688 9.691406 16.351562 9.589844 16.691406 9.363281 L 16.753906 9.320312 L 16.753906 8.078125 L 15.773438 8.078125 L 15.773438 8.207031 C 15.773438 8.277344 15.777344 8.347656 15.78125 8.363281 C 15.800781 8.414062 15.851562 8.421875 16.121094 8.421875 L 16.367188 8.421875 L 16.367188 9.117188 L 16.316406 9.140625 C 16.234375 9.183594 16.054688 9.25 15.96875 9.269531 C 15.785156 9.3125 15.480469 9.3125 15.292969 9.269531 C 14.855469 9.164062 14.554688 8.851562 14.449219 8.386719 C 14.417969 8.25 14.40625 7.964844 14.425781 7.816406 C 14.460938 7.511719 14.570312 7.269531 14.753906 7.085938 C 14.953125 6.882812 15.199219 6.78125 15.53125 6.765625 C 15.855469 6.753906 16.078125 6.8125 16.359375 6.980469 C 16.523438 7.082031 16.5625 7.070312 16.667969 6.890625 L 16.734375 6.785156 L 16.691406 6.75 C 16.484375 6.570312 16.210938 6.449219 15.914062 6.40625 C 15.757812 6.382812 15.433594 6.382812 15.28125 6.40625 Z M 15.28125 6.40625 " />
                <path d="M 46.621094 6.394531 C 46.125 6.472656 45.800781 6.859375 45.828125 7.332031 C 45.84375 7.558594 45.917969 7.730469 46.070312 7.867188 C 46.175781 7.964844 46.375 8.058594 46.703125 8.164062 C 47 8.261719 47.125 8.320312 47.214844 8.402344 C 47.335938 8.511719 47.378906 8.664062 47.335938 8.847656 C 47.300781 9.007812 47.207031 9.132812 47.066406 9.210938 C 46.945312 9.273438 46.867188 9.292969 46.703125 9.292969 C 46.460938 9.289062 46.316406 9.238281 46.09375 9.074219 C 46.023438 9.023438 45.957031 8.980469 45.941406 8.980469 C 45.859375 8.976562 45.835938 8.992188 45.75 9.136719 L 45.6875 9.242188 L 45.761719 9.3125 C 45.941406 9.476562 46.140625 9.578125 46.402344 9.632812 C 46.53125 9.664062 46.851562 9.664062 46.972656 9.636719 C 47.367188 9.546875 47.632812 9.296875 47.730469 8.925781 C 47.761719 8.804688 47.769531 8.550781 47.742188 8.445312 C 47.703125 8.285156 47.613281 8.152344 47.476562 8.046875 C 47.355469 7.957031 47.21875 7.894531 46.910156 7.789062 C 46.570312 7.671875 46.445312 7.609375 46.351562 7.515625 C 46.292969 7.453125 46.269531 7.425781 46.257812 7.371094 C 46.191406 7.148438 46.296875 6.914062 46.507812 6.816406 C 46.605469 6.765625 46.6875 6.75 46.824219 6.75 C 47.011719 6.75 47.148438 6.792969 47.324219 6.90625 C 47.453125 6.992188 47.476562 7 47.527344 6.976562 C 47.5625 6.964844 47.578125 6.941406 47.632812 6.835938 L 47.695312 6.710938 L 47.652344 6.675781 C 47.578125 6.609375 47.433594 6.519531 47.34375 6.484375 C 47.175781 6.414062 47.085938 6.398438 46.863281 6.390625 C 46.75 6.390625 46.640625 6.390625 46.621094 6.394531 Z M 46.621094 6.394531 " />
                <path d="M 52.417969 6.394531 C 51.925781 6.472656 51.597656 6.859375 51.628906 7.332031 C 51.640625 7.558594 51.71875 7.730469 51.871094 7.867188 C 51.976562 7.964844 52.171875 8.054688 52.5 8.164062 C 52.800781 8.261719 52.925781 8.320312 53.015625 8.402344 C 53.136719 8.511719 53.175781 8.664062 53.136719 8.847656 C 53.101562 9.007812 53.007812 9.132812 52.867188 9.210938 C 52.746094 9.273438 52.664062 9.292969 52.503906 9.292969 C 52.261719 9.289062 52.117188 9.238281 51.890625 9.074219 C 51.824219 9.023438 51.753906 8.980469 51.742188 8.980469 C 51.660156 8.976562 51.636719 8.992188 51.550781 9.136719 L 51.484375 9.242188 L 51.5625 9.3125 C 51.742188 9.476562 51.941406 9.578125 52.199219 9.632812 C 52.332031 9.664062 52.648438 9.664062 52.773438 9.636719 C 53.167969 9.546875 53.429688 9.296875 53.53125 8.925781 C 53.5625 8.804688 53.570312 8.550781 53.542969 8.445312 C 53.503906 8.285156 53.414062 8.152344 53.273438 8.046875 C 53.152344 7.957031 53.019531 7.894531 52.710938 7.789062 C 52.367188 7.671875 52.246094 7.609375 52.148438 7.515625 C 52.089844 7.453125 52.070312 7.425781 52.054688 7.371094 C 51.988281 7.148438 52.097656 6.914062 52.308594 6.816406 C 52.40625 6.765625 52.488281 6.75 52.625 6.75 C 52.8125 6.75 52.945312 6.792969 53.125 6.90625 C 53.253906 6.992188 53.277344 7 53.328125 6.976562 C 53.359375 6.964844 53.378906 6.941406 53.429688 6.835938 L 53.496094 6.710938 L 53.453125 6.675781 C 53.378906 6.609375 53.234375 6.519531 53.144531 6.484375 C 52.976562 6.414062 52.886719 6.398438 52.660156 6.390625 C 52.546875 6.390625 52.4375 6.390625 52.417969 6.394531 Z M 52.417969 6.394531 " />
                <path d="M 4.320312 8.023438 L 4.320312 9.628906 L 4.757812 9.628906 L 4.757812 6.421875 L 4.320312 6.421875 Z M 4.320312 8.023438 " />
                <path d="M 5.558594 8.023438 L 5.558594 9.628906 L 5.945312 9.628906 L 5.941406 8.390625 C 5.9375 7.238281 5.941406 7.15625 5.960938 7.175781 C 5.96875 7.1875 6.378906 7.71875 6.867188 8.359375 C 7.355469 9 7.769531 9.539062 7.789062 9.558594 C 7.84375 9.617188 7.875 9.628906 8.019531 9.628906 L 8.152344 9.628906 L 8.152344 6.421875 L 7.761719 6.421875 L 7.765625 7.652344 C 7.769531 8.328125 7.765625 8.878906 7.761719 8.875 C 7.757812 8.871094 7.34375 8.328125 6.84375 7.671875 C 6.339844 7.015625 5.917969 6.464844 5.898438 6.449219 C 5.867188 6.425781 5.855469 6.421875 5.710938 6.421875 L 5.558594 6.421875 Z M 5.558594 8.023438 " />
                <path d="M 8.613281 6.605469 L 8.613281 6.789062 L 9.65625 6.789062 L 9.65625 9.628906 L 10.082031 9.628906 L 10.082031 6.789062 L 11.125 6.789062 L 11.125 6.421875 L 8.613281 6.421875 Z M 8.613281 6.605469 " />
                <path d="M 11.585938 8.023438 L 11.585938 9.628906 L 13.566406 9.628906 L 13.5625 9.445312 L 13.558594 9.269531 L 12.789062 9.265625 L 12.023438 9.261719 L 12.03125 8.183594 L 13.273438 8.175781 L 13.273438 7.835938 L 12.03125 7.828125 L 12.027344 7.300781 L 12.023438 6.777344 L 13.5625 6.777344 L 13.5625 6.421875 L 11.585938 6.421875 Z M 11.585938 8.023438 " />
                <path d="M 17.410156 8.023438 L 17.410156 9.628906 L 17.847656 9.628906 L 17.847656 8.285156 L 18.03125 8.285156 C 18.242188 8.285156 18.292969 8.296875 18.351562 8.351562 C 18.371094 8.375 18.585938 8.660156 18.824219 8.988281 C 19.226562 9.550781 19.257812 9.589844 19.304688 9.609375 C 19.347656 9.625 19.398438 9.628906 19.570312 9.625 L 19.777344 9.621094 L 19.304688 8.96875 C 19.042969 8.609375 18.804688 8.292969 18.773438 8.265625 L 18.71875 8.214844 L 18.804688 8.191406 C 19.152344 8.085938 19.394531 7.839844 19.464844 7.523438 C 19.488281 7.414062 19.492188 7.199219 19.464844 7.085938 C 19.410156 6.808594 19.203125 6.59375 18.902344 6.496094 C 18.699219 6.433594 18.695312 6.429688 18.03125 6.425781 L 17.410156 6.421875 Z M 18.5 6.777344 C 18.777344 6.8125 18.949219 6.921875 19.023438 7.113281 C 19.058594 7.199219 19.070312 7.382812 19.046875 7.480469 C 19 7.707031 18.835938 7.867188 18.578125 7.9375 C 18.496094 7.957031 18.441406 7.960938 18.164062 7.96875 L 17.847656 7.972656 L 17.847656 6.761719 L 18.125 6.761719 C 18.273438 6.761719 18.445312 6.769531 18.5 6.777344 Z M 18.5 6.777344 " />
                <path d="M 21.082031 6.429688 C 21.074219 6.445312 19.8125 9.613281 19.8125 9.621094 C 19.8125 9.625 19.902344 9.628906 20.015625 9.625 L 20.214844 9.621094 L 20.253906 9.578125 C 20.28125 9.546875 20.328125 9.4375 20.441406 9.140625 L 20.59375 8.75 L 22.019531 8.75 L 22.082031 8.910156 C 22.113281 9 22.183594 9.175781 22.230469 9.296875 C 22.277344 9.421875 22.328125 9.539062 22.339844 9.558594 C 22.378906 9.617188 22.414062 9.628906 22.617188 9.628906 C 22.792969 9.628906 22.800781 9.625 22.792969 9.601562 C 22.789062 9.589844 22.503906 8.867188 22.160156 8 L 21.53125 6.421875 L 21.308594 6.421875 C 21.1875 6.421875 21.085938 6.425781 21.082031 6.429688 Z M 21.640625 7.769531 C 21.777344 8.121094 21.886719 8.414062 21.886719 8.417969 C 21.886719 8.429688 21.710938 8.433594 21.304688 8.433594 C 20.753906 8.433594 20.71875 8.433594 20.726562 8.410156 C 20.726562 8.398438 20.851562 8.078125 20.996094 7.695312 C 21.144531 7.3125 21.273438 6.972656 21.28125 6.941406 C 21.289062 6.90625 21.296875 6.875 21.300781 6.867188 C 21.304688 6.863281 21.328125 6.917969 21.351562 6.996094 C 21.378906 7.074219 21.507812 7.421875 21.640625 7.769531 Z M 21.640625 7.769531 " />
                <path d="M 22.871094 6.605469 L 22.871094 6.789062 L 23.914062 6.789062 L 23.914062 9.628906 L 24.335938 9.628906 L 24.335938 6.789062 L 25.382812 6.789062 L 25.382812 6.421875 L 22.871094 6.421875 Z M 22.871094 6.605469 " />
                <path d="M 25.832031 8.023438 L 25.832031 9.628906 L 27.808594 9.628906 L 27.804688 9.445312 L 27.800781 9.269531 L 27.035156 9.265625 L 26.265625 9.261719 L 26.273438 8.183594 L 27.515625 8.175781 L 27.515625 7.835938 L 26.273438 7.828125 L 26.269531 7.300781 L 26.265625 6.777344 L 27.808594 6.777344 L 27.808594 6.421875 L 25.832031 6.421875 Z M 25.832031 8.023438 " />
                <path d="M 28.402344 8.023438 L 28.402344 9.628906 L 29.050781 9.628906 C 29.6875 9.628906 29.832031 9.621094 29.996094 9.585938 C 30.511719 9.472656 30.894531 9.140625 31.074219 8.652344 C 31.203125 8.292969 31.207031 7.8125 31.089844 7.441406 C 30.914062 6.898438 30.472656 6.527344 29.894531 6.441406 C 29.804688 6.425781 29.628906 6.421875 29.089844 6.421875 L 28.402344 6.421875 Z M 29.824219 6.792969 C 30.046875 6.832031 30.238281 6.925781 30.378906 7.066406 C 30.507812 7.195312 30.578125 7.3125 30.644531 7.496094 C 30.769531 7.851562 30.75 8.335938 30.601562 8.65625 C 30.535156 8.796875 30.472656 8.886719 30.371094 8.984375 C 30.265625 9.082031 30.144531 9.15625 30.011719 9.203125 C 29.84375 9.261719 29.769531 9.265625 29.289062 9.269531 L 28.84375 9.269531 L 28.839844 8.019531 L 28.839844 6.777344 L 29.28125 6.777344 C 29.617188 6.777344 29.746094 6.78125 29.824219 6.792969 Z M 29.824219 6.792969 " />
                <path d="M 32.601562 6.429688 C 32.601562 6.4375 32.820312 7.15625 33.09375 8.035156 L 33.589844 9.628906 L 33.980469 9.628906 L 34.410156 8.335938 C 34.78125 7.210938 34.839844 7.046875 34.847656 7.082031 C 34.851562 7.105469 35.042969 7.6875 35.269531 8.375 L 35.683594 9.628906 L 36.078125 9.628906 L 36.570312 8.035156 C 36.84375 7.15625 37.066406 6.4375 37.066406 6.429688 C 37.066406 6.425781 36.976562 6.421875 36.871094 6.421875 C 36.644531 6.421875 36.621094 6.429688 36.585938 6.523438 C 36.558594 6.597656 35.941406 8.6875 35.914062 8.796875 C 35.898438 8.847656 35.886719 8.910156 35.882812 8.9375 L 35.871094 8.988281 L 35.851562 8.902344 C 35.824219 8.796875 35.800781 8.726562 35.378906 7.476562 C 35.207031 6.957031 35.058594 6.519531 35.046875 6.496094 C 35.011719 6.433594 34.988281 6.421875 34.847656 6.421875 C 34.648438 6.421875 34.703125 6.304688 34.261719 7.621094 C 34.054688 8.238281 33.867188 8.800781 33.851562 8.871094 L 33.816406 8.996094 L 33.792969 8.875 C 33.777344 8.808594 33.617188 8.246094 33.429688 7.621094 C 33.125 6.582031 33.09375 6.488281 33.0625 6.457031 L 33.023438 6.421875 L 32.8125 6.421875 C 32.695312 6.421875 32.601562 6.425781 32.601562 6.429688 Z M 32.601562 6.429688 " />
                <path d="M 37.539062 8.023438 L 37.539062 9.628906 L 37.976562 9.628906 L 37.976562 6.421875 L 37.539062 6.421875 Z M 37.539062 8.023438 " />
                <path d="M 38.449219 6.605469 L 38.449219 6.789062 L 39.492188 6.789062 L 39.492188 9.628906 L 39.914062 9.628906 L 39.914062 6.789062 L 40.960938 6.789062 L 40.960938 6.421875 L 38.449219 6.421875 Z M 38.449219 6.605469 " />
                <path d="M 41.410156 8.023438 L 41.410156 9.628906 L 41.84375 9.628906 L 41.847656 8.898438 L 41.851562 8.171875 L 42.710938 8.167969 L 43.566406 8.164062 L 43.566406 9.628906 L 44.003906 9.628906 L 44.003906 6.421875 L 43.566406 6.421875 L 43.566406 7.132812 L 43.5625 7.84375 L 41.851562 7.84375 L 41.84375 6.421875 L 41.410156 6.421875 Z M 41.410156 8.023438 " />
                <path d="M 48.359375 8.023438 L 48.359375 9.628906 L 49.007812 9.628906 C 49.644531 9.628906 49.789062 9.621094 49.957031 9.585938 C 50.46875 9.472656 50.851562 9.140625 51.03125 8.652344 C 51.160156 8.292969 51.167969 7.8125 51.046875 7.441406 C 50.871094 6.898438 50.429688 6.527344 49.851562 6.441406 C 49.761719 6.425781 49.585938 6.421875 49.050781 6.421875 L 48.359375 6.421875 Z M 49.785156 6.792969 C 50.003906 6.832031 50.195312 6.925781 50.335938 7.066406 C 50.464844 7.195312 50.539062 7.3125 50.601562 7.496094 C 50.726562 7.851562 50.707031 8.335938 50.558594 8.65625 C 50.492188 8.796875 50.429688 8.886719 50.328125 8.984375 C 50.222656 9.082031 50.105469 9.15625 49.96875 9.203125 C 49.800781 9.261719 49.726562 9.265625 49.246094 9.269531 L 48.804688 9.269531 L 48.800781 8.019531 L 48.796875 6.777344 L 49.238281 6.777344 C 49.574219 6.777344 49.703125 6.78125 49.785156 6.792969 Z M 49.785156 6.792969 " />
                <path d="M 55.300781 8.023438 L 55.300781 9.628906 L 57.277344 9.628906 L 57.273438 9.445312 L 57.273438 9.269531 L 56.503906 9.265625 L 55.738281 9.261719 L 55.738281 8.722656 L 55.742188 8.183594 L 56.363281 8.179688 L 56.988281 8.175781 L 56.988281 7.835938 L 56.363281 7.832031 L 55.742188 7.828125 L 55.738281 7.300781 L 55.738281 6.777344 L 57.277344 6.777344 L 57.277344 6.421875 L 55.300781 6.421875 Z M 55.300781 8.023438 " />
                <path d="M 57.871094 8.023438 L 57.871094 9.628906 L 58.261719 9.628906 L 58.257812 8.355469 C 58.253906 7.65625 58.257812 7.085938 58.261719 7.089844 C 58.265625 7.09375 58.511719 7.539062 58.8125 8.078125 C 59.109375 8.621094 59.367188 9.074219 59.378906 9.09375 C 59.417969 9.152344 59.464844 9.167969 59.566406 9.160156 C 59.65625 9.160156 59.660156 9.15625 59.699219 9.109375 C 59.722656 9.082031 59.984375 8.617188 60.28125 8.074219 L 60.820312 7.085938 L 60.820312 9.628906 L 61.195312 9.628906 L 61.195312 6.421875 L 60.992188 6.421875 C 60.847656 6.421875 60.78125 6.425781 60.769531 6.4375 C 60.757812 6.445312 60.507812 6.890625 60.210938 7.429688 C 59.648438 8.453125 59.621094 8.507812 59.574219 8.617188 C 59.546875 8.679688 59.546875 8.683594 59.535156 8.652344 C 59.53125 8.636719 59.507812 8.585938 59.488281 8.542969 C 59.4375 8.429688 58.347656 6.480469 58.316406 6.449219 C 58.292969 6.421875 58.285156 6.421875 58.082031 6.421875 L 57.871094 6.421875 Z M 57.871094 8.023438 " />
                <path d="M 61.996094 8.023438 L 61.996094 9.628906 L 62.386719 9.628906 L 62.382812 8.355469 C 62.378906 7.65625 62.382812 7.085938 62.386719 7.089844 C 62.390625 7.09375 62.636719 7.539062 62.9375 8.078125 C 63.234375 8.621094 63.492188 9.074219 63.503906 9.09375 C 63.542969 9.152344 63.589844 9.167969 63.691406 9.160156 C 63.78125 9.160156 63.785156 9.15625 63.824219 9.109375 C 63.847656 9.082031 64.109375 8.617188 64.40625 8.074219 L 64.945312 7.085938 L 64.945312 9.628906 L 65.320312 9.628906 L 65.320312 6.421875 L 65.117188 6.421875 C 64.972656 6.421875 64.90625 6.425781 64.894531 6.4375 C 64.882812 6.445312 64.632812 6.890625 64.335938 7.429688 C 63.773438 8.457031 63.746094 8.507812 63.699219 8.617188 C 63.675781 8.679688 63.671875 8.683594 63.660156 8.652344 C 63.65625 8.636719 63.632812 8.585938 63.613281 8.542969 C 63.5625 8.429688 62.472656 6.480469 62.441406 6.449219 C 62.417969 6.421875 62.410156 6.421875 62.207031 6.421875 L 61.996094 6.421875 Z M 61.996094 8.023438 " />
                <path d="M 31.472656 10.382812 C 31.324219 10.398438 31.132812 10.445312 31 10.5 C 30.507812 10.703125 30.1875 11.101562 30.066406 11.664062 C 30.035156 11.808594 30.035156 12.242188 30.066406 12.394531 C 30.1875 12.964844 30.539062 13.386719 31.054688 13.582031 C 31.417969 13.71875 31.925781 13.71875 32.289062 13.582031 C 32.851562 13.371094 33.214844 12.886719 33.292969 12.253906 C 33.3125 12.105469 33.300781 11.816406 33.273438 11.675781 C 33.15625 11.078125 32.773438 10.632812 32.222656 10.457031 C 32 10.386719 31.722656 10.359375 31.472656 10.382812 Z M 31.933594 10.925781 C 32.25 11 32.484375 11.214844 32.597656 11.523438 C 32.660156 11.695312 32.675781 11.804688 32.675781 12.035156 C 32.675781 12.199219 32.671875 12.265625 32.652344 12.351562 C 32.5625 12.738281 32.355469 12.992188 32.039062 13.101562 C 31.898438 13.152344 31.78125 13.164062 31.609375 13.15625 C 31.355469 13.144531 31.15625 13.070312 30.988281 12.914062 C 30.835938 12.769531 30.753906 12.621094 30.695312 12.382812 C 30.667969 12.277344 30.667969 12.238281 30.667969 12.023438 C 30.667969 11.800781 30.667969 11.765625 30.699219 11.65625 C 30.804688 11.25 31.074219 10.984375 31.460938 10.914062 C 31.574219 10.890625 31.820312 10.898438 31.933594 10.925781 Z M 31.933594 10.925781 " />
                <path d="M 50.824219 10.382812 C 50.675781 10.398438 50.484375 10.445312 50.351562 10.5 C 49.859375 10.703125 49.539062 11.101562 49.417969 11.664062 C 49.386719 11.808594 49.386719 12.242188 49.417969 12.394531 C 49.539062 12.964844 49.890625 13.386719 50.40625 13.582031 C 50.769531 13.71875 51.277344 13.71875 51.640625 13.582031 C 52.203125 13.371094 52.566406 12.886719 52.644531 12.253906 C 52.664062 12.105469 52.652344 11.816406 52.625 11.675781 C 52.507812 11.078125 52.125 10.632812 51.574219 10.457031 C 51.351562 10.386719 51.074219 10.359375 50.824219 10.382812 Z M 51.285156 10.925781 C 51.601562 11 51.835938 11.214844 51.949219 11.523438 C 52.011719 11.6875 52.027344 11.816406 52.027344 12.039062 C 52.027344 12.199219 52.023438 12.265625 52.003906 12.351562 C 51.914062 12.738281 51.707031 12.992188 51.390625 13.101562 C 51.25 13.152344 51.132812 13.164062 50.960938 13.15625 C 50.707031 13.144531 50.507812 13.070312 50.339844 12.914062 C 50.1875 12.769531 50.105469 12.621094 50.046875 12.382812 C 50.019531 12.277344 50.015625 12.238281 50.015625 12.023438 C 50.019531 11.800781 50.019531 11.765625 50.050781 11.65625 C 50.15625 11.25 50.425781 10.984375 50.8125 10.914062 C 50.925781 10.890625 51.171875 10.898438 51.285156 10.925781 Z M 51.285156 10.925781 " />
                <path d="M 15.324219 12.03125 L 15.324219 13.648438 L 16.078125 13.644531 C 16.804688 13.640625 16.839844 13.640625 16.953125 13.613281 C 17.675781 13.449219 18.128906 12.925781 18.1875 12.183594 C 18.199219 12.011719 18.1875 11.804688 18.15625 11.648438 C 18.03125 11.039062 17.589844 10.589844 16.984375 10.457031 C 16.804688 10.414062 16.636719 10.40625 15.96875 10.40625 L 15.324219 10.40625 Z M 16.8125 10.925781 C 17.308594 11.050781 17.566406 11.433594 17.566406 12.027344 C 17.566406 12.554688 17.355469 12.929688 16.972656 13.085938 C 16.8125 13.148438 16.785156 13.152344 16.351562 13.15625 L 15.941406 13.164062 L 15.941406 10.890625 L 16.339844 10.898438 C 16.652344 10.902344 16.753906 10.910156 16.8125 10.925781 Z M 16.8125 10.925781 " />
                <path d="M 19.566406 10.484375 C 19.433594 10.820312 18.332031 13.636719 18.332031 13.640625 C 18.332031 13.644531 18.449219 13.648438 18.59375 13.648438 C 18.890625 13.648438 18.917969 13.644531 18.96875 13.5625 C 18.984375 13.539062 19.054688 13.371094 19.121094 13.191406 L 19.238281 12.863281 L 20.566406 12.863281 L 20.585938 12.90625 C 20.75 13.367188 20.820312 13.546875 20.851562 13.578125 C 20.914062 13.644531 20.941406 13.648438 21.222656 13.648438 C 21.457031 13.648438 21.476562 13.648438 21.472656 13.628906 C 21.46875 13.613281 20.371094 10.808594 20.222656 10.433594 L 20.210938 10.40625 L 19.597656 10.40625 Z M 20.199219 11.84375 C 20.3125 12.15625 20.40625 12.410156 20.402344 12.414062 C 20.402344 12.417969 20.175781 12.417969 19.902344 12.414062 L 19.40625 12.410156 L 19.621094 11.820312 C 19.738281 11.496094 19.851562 11.179688 19.867188 11.117188 L 19.902344 11.003906 L 19.945312 11.140625 C 19.972656 11.214844 20.085938 11.535156 20.199219 11.84375 Z M 20.199219 11.84375 " />
                <path d="M 21.789062 12.027344 L 21.789062 13.648438 L 22.398438 13.648438 L 22.398438 13.035156 C 22.398438 12.699219 22.402344 12.417969 22.40625 12.410156 C 22.414062 12.394531 22.457031 12.394531 22.59375 12.394531 C 22.789062 12.398438 22.796875 12.402344 22.863281 12.496094 C 22.882812 12.523438 23.042969 12.761719 23.214844 13.027344 C 23.386719 13.292969 23.546875 13.53125 23.566406 13.554688 C 23.632812 13.644531 23.660156 13.648438 24.007812 13.648438 C 24.175781 13.648438 24.3125 13.648438 24.3125 13.644531 C 24.3125 13.640625 24.128906 13.363281 23.902344 13.027344 C 23.589844 12.5625 23.46875 12.394531 23.410156 12.335938 L 23.332031 12.261719 L 23.40625 12.234375 C 23.652344 12.140625 23.859375 11.941406 23.953125 11.710938 C 24.058594 11.449219 24.039062 11.101562 23.90625 10.875 C 23.785156 10.675781 23.570312 10.53125 23.277344 10.460938 C 23.085938 10.414062 22.929688 10.40625 22.339844 10.40625 L 21.789062 10.40625 Z M 22.949219 10.894531 C 22.996094 10.902344 23.066406 10.917969 23.109375 10.933594 C 23.324219 11.003906 23.4375 11.179688 23.425781 11.425781 C 23.410156 11.664062 23.292969 11.820312 23.0625 11.902344 C 22.976562 11.933594 22.960938 11.9375 22.683594 11.941406 L 22.398438 11.945312 L 22.398438 11.433594 C 22.398438 11.152344 22.398438 10.914062 22.402344 10.902344 C 22.410156 10.886719 22.441406 10.882812 22.636719 10.882812 C 22.761719 10.882812 22.902344 10.890625 22.949219 10.894531 Z M 22.949219 10.894531 " />
                <path d="M 24.652344 12.027344 L 24.652344 13.648438 L 25.261719 13.648438 L 25.261719 12.960938 C 25.261719 12.582031 25.261719 12.265625 25.265625 12.253906 C 25.277344 12.222656 25.539062 12.226562 25.609375 12.257812 C 25.667969 12.285156 25.632812 12.242188 26.234375 13.035156 C 26.613281 13.527344 26.667969 13.597656 26.71875 13.621094 C 26.769531 13.648438 26.78125 13.648438 27.085938 13.648438 L 27.398438 13.644531 L 26.8125 12.851562 C 26.492188 12.414062 26.207031 12.039062 26.183594 12.019531 C 26.160156 11.996094 26.117188 11.964844 26.09375 11.953125 L 26.042969 11.929688 L 26.078125 11.90625 C 26.097656 11.894531 26.144531 11.851562 26.183594 11.8125 C 26.269531 11.722656 27.308594 10.429688 27.308594 10.414062 C 27.308594 10.402344 26.765625 10.40625 26.699219 10.421875 C 26.652344 10.429688 26.617188 10.449219 26.582031 10.476562 C 26.554688 10.5 26.339844 10.765625 26.101562 11.070312 C 25.863281 11.371094 25.648438 11.640625 25.625 11.667969 C 25.558594 11.738281 25.503906 11.761719 25.371094 11.761719 L 25.261719 11.761719 L 25.261719 10.40625 L 24.652344 10.40625 Z M 24.652344 12.027344 " />
                <path d="M 27.699219 12.027344 L 27.699219 13.648438 L 28.316406 13.648438 L 28.316406 12.332031 L 29.519531 12.332031 L 29.519531 11.84375 L 28.316406 11.84375 L 28.316406 10.894531 L 29.738281 10.894531 L 29.738281 10.40625 L 27.699219 10.40625 Z M 27.699219 12.027344 " />
                <path d="M 33.8125 12.027344 L 33.8125 13.648438 L 34.359375 13.648438 L 34.355469 12.535156 L 34.351562 11.425781 L 34.472656 11.578125 C 34.539062 11.660156 34.898438 12.121094 35.273438 12.601562 C 35.648438 13.078125 35.976562 13.5 36.007812 13.539062 C 36.089844 13.636719 36.125 13.648438 36.351562 13.648438 L 36.542969 13.648438 L 36.542969 12.066406 C 36.542969 11.195312 36.539062 10.464844 36.535156 10.445312 L 36.527344 10.40625 L 36.011719 10.40625 L 36.003906 10.53125 C 36 10.601562 35.996094 11.101562 36 11.648438 C 36.003906 12.371094 36 12.632812 35.988281 12.621094 C 35.984375 12.613281 35.621094 12.148438 35.183594 11.589844 C 34.75 11.03125 34.367188 10.542969 34.335938 10.507812 C 34.261719 10.414062 34.238281 10.40625 34.007812 10.40625 L 33.8125 10.40625 Z M 33.8125 12.027344 " />
                <path d="M 38.328125 12.027344 L 38.328125 13.648438 L 40.363281 13.648438 L 40.363281 13.160156 L 38.945312 13.160156 L 38.945312 12.257812 L 40.058594 12.257812 L 40.066406 12.226562 C 40.070312 12.207031 40.070312 12.101562 40.066406 11.988281 L 40.058594 11.785156 L 38.945312 11.785156 L 38.945312 10.894531 L 40.363281 10.894531 L 40.363281 10.40625 L 38.328125 10.40625 Z M 38.328125 12.027344 " />
                <path d="M 40.886719 12.03125 L 40.886719 13.648438 L 41.640625 13.644531 C 42.367188 13.640625 42.402344 13.640625 42.515625 13.613281 C 43.238281 13.449219 43.691406 12.925781 43.75 12.183594 C 43.761719 12.011719 43.75 11.804688 43.71875 11.648438 C 43.59375 11.039062 43.152344 10.589844 42.546875 10.457031 C 42.367188 10.414062 42.199219 10.40625 41.535156 10.40625 L 40.886719 10.40625 Z M 42.378906 10.925781 C 42.871094 11.050781 43.132812 11.433594 43.132812 12.027344 C 43.132812 12.554688 42.921875 12.929688 42.535156 13.085938 C 42.378906 13.148438 42.351562 13.152344 41.914062 13.15625 L 41.503906 13.164062 L 41.503906 10.890625 L 41.902344 10.898438 C 42.21875 10.902344 42.316406 10.910156 42.378906 10.925781 Z M 42.378906 10.925781 " />
                <path d="M 44.273438 12.027344 L 44.273438 13.648438 L 44.890625 13.648438 L 44.890625 12.144531 C 44.890625 11.320312 44.886719 10.589844 44.882812 10.527344 L 44.875 10.40625 L 44.273438 10.40625 Z M 44.273438 12.027344 " />
                <path d="M 45.304688 10.65625 L 45.304688 10.90625 L 46.273438 10.90625 L 46.273438 13.648438 L 46.878906 13.648438 L 46.878906 12.285156 C 46.878906 11.054688 46.882812 10.921875 46.898438 10.914062 C 46.910156 10.910156 47.128906 10.90625 47.382812 10.90625 L 47.851562 10.90625 L 47.851562 10.40625 L 45.304688 10.40625 Z M 45.304688 10.65625 " />
                <path d="M 48.261719 12.027344 L 48.261719 13.648438 L 48.882812 13.648438 L 48.882812 12.144531 C 48.882812 11.320312 48.878906 10.589844 48.875 10.527344 L 48.867188 10.40625 L 48.261719 10.40625 Z M 48.261719 12.027344 " />
                <path d="M 53.175781 12.027344 L 53.175781 13.648438 L 53.726562 13.648438 L 53.71875 12.535156 L 53.714844 11.425781 L 53.855469 11.601562 C 53.933594 11.699219 54.300781 12.171875 54.675781 12.648438 C 55.046875 13.128906 55.367188 13.539062 55.390625 13.5625 C 55.457031 13.636719 55.5 13.648438 55.714844 13.648438 L 55.90625 13.648438 L 55.90625 12.066406 C 55.90625 11.195312 55.902344 10.464844 55.898438 10.445312 L 55.890625 10.40625 L 55.375 10.40625 L 55.367188 10.507812 C 55.363281 10.5625 55.359375 11.066406 55.363281 11.625 C 55.367188 12.367188 55.363281 12.632812 55.351562 12.621094 C 55.347656 12.613281 54.984375 12.148438 54.546875 11.589844 C 54.113281 11.03125 53.730469 10.542969 53.699219 10.507812 C 53.625 10.414062 53.601562 10.40625 53.371094 10.40625 L 53.175781 10.40625 Z M 53.175781 12.027344 " />
            </g>
        </svg>

    )
}


export const epttaCommandIcon = (iconColor, thisClassName = '') => {
    return (
        <svg fill={iconColor} className={thisClassName} viewBox="136.953 74.256 64 64" xmlns="http://www.w3.org/2000/svg">
            <path className="st1" d="M 150.751 100.601 C 151.451 99.901 152.951 99.401 154.951 99.401 L 199.651 99.401 L 199.651 88.201 L 150.151 88.201 C 148.751 88.201 147.251 88.401 145.751 88.901 C 144.351 89.301 142.951 90.001 141.851 90.901 C 140.651 91.801 139.651 92.901 138.951 94.101 C 138.251 95.401 137.851 96.901 137.851 98.401 L 137.851 115.801 C 137.851 117.401 138.251 118.901 138.951 120.101 C 139.651 121.301 140.551 122.501 141.751 123.301 C 142.851 124.101 144.151 124.801 145.651 125.301 C 147.051 125.701 148.651 126.001 150.251 126.001 L 199.751 126.001 L 199.751 114.701 L 154.951 114.701 C 152.951 114.701 151.551 114.301 150.851 113.601 C 150.151 112.901 149.751 111.701 149.751 110.001 L 149.751 103.801 C 149.551 102.501 149.951 101.401 150.751 100.601 Z" />
        </svg>
    )
}