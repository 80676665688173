import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { epttaCommandIcon } from './EpttaIcons';
const useStyles = makeStyles(theme => ({
	root: {
		'& .logo-icon': {
			width: 24,
			height: 24,
			transition: theme.transitions.create(['width', 'height'], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		},
		'& .react-badge, & .logo-text, & .logo-badge,': {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		}
	},
	reactBadge: {
		backgroundColor: '#121212',
		color: '#61DAFB'
	},
	logoBadge: {
		//backgroundColor: '#121212',
		color: '#61DAFB'
	}
}));

function Logo() {
	let brand = process.env.REACT_APP_BRAND
	const theme = useTheme()
	const classes = useStyles();
	const navBarFolded = useSelector(({ fuse }) => fuse.navbar.foldedOpen)

	if (navBarFolded) {
		return null
	}

	return (
		<div className={clsx(classes.root, 'flex items-center')}>
			{brand === 'commandDarkFon' &&
				<Icon style={{ fontSize: 40 }}>
					{epttaCommandIcon(theme.palette.secondary.main)}
				</Icon>
			}

			{brand === 'vigilance' &&
				<Icon style={{ fontSize: 40 }}>
					<img src="assets/images/logos/vigilance/icon.svg" alt="logo" />
				</Icon>
			}

			{brand === 'command' &&
				<Icon style={{ fontSize: 40 }}>
					{epttaCommandIcon(theme.palette.secondary.main)}
				</Icon>
			}

			{brand === 'active' &&
				<Icon style={{ fontSize: 50 }}>
					<img
						src='assets/images/logos/active/active icon_b.svg'
					//src='assets/images/logos/active/active icon_semi.svg'
					/>
				</Icon>
			}
			{brand === 'aid' &&
				<Icon style={{ fontSize: 50, marginTop: 15 }}>
					<img
						//src='assets/images/logos/aid/aid icon_WHITE_RED_trans.svg'
						src='assets/images/logos/aid/aid logo.svg'
					/>
				</Icon>
			}
			{brand === 'astratac' &&
				<Icon style={{ fontSize: 50 }}>
					<img
						src='assets/images/logos/astratac/astratac icon.svg'
						alt="logo"
					/>
				</Icon>
			}
			{brand === 'protecting' &&
				<Icon style={{ fontSize: 50 }}>
					<img
						src='assets/images/logos/protecting/protecting_icon_white.svg'
						//src='assets/images/logos/protecting/protecting icon_SEMITRANSPARENT.svg'
						alt="logo"
					//src='assets/images/logos/protecting/protecting icon.svg'						
					/>
				</Icon>
			}
			{brand === 'safe' &&
				<Icon style={{ fontSize: 50 }}>
					<img
						src='assets/images/logos/safe/safe_in icon.svg'
					/>
				</Icon>
			}
			{brand === 'selecta' &&
				<Icon style={{ fontSize: 50 }}>
					<img
						//src='assets/images/logos/aid/aid logo.svg'
						src='assets/images/logos/selecta/SELECTA icon WHITE.svg'
					/>
				</Icon>
			}
			{brand === 'tempest' &&
				<Icon style={{ fontSize: 50 }}>
					<img
						//src='assets/images/logos/aid/aid logo.svg'
						src='assets/images/logos/tempest/tempest_icon_GREY.svg'
					/>
				</Icon>
			}
			{brand === 'dia_talk' &&
				<Icon style={{ fontSize: 50 }}>
					<img
						src='assets/images/logos/dia_talk/icon.png'
					/>
				</Icon>
			}

			{brand === 'nova' &&
				<Icon style={{ fontSize: 50, marginTop: 30 }}>
					<img
						//src='assets/images/logos/aid/aid logo.svg'
						src='assets/images/logos/nova/nova_logo_white.svg'
					/>
				</Icon>
			}

			{brand === 'systelios' &&
				<Icon
					style={{ fontSize: 40 }}
				>
					<img
						src='assets/images/logos/systelios/logo_systelios__icon.svg'
					/>
				</Icon>
			}

			{/* {/* <Typography className={clsx(classes.logoBadge, 'text-20 mx-12 font-light logo-text')}>
				{user.data.tenant}
			</Typography>
			<div className={clsx(classes.reactBadge, 'react-badge flex items-center py-4 px-8 rounded')}>
				<img
					className="react-logo"
					src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K"
					alt="react"
					width="16"
				/>
				<span className="react-text text-12 mx-4">React</span>
			</div> */}
		</div>
	);
}

export default Logo;
