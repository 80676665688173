import axios from 'axios';
//import { showMessage } from 'react/app/store/actions/fuse';

export const GET_AGENTS_LOCATIONS = '[MANAGE-COMMAND APP] GET AGENTS_LOCATIONS'; //akisx
export const CLEAR_AGENTS_LOCATIONS_STORE = '[MANAGE-COMMAND APP] CLEAR AGENTS LOCATIONS STORE'; //akisx


export function clearAgentLocationsStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_AGENTS_LOCATIONS_STORE
		})
		
}

export function getAgentsLocations() {
	const request = axios.get(`EpttaAdmin/AgentsLastLocations`);
	
	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_AGENTS_LOCATIONS,
				payload: response.data
			})
		);
	}