import FuseUtils from '@fuse/utils';
import _ from '@lodash';
import jwt from 'jsonwebtoken';
import mock from '../mock';
/* eslint-disable camelcase */

const jwtConfig = {
	secret: 'some-secret-code-goes-here',
	expiresIn: '2 days' // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, etc)
};

const authDB = {
	users: [
		{
			uuid: 'XgbuVEXBU5gtSKdbQRP1Zbbby1i1',
			from: 'custom-db',
			password: 'admin',
			role: 'admin',
			data: {
				tenant: 'eyeonix',
				tenant_Id: '883366db-7e89-4e5f-9c5f-c7dcb3b67bcc',
				displayName: 'Akis Chalidas',
				photoURL: 'assets/images/avatars/akisx.jpg',
				username: 'admin',
				email: 'akis.xalidas23@eyeonix.com',
				settings: {
					layout: {
						style: 'layout1',
						config: {
							scroll: 'content',
							navbar: {
								display: true,
								folded: true,
								position: 'left'
							},
							toolbar: {
								display: true,
								style: 'fixed',
								position: 'below'
							},
							footer: {
								display: true,
								style: 'fixed',
								position: 'below'
							},
							mode: 'fullwidth'
						}
					},
					customScrollbars: true,
					theme: {
						main: 'defaultDark',
						navbar: 'defaultDark',
						toolbar: 'defaultDark',
						footer: 'defaultDark'
					}
				},
				shortcuts: ['calendar', 'mail', 'contacts']
			}
		},
		{
			uuid: 'XgbuVEXBU6gtSKdbTYR1Zbbby1i3',
			from: 'custom-db',
			password: 'staff',
			role: 'staff',
			data: {
				tenant: 'eyeonix',
				displayName: 'Arnold Matlock',
				photoURL: 'assets/images/avatars/Arnold.jpg',
				email: 'staff',
				settings: {
					layout: {
						style: 'layout2',
						config: {
							mode: 'boxed',
							scroll: 'content',
							navbar: {
								display: true
							},
							toolbar: {
								display: true,
								position: 'below'
							},
							footer: {
								display: true,
								style: 'fixed'
							}
						}
					},
					customScrollbars: true,
					theme: {
						main: 'greeny',
						navbar: 'mainThemeDark',
						toolbar: 'mainThemeDark',
						footer: 'mainThemeDark'
					}
				},
				shortcuts: ['calendar', 'mail', 'contacts', 'todo']
			}
		}
	]
};

mock.onGet('/api/auth').reply(config => {
	const data = JSON.parse(config.data);
	const { tenant, username, password } = data;

	const user = _.cloneDeep(authDB.users.find(_user => _user.data.username === username));

	const error = {
		username: user ? null : 'Check your Username',
		password: user && user.password === password ? null : 'Check your Password',
		tenant: user && user.data.tenant === tenant ? null : 'Check your Tenant'
	};

	if (!error.tenant && !error.username && !error.password && !error.displayName) {
		delete user.password;
		//delete user.data.tenant //AkisX

		//const access_token = jwt.sign({ id: user.uuid }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn });
		const access_token = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoiQWdlbnQxMEBleWVvbml4IiwiaWF0IjoxNTg0Njk3Njk2LCJleHAiOjE1ODU1OTc2OTZ9.3YkaTHda2haPSbPSUZ7fqsa8fOcUPDBWdz9rk2u0S-oAC9FLV2o7BavIrc8z1S-0Z0tKfwkF8cL7HtaUGq3WUg';
		const response = {
			user,
			access_token
		};

		return [200, response];
	}
	return [200, { error }];
});

mock.onGet('/api/auth/access-token').reply(config => {
	//const data = JSON.parse(config.data);
	//const { access_token } = data;

	try {
		//const { id } = jwt.verify(access_token, jwtConfig.secret);
		//const { id } = 'XgbuVEXBU5gtSKdbQRP1Zbbby1i1';

		const user = _.cloneDeep(authDB.users.find(_user => _user.uuid === 'XgbuVEXBU5gtSKdbQRP1Zbbby1i1'));
		delete user.password;
		//delete user.data.tenant; //AkisX

		//const updatedAccessToken = jwt.sign({ id: user.uuid }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn });
		const updatedAccessToken = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoiQWdlbnQxMEBleWVvbml4IiwiaWF0IjoxNTg0Njk3Njk2LCJleHAiOjE1ODU1OTc2OTZ9.3YkaTHda2haPSbPSUZ7fqsa8fOcUPDBWdz9rk2u0S-oAC9FLV2o7BavIrc8z1S-0Z0tKfwkF8cL7HtaUGq3WUg';
		
		const response = {
			user,
			access_token: updatedAccessToken
		};

		return [200, response];
	} catch (e) {
		const error = 'Invalid access token detected';
		return [401, { error }];
	}
});

mock.onPost('/api/auth/register').reply(request => {
	const data = JSON.parse(request.data);
	const { displayName, password, username, tenant } = data;
	const isUsernameExists = authDB.users.find(_user => _user.data.username === username);
	const error = {
		username: isUsernameExists ? 'The user is already in use' : null,
		displayName: displayName !== '' ? null : 'Enter display name',
		password: null,
		tenant: null
	};
	if (!error.displayName && !error.password && !error.username && !error.tenant) {
		const newUser = {
			uuid: FuseUtils.generateGUID(),
			from: 'custom-db',
			tenant,
			password,
			role: 'admin',
			data: {
				displayName,
				photoURL: 'assets/images/avatars/Abbott.jpg',
				username,
				settings: {},
				shortcuts: []
			}
		};

		authDB.users = [...authDB.users, newUser];

		const user = _.cloneDeep(newUser);
		delete user.password;
		//delete user.tenant; //AkisX

		const access_token = jwt.sign({ id: user.uuid }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn });

		const response = {
			user,
			access_token
		};

		return [200, response];
	}
	return [200, { error }];
});

mock.onPost('/api/auth/user/update').reply(config => {
	const data = JSON.parse(config.data);
	const { user } = data;

	authDB.users = authDB.users.map(_user => {
		if (user.uuid === user.id) {
			return _.merge(_user, user);
		}
		return _user;
	});

	return [200, user];
});
