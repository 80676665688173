import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as NavigationActions from '../../store/actions/fuse/navigation.actions'
import { authRoles } from 'react/app/auth';
import * as Actions from '../../main/apps/store/actions';

const chartsNavItem = {
	id: 'only-admin-super-admin-superAdmins',
	title: 'Charts-Analytics',
	type: 'item',
	auth: authRoles.superAdmin,
	url: '/apps/super-admin/chart',
	icon: 'assessment'
}

const WeatherForecastMapNavItem = {
	id: 'only-admin-super-admin-weather-app',
	title: 'Weather Forecast Map',
	type: 'item',
	auth: authRoles.superAdmin,
	url: '/apps/super-admin/weather-app',
	icon: 'map'
}



function Navigation(props) {
	const dispatch = useDispatch()
	const navigation = useSelector(({ fuse }) => fuse.navigation);
	const user = useSelector(({ auth }) => auth.user);
	const showChartsState = useSelector(({ fuse }) => fuse.settings.showChartsState);
	const tenantSettings = useSelector(({ commandAdminApp }) => commandAdminApp?.tenantSettings?.tenantSettingsData);


	useEffect(() => {
		function updateTenantSettingState() {
			if (!(tenantSettings?.length > 0)) {
				let authUser = user?.srvData?.user
				if (user) {
					let tenant = authUser.tenantId;
					dispatch(Actions.getTenantSettings(tenant, authUser));
				}

			}
		}
		updateTenantSettingState();
	}, [user])

	useEffect(() => {
		dispatch(NavigationActions.resetNavigation())
		setNavPermissions()
	}, [showChartsState, user, tenantSettings])


	function setNavPermissions() {
		if (showChartsState && user.data.role === "superAdmin") {
			dispatch(NavigationActions.appendNavigationItem(chartsNavItem))
		}
		if (user.data.role === "superAdmin") {

			if (tenantSettings?.length > 0) {
				let isWeatherForecastEnabled = false;
				tenantSettings.map(({ key }) => {
					if (key === "WEATHER_FORCAST") {
						isWeatherForecastEnabled = true
					}
				})

				if (!isWeatherForecastEnabled) {
					dispatch(NavigationActions.removeNavigationItem("only-admin-super-admin-weather-app"))
				} else {
					dispatch(NavigationActions.appendNavigationItem(WeatherForecastMapNavItem))
				}

			}
		}
	}

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={navigation}
			layout={props.layout}
			dense={props.dense}
			active={props.active}
		/>
	);
}

Navigation.defaultProps = {
	layout: 'vertical'
};

export default React.memo(Navigation);
