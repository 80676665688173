import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	mapProvidersData: [],//akisx
	enabledMapProvidersData: null,
	searchText: '',
	mapProviderData: null,
	delMapProvider: null,
	mapProviderDialogData:{
		state:false,
		id:"new"
	},
	// mapProviderDialogStatusState: false,
	// mapProviderDialogStatusId: "new",
	multiMapProviderDialogStatusState: false,
	multiMapProviderDialogStatusId: "",
	enableMultiImportMapProviders: false
};

const mapProvidersReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.ENABLE_MULTI_IMPORT_MAP_PROVIDERS:{
			return{
				...state,
				enableMultiImportMapProviders: action.payload
			}
		}
		case Actions.GET_MAPPROVIDERS: {
			if(action.payload.length > 0) {
				const sortetData = _.sortBy(action.payload,"name")
				let enabledMapsData = sortetData.filter((data) => data.isEnabled === true)
				return {
					...state,
					mapProvidersData: sortetData,
					enabledMapProvidersData: enabledMapsData
				};
			}else {

				return {
					...state,
					mapProvidersData: [],
					enabledMapProvidersData:[]
				};
			}
		}
		case Actions.SET_MAPPROVIDERS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_MAPPROVIDER: {
			return {
				...state,
				mapProvidersData: state.mapProvidersData.concat(action.payload)
			};
		}
		case Actions.GET_MAPPROVIDER: {
			return {
				...state,
				mapProviderData: action.payload
			};
		}
		case Actions.SAVE_MAPPROVIDER: {
			//console.log("Update mapProvider! "+JSON.stringify(action.payload))
			let data = state.mapProvidersData.filter((data) => data._id !== action.payload._id)
			let data2 = data.concat(action.payload)
			return {
				...state,
				mapProviderData: action.payload,
				mapProvidersData: data2
			};
		}
		case Actions.MAPPROVIDER_DIALOG_DATA: {
			// return{
			// 	...state,
			// 	mapProviderDialogStatusState: action.payload.state,
			// 	mapProviderDialogStatusId: action.payload.id
			// }
			return{
				...state,
				mapProviderDialogData:{
					state: action.payload.state,
					id: action.payload.id
				}
			}
		}
		case Actions.MULTIMAPPROVIDER_DIALOG_STATUS_STATE : {
			return {
				...state,
				multiMapProviderDialogStatusState: action.payload.state,
				multiMapProviderDialogStatusId: action.payload.id
			}
		}
		case Actions.DELETE_MAPPROVIDER: {
			return {
				...state,
				delMapProvider: action,
				mapProviderData: null,
				mapProvidersData: state.mapProvidersData.filter((data) => data._id !== action.payload._id)
			};
		}
		case Actions.CLEAR_MPROVIDERS_STORE: {
			return {
				...initialState
			}
		}
		default: {
			return state;
		}
	}
};

export default mapProvidersReducer;
