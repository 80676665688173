import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_TENANTSETTINGS = '[MANAGE-COMMAND APP] GET TENANTSETTINGS'; //akisx
export const SET_TENANTSETTINGS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET TENANTSETTINGS SEARCH TEXT';
export const NEW_TENANTSETTING = '[MANAGE-COMMAND APP] NEW TENANTSETTING'; //akisx

export const GET_TENANTSETTING = '[MANAGE-COMMAND APP] GET TENANTSETTING';//akisx
export const GET_TENANTITEMS = '[MANAGE-COMMAND APP] GET TENANTITEMS';//akisx
export const SAVE_TENANTSETTING = '[MANAGE-COMMAND APP] SAVE TENANTSETTING'; //akisx
export const DELETE_TENANTSETTING = '[MANAGE-COMMAND APP] DELETE TENANTSETTING'; //akisx
export const CLEAR_TENANT_SETTINGS_STORE = '[MANAGE-COMMAND APP] CLEAR TENANT SETTINGS STORE'; //akisx
export const TENANT_SETTINGS_DIALOG_DATA = '[MANAGE-COMMAND APP] TENANT SETTINGS DIALOG DATA'; //akisx

export function clearTenantSettingsStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_TENANT_SETTINGS_STORE
		})
}

export function tenantSettingsDialogData(state){
	return {
		type: TENANT_SETTINGS_DIALOG_DATA,
		payload: state
	}
}

export function newTenantSetting(data, message, authUser) {

	if (authUser.srvData.user.role[0] === 'tenantadmin'){
		const request = axios.post('EpttaTenantAdmin/TenantSettingsManager', data);

		return dispatch =>
			request.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				// dispatch(showMessage({ variant: 'success', message: 'TenantSetting Saved' }));
	
				return dispatch({
					type: NEW_TENANTSETTING,
					payload: response.data
				});
			})
	}else{
		const request = axios.post('EpttaAdmin/TenantSettingsManager', data);

		return dispatch =>
			request.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				// dispatch(showMessage({ variant: 'success', message: 'TenantSetting Saved' }));
	
				return dispatch({
					type: NEW_TENANTSETTING,
					payload: response.data
				});
			})
	}
	
}


export function getTenantSettings(param, authUser) {


	if(authUser.role[0] === 'tenantadmin') {
		const request = axios.get(`EpttaTenantAdmin/TenantSettingsManager/${param}`);

		return dispatch =>
			request.then(response =>
				dispatch({
					type: GET_TENANTSETTINGS,
					payload: response.data
				})
			);			
	}else{
		const request = axios.get(`EpttaAdmin/TenantSettingsManager/${param}`);

		return dispatch =>
			request.then(response =>
				dispatch({
					type: GET_TENANTSETTINGS,
					payload: response.data
				})
			);	
	}

}

export function getTenantItems() {

	const request = axios.get(`EpttaAdmin/Tenants/Count`);
	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_TENANTITEMS,
				payload: response.data
			})
		);	
}

export function setTenantSettingsSearchText(event) {
	return {
		type: SET_TENANTSETTINGS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getTenantSetting(tenantSettingsId, authUser) {
	
	if(authUser.srvData.user.role[0] === 'tenantadmin') {
		const request = axios.get(`EpttaTenantAdmin/TenantSettingsManager/Setting/${tenantSettingsId}`);//need paris get by id

		return dispatch =>
			request.then(response =>
				dispatch({
					type: GET_TENANTSETTING,
					payload: response.data
				})
			);
	}else{
		const request = axios.get(`EpttaAdmin/TenantSettingsManager/Setting/${tenantSettingsId}`);//need paris get by id

		return dispatch =>
			request.then(response =>
				dispatch({
					type: GET_TENANTSETTING,
					payload: response.data
				})
			);
	}
	
}

export function saveTenantSetting(data, message, authUser) {

	if (authUser.srvData.user.role[0] === 'tenantadmin'){
		const request = axios.put('EpttaTenantAdmin/TenantSettingsManager', data);

		return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			// dispatch(showMessage({ variant: 'success', message: 'TenantSetting Saved' }));
			//console.log("save response: " +JSON.stringify(response));
			data.lastUpdate = new Date()
			return dispatch({
				type: SAVE_TENANTSETTING,
				payload: data
			});
		})
		
	}else{
		const request = axios.put('EpttaAdmin/TenantSettingsManager', data);

		return dispatch =>
			request.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				// dispatch(showMessage({ variant: 'success', message: 'TenantSetting Saved' }));
				//console.log("save response: " +JSON.stringify(response));
				data.lastUpdate = new Date()
				return dispatch({
					type: SAVE_TENANTSETTING,
					payload: data
				});
			})
	}

	
}

export function deleteTenantSetting(data, message, authUser) {

	if (authUser.srvData.user.role[0] === 'tenantadmin'){
		const request = axios.delete(`EpttaTenantAdmin/TenantSettingsManager?tenantSettingsId=${data._id}`);

		return dispatch =>
			request.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				// dispatch(showMessage({ variant: 'success', message: 'TenantSetting Deleted' }));
				//console.log("delete response: " +JSON.stringify(response));

				return dispatch({
					type: DELETE_TENANTSETTING,
					payload: data
				});
			})
	}else{
		const request = axios.delete(`EpttaAdmin/TenantSettingsManager?tenantSettingsId=${data._id}`);

		return dispatch =>
			request.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				// dispatch(showMessage({ variant: 'success', message: 'TenantSetting Deleted' }));
				//console.log("delete response: " +JSON.stringify(response));

				return dispatch({
					type: DELETE_TENANTSETTING,
					payload: data
				});
			})

	}
	
}

export function newTenantSettingTemplate(tenant, authUser) {
	
	if (authUser.srvData.user.role[0] === 'tenantadmin'){
		const data = {
			"_id": "new",
			"created": "",
			"description": "",
			"key": "key",
			"lastUpdate": "2020-03-29T18:10:25.522Z",
			"tenantId": tenant,
			"value": "value",
			"valueType": "type"
		  };
	
		return {
			type: GET_TENANTSETTING,
			payload: data
		};

	}else{
		const data = {
			"_id": "new",
			"created": "",
			"description": "",
			"key": "key",
			"lastUpdate": "2020-03-29T18:10:25.522Z",
			"tenantId": tenant,
			"value": "value",
			"valueType": "type"
		  };
	
		return {
			type: GET_TENANTSETTING,
			payload: data
		};
	}	
}

