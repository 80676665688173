import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_AGENT_DEVICES = '[MANAGE-COMMAND APP] GET AGENT_DEVICES'; //akisx
export const SET_SELECTED_DSOURCE_AGENT = '[MANAGE-COMMAND APP] SET SET_SELECTED_DSOURCE_AGENT';
export const CLEAR_SELECTED_DSOURCE_AGENT = '[MANAGE-COMMAND APP] CLEAR CLEAR_SELECTED_DSOURCE_AGENT';
export const SAVE_AGENT_DEVICES = '[MANAGE-COMMAND APP] SAVE AGENT_DEVICES'; //akisx
export const NEW_AGENT_DEVICE = '[MANAGE-COMMAND APP] NEW AGENT_DEVICE';//akisx
export const DELETE_AGENT_DEVICE = '[MANAGE-COMMAND APP] DELETE AGENT_DEVICE';//akisx
export const GET_AGENT_DEVICE = '[MANAGE-COMMAND APP] GET AGENT_DEVICE';//akisx
export const CLEAR_AGENT_DEVICES_STORE = '[MANAGE-COMMAND APP] CLEAR AGENTDEVICES STORE'; //akisx
export const AGENT_DEVICE_DIALOG_DATA = '[MANAGE-COMMAND APP] AGENT DEVICE DIALOG DATA'; //akisx


export function clearAgentDevicesStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_AGENT_DEVICES_STORE
		})

}

export function agentDeviceDialogData(data) {
	return {
		type: AGENT_DEVICE_DIALOG_DATA,
		payload: data
	}
}

export function newAgentDevice(data, message) {
	const request = axios.post('EpttaAdmin/AgentDevices/createAgentDevice', data);

	return dispatch =>
		request.then(response => {
			//dispatch(showMessage({ variant: 'success', message: 'Saved' }));
			dispatch(showMessage({ variant: 'success', message: message }));
			//console.log("NEW ERROR RESPONSE"+response)

			return dispatch({
				type: NEW_AGENT_DEVICE,
				payload: response.data
			});
		})
			.catch(e => {
				if (e?.response && e.response?.data) {
					if (e.response.data.error) {
						dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
					} else {
						dispatch(showMessage({ variant: 'error', message: e.response.data }));
					}

					//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
				}
				else {
					dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
				}
			});;
}

export function deleteAgentDevice(targetDeleteAgentDevice, message) {
	const request = axios.delete(`EpttaAdmin/AgentDevices/deleteDeviceById/${targetDeleteAgentDevice._id}`);
	//console.log("delete response: " +JSON.stringify(targetDeleteAgentDevice));
	return dispatch =>
		request.then(response => {
			//dispatch(showMessage({ variant: 'success', message: 'Device Deleted' }));
			dispatch(showMessage({ variant: 'success', message: message }));

			return dispatch({
				type: DELETE_AGENT_DEVICE,
				payload: targetDeleteAgentDevice
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});;
}

export function getAgentDevices(param) {

	const request = axios.get(`EpttaAdmin/AgentDevices/AgentDevices/${param}`)

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_AGENT_DEVICES,
				payload: response.data
			})
		);
}

export function newAgentDeviceTemplate(sourceAgentId) {

	const data = {
		"IMEI": "",
		"_id": "new",
		"agentId": sourceAgentId,
		"brandName": "",
		"created": "",
		"firmware": "",
		"imei": "",
		"lastUpdate": "",
		"modelVersion": "",
		"platform": "",
		"serialNumber": ""
	};

	return {
		type: GET_AGENT_DEVICE,
		payload: data
	};
}

export function setSelectedDSourceAgent(agentId) {
	return {
		type: SET_SELECTED_DSOURCE_AGENT,
		selectedSourceAgent: agentId
	};
}

export function clearSelectedDSourceAgent() {
	let clear = ""
	return {
		type: CLEAR_SELECTED_DSOURCE_AGENT,
		selectedSourceAgent: clear
	};
}
