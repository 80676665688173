import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_GROUPSSUBS = '[MANAGE-COMMAND APP] GET GROUPSSUBS'; //akisx
export const SET_SELECTED_GSOURCE_AGENT = '[MANAGE-COMMAND APP] SET SET_SELECTED_GSOURCE_AGENT';
export const SET_SELECTED_GTARGET_GROUP = '[MANAGE-COMMAND APP] SET SET_SELECTED_GTARGET_GROUP';
export const SAVE_GROUPSSUBS = '[MANAGE-COMMAND APP] SAVE GROUPSSUBS'; //akisx
export const NEW_GROUPSUB = '[MANAGE-COMMAND APP] NEW GROUPSUB';//akisx
export const DELETE_GROUPSUB = '[MANAGE-COMMAND APP] DELETE GROUPSUB';//akisx
export const GET_GROUPSUB = '[MANAGE-COMMAND APP] GET GROUPSUB';//akisx
export const CLEAR_GROUP_SUBS_STORE = '[MANAGE-COMMAND APP] CLEAR GROUP SUBS STORE'; //akisx
export const SAVE_MULTI_GROUP_SUBS = '[MANAGE-COMMAND APP] SAVE MULTI GROUP SUBS'; //akisx
export const IS_MULTI_GROUP_SUBS = '[MANAGE-COMMAND APP] IS MULTI GROUP SUBS'; //akisx


export function clearGroupSubsStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_GROUP_SUBS_STORE
		})

}
export function newGroupSub(data, message) {
	const request = axios.post('EpttaAdmin/ChannelSubscription', data);

	return dispatch =>
		request.then(response => {
			//dispatch(showMessage({ variant: 'success', message:'Subscription Saved' }));
			dispatch(showMessage({ variant: 'success', message: message }));


			return dispatch({
				type: NEW_GROUPSUB,
				payload: response.data
			});
		})
			.catch(e => {
				if (e?.response && e.response?.data) {
					if (e.response.data.error) {
						dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
					} else {
						dispatch(showMessage({ variant: 'error', message: e.response.data }));
					}

					//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
				}
				else {
					dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
				}
			});;
}

export function deleteGroupSub(targetDeleteGroupSub, message) {
	const request = axios.delete(`EpttaAdmin/ChannelSubscription?id=${targetDeleteGroupSub._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Subscription Deleted' }));
			//console.log("delete response: " +JSON.stringify(targetDeleteGroupSub));

			return dispatch({
				type: DELETE_GROUPSUB,
				payload: targetDeleteGroupSub
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});;
}

export function getGroupsSubs() {
	//console.log("axios get groups called")
	const request = axios.get(`EpttaAdmin/ChannelSubscriptions/GetByType?page=0&size=9999&type=G`)

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_GROUPSSUBS,
				payload: response.data.content
			})
		);
}

export function saveGroupsSubs(data, message, setClearForm) {
	const request = axios.put('EpttaAdmin/ChannelSubscription', data);

	return dispatch =>
		request.then(response => {
			//dispatch(showMessage({ variant: 'success', message: 'Subscriptions Saved' }));
			dispatch(showMessage({ variant: 'success', message: message }));
			//console.log("save response: " +JSON.stringify(response));
			setClearForm(data)
			return dispatch({
				type: SAVE_GROUPSSUBS,
				payload: response.data
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});;
}

export function saveMultiGroupSubs(channelIdsList, data, message) {
	data._id = 'multi'
	let multiGroupSubsObj = {
		channelIdsList: channelIdsList,
		channelSubscription: data
	}

	console.log("saveMultiGroupSubs action ", multiGroupSubsObj)

	const request = axios.post('EpttaAdmin/ChannelSubscription/multiChannelSubscriptionImportation', multiGroupSubsObj)

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			return dispatch({
				type: SAVE_MULTI_GROUP_SUBS,
				payload: response.data,
				channelIdsList: channelIdsList
			});
		}).catch(err => {
			if (err?.response && err.response?.data) {
				if (err.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: err.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: err.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			// else {
			// 	dispatch(showMessage({ variant: 'error', message: `Unhandle ERROR ${err}` }));
			// }
		})

}

export function isMultiGroupSubs(state) {
	return {
		type: IS_MULTI_GROUP_SUBS,
		payload: state
	}

}

export function newGroupSubTemplate(tenant, sourceAgentId, targetGroupId) {

	const data = {
		"_id": "new",
		"agentId": sourceAgentId,
		"channelId": targetGroupId,
		"channelSubscriptionPermissions": {
			"allowJoin": true,
			"allowListen": true,
			"allowMute": true,
			"allowReceiveAttachment": true,
			"allowReceiveTextMessage": true,
			"allowSendAttachment": true,
			"allowSendTextMessage": true,
			"allowSpeak": true,
			"created": "",
			"lastUpdate": "",
			"priority": 0
		},
		"channelSubscriptionState": {
			"_id": "new",
			"created": "",
			"deviceId": "string",
			"id": "string",
			"isAlwaysListening": true,
			"isMuted": true,
			"lastUpdate": "",
			"sessionId": "string"
		},
		"created": "",
		"id": "new",
		"lastUpdate": "",
		"permissions": {
			"allowJoin": true,
			"allowListen": true,
			"allowMute": true,
			"allowReceiveAttachment": true,
			"allowReceiveTextMessage": true,
			"allowSendAttachment": true,
			"allowSendTextMessage": true,
			"allowSpeak": true,
			"created": "",
			"lastUpdate": "",
			"priority": 0
		},
		"state": {
			"_id": "new",
			"created": "",
			"deviceId": "string",
			"id": "string",
			"isAlwaysListening": false,
			"isMuted": false,
			"lastUpdate": "",
			"sessionId": "string"
		}
	};

	return {
		type: GET_GROUPSUB,
		payload: data
	};
}

export function setSelectedGTargetGroup(groupId) {
	return {
		type: SET_SELECTED_GTARGET_GROUP,
		selectedTargetGroup: groupId
	};
}

export function setSelectedGSourceAgent(agentId) {
	return {
		type: SET_SELECTED_GSOURCE_AGENT,
		selectedSourceAgent: agentId
	};
}
