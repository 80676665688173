import { authRoles } from 'react/app/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import ar from '../i18n/ar';
import en from '../i18n/en';
import tr from '../i18n/tr';
import gr from '../i18n/gr'; //Akisx

i18next.addResourceBundle('en', 'manageGroups', en);
i18next.addResourceBundle('tr', 'manageGroups', tr);
i18next.addResourceBundle('ar', 'manageGroups', ar);
i18next.addResourceBundle('gr', 'manageGroups', gr); //Akisx

const GroupsAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/groups-management/groups/:groupId/:groupName?',
			component: React.lazy(() => import('./groups/Groups'))
		},
		{
			path: '/apps/groups-management/groups',
			component: React.lazy(() => import('./groups/Groups'))
		},
		{
			path: '/apps/groups-management/groupsSubsStatus',
			component: React.lazy(() => import('./groupsSubsStatus/GroupsSubsStatus'))
		},
		// {
		// 	path: '/apps/groups-management/groupsSubs/:groupSubId/:groupSubName?',
		// 	component: React.lazy(() => import('./groupsSubs/GroupSub'))
		// },
		// {
		// 	path: '/apps/groups-management/groupsSubs',
		// 	component: React.lazy(() => import('./groupsSubs/GroupsSubs'))
		// },
		{
			path: '/apps/groups-management/groupsSubs/:groupSubId/:groupSubName?',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/groupsSubs/GroupSub'))
		},
		{
			path: '/apps/groups-management/groupsSubs',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/groupsSubs/GroupsSubs'))
		},
		{
			path: '/apps/groups-management',
			component: () => <Redirect to="/apps/groups-management/groups" />
		}
	]
};

export default GroupsAppConfig;
