import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	licenseData: null,
	tenantAdminLicenseData: null
};

const licensesReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_LICENSE_DATA : {
			console.log("GET_LICENSE_DATA ", action.payload)
			return {
				...state,
				licenseData: action.payload
			}
		}
		case Actions.GET_TENANT_ADMIN_LICENSE_DATA: {
			console.log("GET_TENANT_ADMIN_LICENSE_DATA ", action.payload)

			return {
				...state,
				tenantAdminLicenseData: action.payload
			}
		}
		case Actions.CLEAR_LICENCE_STORE : {
			return {
				...initialState
			}
		}
		
		default: {
			return state;
		}
	}
};

export default licensesReducer;
