import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';
import * as AuthActions from 'react/app/auth/store/actions'

export const GET_ADMINS = '[MANAGE-COMMAND APP] GET ADMINS'; //akisx
export const SET_ADMINS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET ADMINS SEARCH TEXT';
export const NEW_ADMIN = '[MANAGE-COMMAND APP] NEW ADMIN'; //akisx

export const GET_ADMIN = '[MANAGE-COMMAND APP] GET ADMIN';//akisx
export const SAVE_ADMIN = '[MANAGE-COMMAND APP] SAVE ADMIN'; //akisx
export const SAVE_ADMIN_DEL_ROLE = '[MANAGE-COMMAND APP] SAVE ADMIN DEL ROLE'; //akisx
export const DELETE_ADMIN = '[MANAGE-COMMAND APP] DELETE ADMIN'; //akisx
export const CLEAR_ADMINS = '[MANAGE-COMMAND APP] CLEAR ADMIN'; //akisx
export const ADMIN_DIALOG_DATA = '[MANAGE-COMMAND APP] ADMIN DIALOG DATA'

export function clearAdminsStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_ADMINS
		})
		
}

export function adminDialogData(payload){
	return {
		type : ADMIN_DIALOG_DATA,
		payload : payload
	}
}

export function newAdmin(data, message) { //new admin just update my last admin
	console.log("form data", data)
	
	
	const request = axios.post('EpttaAdmin/Users', data);

	return dispatch =>
		request.then(response => {
			//dispatch(showMessage({ variant: 'success', message: 'Admin Saved' }));
			dispatch(showMessage({ variant: 'success', message: message }));

			return dispatch({
				type: NEW_ADMIN,
				payload: response.data
			});
		}).catch(e => {
			if(e?.response && e.response?.data) {
				if(e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				}else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}
				
				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}


export function getAdmins(authUser) {
	const request = axios.get(`EpttaAdmin/Users/GetAdmins`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_ADMINS,
				payload: response.data,
				authUser: authUser
			})
		);	
}

export function setAdminsSearchText(event) {
	return {
		type: SET_ADMINS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getAdmin(adminsId) {
	const request = axios.get(`EpttaAdmin/Users/GetUserById?id=${adminsId}`);//need paris get by id

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_ADMIN,
				payload: response.data
			})
		);
}

export function saveAdmin(data,check, message1, message2, isTimeToLogOut) {

	const request = axios.put('EpttaAdmin/Users', data);

	return dispatch =>
		request.then(response => {
			
			if(check) {
				dispatch(showMessage({ variant: 'success', message: message1 }));
				//dispatch(showMessage({ variant: 'success', message: 'Admin Role Deleted' }));

				return dispatch({
					type: DELETE_ADMIN,
					payload: data
				});
			}else {
				
				//dispatch(showMessage({ variant: 'success', message: 'Admin Saved' }));
				dispatch(showMessage({ variant: 'success', message: message2 }));
				//console.log("save response: " +JSON.stringify(response));
				data.lastUpdate= new Date()
				
				if (response.status === 200 && !isTimeToLogOut) {
					return dispatch({
						type: SAVE_ADMIN,
						payload: data
					});
	
				} else if(response.status !== 200){
					dispatch(showMessage({ variant: 'error', message: 'Response Status: ' + response.status }));
				}
				
				
			}

			
		}).catch(e => {
			if(e?.response && e.response?.data) {
				if(e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				}else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}
				
				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}

export function deleteAdmin(data, message) {
	let role = data.role
	if(role.indexOf("agent")>-1) {
		let point = role.indexOf("admin")
		let newRole = role.splice(point-1,1)
		let newData = data;
		newData.role = newRole;
		newData.object = {};
		
		//console.log("new Data: "+JSON.stringify(newData))
		return saveAdmin(newData,true)
		
	}else {
		//const request = axios.delete(`EpttaAdmin/Users?id=${data._id}`);
		const request = axios.delete(`EpttaAdmin/Users?id=${data._id}`);
		return dispatch =>
			request.then(response => {
				dispatch(showMessage({ variant: 'success', message: message}));
				//dispatch(showMessage({ variant: 'success', message: 'Admin Deleted'}));
				//console.log("delete response: " +JSON.stringify(response));

				return dispatch({
					type: DELETE_ADMIN,
					payload: data
				});
		})
	}
	
}

export function newAdminTemplate(tenant,tenantId) {
	//need to set object on create admin
	const data = {
		"_id": "new",
		"created": "",
		"deviceToken": [],
		"isActive": true,
		"lastUpdate": "",
		"object": {
			"role":"admin",
			"data":{
			   "tenant": tenant,//need tenantName here to pass to props
			   "displayName":"New Admin",//pass a name
			   "photoURL":"assets/images/avatars/avatar.png",//have to set a default
			   "email":"", //set the email
			   "settings":{
				  "layout":{
					 "style":"layout1",
					 "config":{
						"scroll":"content",
						"navbar":{
						   "display":true,
						   "folded":true,
						   "position":"left"
						},
						"toolbar":{
						   "display":true,
						   "style":"fixed",
						   "position":"below"
						},
						"footer":{
						   "display":true,
						   "style":"fixed",
						   "position":"below"
						},
						"mode":"fullwidth"
					 }
				  },
				  "customScrollbars":true,
				  "theme":{
					 "main":"defaultDark",
					 "navbar":"defaultDark",
					 "toolbar":"defaultDark",
					 "footer":"defaultDark"
				  }
			   },
			   "shortcuts":[
				  
			   ]
			}
		 },
		"password": "",
		"role": [
		  "admin"
		],
		"salt": "",
		"saltString": "",
		"tenantId": tenantId,
		"username": "New"
	  };

	return {
		type: GET_ADMIN,
		payload: data
	};
}

