import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { epttaCommandDarkfonLogo, epttaCommandLogo } from './EpttaIcons';

const useStyles = makeStyles(theme => ({
	root: {
		'&.user': {
			'& .displayName, & .email': {
				transition: theme.transitions.create('opacity', {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			}
		}
	},
	avatar: {
		width: 102,
		height: 102,
		position: 'absolute',
		top: 92,
		padding: 8,
		//background: '#ffffff',
		boxSizing: 'content-box',
		left: '50%',
		transform: 'translateX(-50%)',
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		}),
		'& > img': {
			borderRadius: '50%'
		}
	},
	logoBadge: {
		width: "100%",
		height: "100%",
		marginBottom: 12,
	}
}));

function UserNavbarHeader(props) {
	let brand = process.env.REACT_APP_BRAND
	const theme = useTheme()
	const user = useSelector(({ auth }) => auth.user.data);
	const navBarFolded = useSelector(({ fuse }) => fuse.navbar.foldedOpen)

	const classes = useStyles();

	if (!navBarFolded) {
		return null
	}

	return (
		<AppBar
			position="static"
			color="primary"
			elevation={0}
			classes={{ root: classes.root }}
			className="user relative flex flex-col items-center justify-center pb-12 z-0"
		>
			<div className={clsx(classes.root, 'flex items-center')}>

				<Icon style={{ fontSize: 5 }} className={classes.logoBadge}>
					{brand === 'commandDarkFon' &&
						epttaCommandDarkfonLogo(theme.palette.secondary.main, 'w-224')
					}

					{brand === 'command' &&
						epttaCommandLogo(theme.palette.secondary.main, 'w-224')
						// <img src="assets/images/logos/command/logo.svg" alt="logo" className="max-w-224 w-224" />
					}

					{brand === 'vigilance' &&
						<img src="assets/images/logos/vigilance/logo.svg" alt="logo" className="max-w-160 w-160" />
					}

					{brand === 'active' &&
						<img
							src='assets/images/logos/active/active logo_WHITE.svg'
							className="max-w-128 w-128"
						/>
					}

					{brand === 'aid' &&
						<img
							src='assets/images/logos/aid/aid logo.svg'
							className="max-w-128 w-128"
						/>
					}
					{brand === 'astratac' &&
						<img
							src='assets/images/logos/astratac/astratac logo2.svg'
							className="max-w-128 w-128"
						/>
					}
					{brand === 'protecting' &&
						<img
							src='assets/images/logos/protecting/protecting logo_WHITE_CENTER.svg'
							width={200}
						/>

					}
					{brand === 'safe' &&
						<img
							src='assets/images/logos/safe/safe_in logo.svg'
							className="max-w-128 w-128"
						/>
					}
					{brand === 'selecta' &&
						<img
							src='assets/images/logos/selecta/SelectaPTT Logo-WHITE.svg'
							width={200}
						/>
					}
					{brand === 'dia_talk' &&
						<img
							src='assets/images/logos/dia_talk/logo.png'
							width={200}
						/>
					}

					{brand === 'nova' &&
						<img
							src='assets/images/logos/nova/Nova-logo-vertical-white.svg'
							width={200}
						/>
					}
					{brand === 'systelios' &&
						<img
							src='assets/images/logos/systelios/logo_systelios.png'
							width={220}
						/>
					}
				</Icon>
			</div>
		</AppBar>
	);
}

export default UserNavbarHeader;
