import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	//agentsData: [],//akisx
	agentsNames: null,
	agentsData: null,//akisx
	searchText: '',
	agentData: null,
	agentItemsData: null,
	delAgent: null,
	agentEmergChanData: [],
	agentMarkerPreviewDialogState: false,
	isMultiAgentImport: false
};

const agentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.AGENT_MARKER_PREVIEW_DIALOG_STATE: {
			return {
				...state,
				agentMarkerPreviewDialogState: action.payload
			}
		}
		case Actions.IS_MULTI_AGENT_IMPORT: {
			return {
				...state,
				isMultiAgentImport: action.payload
			}
		}
		case Actions.GET_AGENTS: {
			if (action.payload.length > 0 && state.agentsData !== []) {
				const sortetData = _.sortBy(action.payload, "agentName")
				//console.log("sort Agents by name before save: "+JSON.stringify(sortetData))
				let agentsNames = new Map()
				action.payload.map(agent => {
					agentsNames.set(agent._id, agent.agentName)
				})

				return {
					...state,
					agentsData: sortetData,
					agentsNames: agentsNames
				};
			} else {

				return {
					...state,
					agentsData: [],
					agentsNames: new Map()
				};
			}
		}
		case Actions.SET_AGENTS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_AGENT: {
			console.log("NEW_AGENT ", action.payload)

			let data = state.agentsData.concat(action.payload)
			let sortetData = _.sortBy(data, "agentName")
			return {
				...state,
				agentsData: sortetData,
				agentsNames: state.agentsNames.set(action.payload._id, action.payload.agentName)
			};
		}
		case Actions.GET_AGENT: {
			return {
				...state,
				agentData: action.payload
			};
		}
		case Actions.GET_AGENT_ITEMS: {
			return {
				...state,
				agentItemsData: action.payload
			};
		}
		case Actions.GET_AGENT_EMERG_CHANS: {
			return {
				...state,
				agentEmergChanData: action.payload
			};
		}
		case Actions.SAVE_AGENT: {
			let data = state.agentsData.filter((data) => data._id !== action.payload._id)
			let data2 = data.concat(action.payload)
			let sortetData = _.sortBy(data2, "agentName")
			return {
				...state,
				agentData: action.payload,
				agentsData: sortetData,
				agentsNames: state.agentsNames.set(action.payload._id, action.payload.agentName)

			};
		}
		case Actions.DELETE_AGENT: {
			//console.log("delete agent!!!!"+JSON.stringify(action.payload))
			let newMap = state.agentsNames
			newMap.delete(action.payload._id)

			return {
				...state,
				delAgent: action,
				agentData: null,
				agentsData: state.agentsData.filter((data) => data._id !== action.payload._id),
				agentsNames: newMap
			};
		}
		case Actions.SAVE_MULTI_AGENTS: {
			//console.log("SAVE_MULTI_AGENTS reducer ", action.payload)
			const newAgents = []

			const agentObj = action.payload.agent
			const agentIds = action.payload.agentNames
			const agentNames = action.names

			let newAgentNames = state.agentsNames
			agentNames.map((name, i) => {
				newAgentNames.set(agentIds[i], name)
			})
			console.log("SAVE_MULTI_AGENTS reducer ", agentNames, agentIds, newAgentNames)
			for (let i = 0; i < agentIds.length; i++) {
				var agent = { ...agentObj }
				agent._id = agentIds[i]
				agent.state[0]._id = agentIds[i]
				agent.agentName = agentNames[i]
				//console.log("SAVE_MULTI_AGENTS new agent ", agent, i, agentNames[i], agentIds[i])

				newAgents.push(agent)
				//console.log("push to newAgents ", newAgents, i)
			}

			//console.log("SAVE_MULTI_AGENTS newAgents2 ", newAgents)

			let data = state.agentsData.concat(newAgents)
			let sortedData = _.sortBy(data, 'agentName')

			console.log("SAVE_MULTI_AGENTS sortedData ", sortedData)
			return {
				...state,
				agentsData: sortedData,
				agentsNames: newAgentNames
			}

		}
		case Actions.CLEAR_AGENTS_STORE: {
			console.log("clear agents store")
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default agentsReducer;
