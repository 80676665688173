import * as Actions from '../actions';

const initialState = {
	//role: [], // guest
	data: {
		//tenant: 'eyeonix',
		//displayName: 'Akis Chalidas2',
		photoURL: 'assets/images/avatars/Velazquez.jpg',
		//email: 'johndoe@withinpixels.com',
		//username: 'admin2',
		//shortcuts: []
	},
	srvData: {

	}
};

const user = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_USER_DATA: {
			return {
				...state,
				data: action.payload
			};
		}
		case Actions.SET_SRV_USER_DATA: {
			return {
				...state,
				srvData: action.payload
			};
		}
		case Actions.REMOVE_USER_DATA: {
			return {
				...initialState
			};
		}
		case Actions.USER_LOGGED_OUT: {
			
			return initialState;
		}
		default: {
			return state;
		}
	}
};

export default user;



//lathos ola  403
//sosto tenant 500
//sosto user 403
//sosto pass 403

//sosto tenant+USER  proxy auth
//sosto tenant+PASS 500