import * as Actions from '../actions'

const initialState = {
    confirmationDialogStatus : false,
    confirmationDialogContent:"",
    confirmationDialogActionType : "",
    confirmationSelectedItem : {}
}

const confirmationReducer = (state = initialState, action) =>{
    switch(action.type){

        case Actions.SET_CONFIRMATION_DIALOG_STATUS :{
            return {
                ...state,
                confirmationDialogStatus: action.confirmationDialogStatus
            }
        }

        case Actions.SET_CONFIRMATION_DIALOG_CONTENT : {
            
            return{
                ...state,
                confirmationDialogContent: action.confirmationDialogContent
            }
        }

        case Actions.SET_CONFIRMATION_ACTION_TYPE: {
            return {
                ...state,
                confirmationDialogActionType: action.confirmationActionType
            }
        }
        
        case Actions.SET_CONFIRMATION_SELECTED_ITEM: {
            return {
                ...state,
                confirmationSelectedItem : action.confirmationSelectedItem
            }
        }

        default: {
			return state;
		} 
    }
}

export default confirmationReducer;