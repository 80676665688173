var base64 = require('base-64');
const { SHA3 } = require('sha3');

const secureStorageKeys = {
    secureLocalStorageEncodingType: 'rc4',
    secureLocalStorageEncryptionSecret: 's3cr3tPa$$w0rd@123',
    serverUrlKey: new SHA3(512).update(base64.encode('jwt_server_url')).digest('hex'),
    jwtSerialN: new SHA3(512).update(base64.encode('jwt_serialN')).digest('hex'),
    jwtUUID: new SHA3(512).update(base64.encode('jwt_uuid')).digest('hex'),
    jwtToken: new SHA3(512).update(base64.encode('jwtToken')).digest('hex'),
    // token: new SHA3(512).update( base64.encode('token') ).digest('hex'),
    // agentId: new SHA3(512).update( base64.encode('jwt_agentId') ).digest('hex'),
    // uiUser: new SHA3(512).update( base64.encode('jwt_ui_user') ).digest('hex'),
};

export default secureStorageKeys;