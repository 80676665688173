import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';
import moment from 'moment/moment';
import _ from 'lodash';

export const SET_WEATHER_EPTTA_MAP = '[EPTTA-MAP] APP SET WEATHER EPTTA MAP'

export const GET_WEATHER_FORECAST_ANALYSIS_POINTS = '[EPTTA-MAP] APP GET WEATHER FORECAST ANALYSIS POINTS'


export const CLEAR_WEATHER_FORECAST_STORE = '[EPTTA-MAP] APP CLEAR WEATHER FORECAST STORE'


export function setWeatherEpttaMap(weatherEpttaMap) {
	return dispatch =>

		dispatch({
			type: SET_WEATHER_EPTTA_MAP,
			weatherEpttaMap: weatherEpttaMap
		})
}

export function getWeatherForecastAnalysisPoints() {
	const request = axios.get(`EpttaTenantAdmin/WeatherForecastAnalysisPoints`);
	return dispatch => {
		
		request.then(response => {
			console.log("GET_WEATHER_FORECAST_ANALYSIS_POINTS ", response)
			dispatch({
				type: GET_WEATHER_FORECAST_ANALYSIS_POINTS,
				weatherForecastAnalysisPoints: response.data.features ? response.data : null
			})
		}
		);
	}
		
}


export function deleteWeatherForecastAnalysisPoints(pointsGeoJSON,coordinates) {

	console.log("weatherForecastAnalysisPoints  delete before",pointsGeoJSON.features.features.length)

	let pointFeatures = pointsGeoJSON.features.features.filter(item=> {
		return !_.isEqual(item.geometry.coordinates,coordinates)
	})

	

	let newObjectData = {
		...pointsGeoJSON,
		features: {
			...pointsGeoJSON.features,
			features: pointFeatures
		}
	}

	console.log("weatherForecastAnalysisPoints  delete after",pointFeatures.length,newObjectData)

	const request = axios.post(`EpttaTenantAdmin/WeatherForecastAnalysisPoints`,newObjectData);
	return dispatch => {
		
		request.then(response => {
			if(response.status === 200) {
				dispatch({
					type: GET_WEATHER_FORECAST_ANALYSIS_POINTS,
					weatherForecastAnalysisPoints: newObjectData
				})
			}else {
				dispatch({
					type: GET_WEATHER_FORECAST_ANALYSIS_POINTS,
					weatherForecastAnalysisPoints: pointsGeoJSON
				})
			}
			
		}
		);
	}
		
}

export function setWeatherForecastAnalysisPoints(pointsGeoJSON,newFeturePoint,layer) {
	let newPoint = {
		type: "Feature",
		properties: newFeturePoint.properties,
		geometry: newFeturePoint.geometry
	}

	let newFeatures = pointsGeoJSON?.features.features.length > 0 ? [...pointsGeoJSON.features.features,newPoint] : [newPoint]

	let newObjectData = {
		_id: "WeatherForecastAnalysisPoints",
		features: {
			type: "FeatureCollection",
			features: newFeatures
		},
		created: "",
		lastUpdate: "",
		timestamp:""
	}


	const request = axios.post(`EpttaTenantAdmin/WeatherForecastAnalysisPoints`,newObjectData);
	return dispatch => {
		
		request.then(response => {
			if(response.status === 200) {
				dispatch({
					type: GET_WEATHER_FORECAST_ANALYSIS_POINTS,
					weatherForecastAnalysisPoints: newObjectData
				})
			}else {
				//layer.remove()
				dispatch({
					type: GET_WEATHER_FORECAST_ANALYSIS_POINTS,
					weatherForecastAnalysisPoints: pointsGeoJSON
				})
			}
			
		}
		);
	}
		
}



export function clearWeatherForecastStore() {
	return {
		type: CLEAR_WEATHER_FORECAST_STORE,
	};
}