import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';
import * as Actions from '../actions'

export const GET_CHANNELS = '[MANAGE-COMMAND APP] GET CHANNELS'; //akisx
export const SET_CHANNELS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET CHANNEL SEARCH TEXT';
export const NEW_CHANNEL = '[MANAGE-COMMAND APP] NEW CHANNEL'; //akisx

export const GET_CHANNEL = '[MANAGE-COMMAND APP] GET CHANNEL';//akisx
export const GET_CHANNEL_ITEMS = '[MANAGE-COMMAND APP] GET CHANNEL ITEMS';//akisx
export const SAVE_CHANNEL = '[MANAGE-COMMAND APP] SAVE CHANNEL'; //akisx
export const DELETE_CHANNEL = '[MANAGE-COMMAND APP] DELETE CHANNEL'; //akisx
export const CLEAR_CHANNELS_STORE = '[MANAGE-COMMAND APP] CLEAR CHANNELS STORE'; //akisx
export const CHANNEL_DIALOG_DATA = '[MANAGE-COMMAND APP] CHANNEL DIALOG DATA'; //akisx

export function clearChannelsStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_CHANNELS_STORE
		})

}

export function channelDialogData(state) {
	return {
		type: CHANNEL_DIALOG_DATA,
		payload: state
	}
}

export function newChannel(data, message) {
	const request = axios.post('EpttaAdmin/Channels', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({variant: 'success', message: 'Channel Saved' }));

			return dispatch({
				type: NEW_CHANNEL,
				payload: response.data
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}


export function getChannels(dgnaManagementState) {

	const request = axios.get('EpttaAdmin/Channels/Search?type=C');

	return dispatch =>
		request.then(response => {
			dispatch({
				type: GET_CHANNELS,
				payload: response.data,
				dgnaManagementState: dgnaManagementState
			})
		});
}

export function setChannelsSearchText(event) {
	return {
		type: SET_CHANNELS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getChannel(channelName) {
	const request = axios.get(`EpttaAdmin/Channels/Search?name=${channelName}&type=C`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_CHANNEL,
				payload: response.data[0]
			})
		);
}

export function getChannelItems(channel, message) {
	const request = axios.get(`EpttaAdmin/Channels/Count/${channel._id}`);

	return dispatch =>
		request.then(response => {
			//console.log("get agent items response: "+JSON.stringify(response.data));
			dispatch({
				type: GET_CHANNEL_ITEMS,
				payload: response.data
			});

			dispatch(Actions.setConfirmationDialogStatus(true))
			dispatch(Actions.setConfirmationActionType("channel"))
			dispatch(Actions.setConfirmationDialogContent(`${message.deleteChannel}${message.channelSub}${response.data.channelSubscriptions}, ${message.messages}${response.data.messages} }`))
			dispatch(Actions.setConfirmationSelectedItem(channel))

			//window.confirm(`Are you sure you wish to delete this Channel? Items Info: { Channel Subscriptions: ${response.data.channelSubscriptions}, Messages: ${response.data.messages} }`) &&
			//dispatch(deleteChannel(channel));
		});
}

export function saveChannel(data, message) {
	const request = axios.put('EpttaAdmin/Channels', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Channel Saved' }));
			//console.log("save response: " +JSON.stringify(response));

			data.lastUpdate = new Date()

			return dispatch({
				type: SAVE_CHANNEL,
				payload: data
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}

export function deleteChannel(data, message) {
	//console.log("data to delete: "+JSON.stringify(data._id))
	const request = axios.delete(`EpttaAdmin/Channels?id=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Channel Deleted' }));
			//console.log("delete response: " +JSON.stringify(response));

			return dispatch({
				type: DELETE_CHANNEL,
				payload: data
			});
		})
}

export function newChannelTemplate(tenant) {

	const data = {
		"_id": "new",
		"color": "yellow",
		"created": "",
		"description": "",
		"id": "new",
		"isDynamic": false,
		"isEmergency": true,
		"isEnabled": true,
		"lastMessage": "",
		"lastMessageString": "",
		"lastUpdate": "",
		"name": "New",
		"parentId": "",
		"tenantId": tenant,
		"type": "C"
	};

	return {
		type: GET_CHANNEL,
		payload: data
	};
}
