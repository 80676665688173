import { authRoles } from 'react/app/auth';
import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import gr from './navigation-i18n/gr'; //Akisx

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);
i18next.addResourceBundle('gr', 'navigation', gr); //Akisx

const navigationConfig = [
	{
		id: 'applications',
		title: 'Applications',
		translate: 'APPLICATIONS',
		type: 'group',
		icon: 'apps',
		url: '/apps/dashboards/',
		auth: authRoles.admin,
		children: [
			{
				id: 'dashboard',
				title: 'Dashboards',
				translate: 'DASHBOARDS',
				type: 'item',
				icon: 'dashboard',
				url: '/apps/dashboards/',
				auth: authRoles.admin,
				// children: [
				// 	{
				// 		id: 'analytics-dashboard',
				// 		title: 'Analytics',
				// 		type: 'item',
				// 		icon: 'insert_chart_outlined',
				// 		url: '/apps/dashboards/analytics',
				// 		exact: true
				// 	}
				// ]
			},
			{
				id: 'agents-management',
				title: 'Manage Agents',
				translate: 'MANAGEAGENTS',
				type: 'collapse',
				icon: 'people_alt',
				url: '/apps/agents-management',
				auth: authRoles.admin,
				children: [
					{
						id: 'agents-menu',
						title: 'Agents',
						translate: 'AGENTSMENU',
						type: 'item',
						icon: 'people_alt',
						url: '/apps/agents-management/agents',
						exact: true
					},
					{
						id: 'agents-management-agents-devices',
						title: 'Agent Devices',
						translate: 'AGENTDEVICES',
						type: 'item',
						icon: 'devices_other',
						url: '/apps/agents-management/agentDevices',
						exact: true
					},
					{
						id: 'agents-management-agents-subscriptions',
						title: 'Agent To Agent Subscriptions',
						translate: 'AGENTTOAGENTSUBS',
						type: 'item',
						icon: 'supervisor_account',
						url: '/apps/agents-management/agent2AgentSubs',
						exact: true
					},
					{
						id: 'agents-management-channels-subscriptions',
						title: 'Channels Subscriptions',
						translate: 'CHANNELSUBS',
						type: 'item',
						icon: 'show_chart',
						url: '/apps/agents-management/channelsSubs',
						exact: true
					},
					{
						id: 'agents-management-groups-subscriptions',
						title: 'Groups Subscriptions',
						translate: 'GROUPSUBS',
						type: 'item',
						icon: 'multiline_chart',
						url: '/apps/agents-management/groupsSubs',
						exact: true
					},
				]
			},
			{
				id: 'channels-management',
				title: 'Manage Channels',
				translate: 'MANAGECHANNELS',
				type: 'collapse',
				icon: 'graphic_eq',
				url: '/apps/channels-management',
				auth: authRoles.admin,
				children: [
					{
						id: 'channels-menu',
						title: 'Channels',
						translate: 'CHANNELSMENU',
						type: 'item',
						icon: 'graphic_eq',
						url: '/apps/channels-management/channels',
						exact: true
					},
					{
						id: 'channels-management-channels-subscriptions',
						title: 'Channels Subscriptions',
						translate: 'CHANNELSUBS',
						icon: 'show_chart',
						type: 'item',
						url: '/apps/channels-management/channelsSubs',
						exact: true
					},
					{
						id: 'channels-management-channels-subscriptions-Status',
						title: 'Channels Subscriptions Status',
						translate: 'CHANNELSSTATUS',
						type: 'item',
						icon: 'assessment',
						url: '/apps/channels-management/channelsSubsStatus',
						exact: true
					},
				]
			},
			{
				id: 'groups-management',
				title: 'Manage Groups',
				translate: 'MANAGEGROUPS',
				type: 'collapse',
				icon: 'grain',
				url: '/apps/groups-management',
				auth: authRoles.admin,
				children: [
					{
						id: 'groups-menu',
						title: 'Groups',
						translate: 'GROUPSMENU',
						type: 'item',
						icon: 'grain',
						url: '/apps/groups-management/groups',
						exact: true
					},
					{
						id: 'groups-management-groups-subscriptions',
						title: 'Groups Subscriptions',
						translate: 'GROUPSSUBS',
						type: 'item',
						icon: 'multiline_chart',
						url: '/apps/groups-management/groupsSubs',
						exact: true
					},
					{
						id: 'groups-management-groups-subscriptions-Status',
						title: 'Groups Subscriptions Status',
						translate: 'GROUPSSTATUS',
						type: 'item',
						icon: 'assessment',
						url: '/apps/groups-management/groupsSubsStatus',
						exact: true
					},
				]
			},
			{
				id: 'tenant',
				title: 'Tenant',
				translate: 'TENANT',
				type: 'collapse',
				icon: 'domain',
				url: '/apps/tenant',
				auth: authRoles.admin,
				children: [
					{
						id: 'tenant-settings-menu',
						title: 'Tenant Settings',
						translate: 'TENANTSET',
						type: 'item',
						icon: 'domain',
						url: '/apps/tenant/tenantSettings',
						exact: true
					},
					{
						id: 'tenant-admins-menu',
						title: 'Tenant Admins',
						translate: 'TENANTADMINS',
						type: 'item',
						icon: 'security',
						url: '/apps/tenant/admins',
						exact: true
					},
					{
						id: 'tenant-map-providers-menu',
						title: 'Map Providers',
						translate: 'MAPPROV',
						type: 'item',
						icon: 'map',
						url: '/apps/tenant/mapProviders',
						exact: true
					},
				]
			},
			{
				id: 'clientsDownloading',
				title: 'Download Clients',
				type: 'item',
				auth: authRoles.admin,
				url: '/apps/clientsDownload/ClientDownload',
				icon: 'get_app',
				//exact:true
			},

		]
	},
	{
		id: 'auth',
		title: 'Auth',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'only-admin-super-admin-tenants',
				title: 'Tenants',
				type: 'item',
				auth: authRoles.superAdmin,
				url: '/apps/super-admin/tenants',
				icon: 'security',
			},
			{
				id: 'only-admin-super-admin-superAdmins',
				title: 'Super Admins',
				type: 'item',
				auth: authRoles.superAdmin,
				url: '/apps/super-admin/superAdmins',
				icon: 'verified_user'
			},
			{
				id: 'only-admin-super-admin-license',
				title: 'License',
				type: 'item',
				auth: authRoles.superAdmin,
				url: '/apps/super-admin/license',
				icon: 'how_to_reg'
			},
			{
				id: 'only-admin-super-admin-clientsDownloading',
				title: 'Download Clients',
				type: 'item',
				auth: authRoles.superAdmin,
				url: '/apps/super-admin/clientsDownload',
				icon: 'get_app'
			},
			{
				id: 'only-admin-super-admin-tenant',
				title: 'Tenant',
				translate: 'TENANT',
				type: 'collapse',
				icon: 'domain',
				url: '/apps/super-admin/tenantSettings',
				auth: authRoles.superAdmin,
				children: [
					{
						id: 'only-admin-super-admin-tenant-settings-menu',
						title: 'Tenant Settings',
						translate: 'TENANTSET',
						type: 'item',
						icon: 'domain',
						url: '/apps/super-admin/tenantSettings',
						exact: true
					},
				]
			},
			// {
			// 	id: 'only-admin-super-admin-weather-app',
			// 	title: 'Weather Forecast Map',
			// 	type: 'item',
			// 	auth: authRoles.superAdmin,
			// 	url: '/apps/super-admin/weather-app',
			// 	icon: 'map'
			// },
			{
				id: 'logout',
				title: 'Logout',
				type: 'item',
				url: '/logout',
				icon: 'exit_to_app'
			},
		]
	},
];

export default navigationConfig;
