import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_AGENT2AGENTSUBS = '[MANAGE-COMMAND APP] GET AGENT2AGENTSUBS'; //akisx
export const SET_SELECTED_SOURCE_AGENT = '[MANAGE-COMMAND APP] SET SET_SELECTED_SOURCE_AGENT';
export const CLEAR_SELECTED_SOURCE_AGENT = '[MANAGE-COMMAND APP] CLEAR CLEAR_SELECTED_SOURCE_AGENT';
export const SAVE_AGENT2AGENTSUBS = '[MANAGE-COMMAND APP] SAVE AGENT2AGENTSUBS'; //akisx
export const NEW_AGENTSUB = '[MANAGE-COMMAND APP] NEW AGENTSUB'; //akisx
export const DELETE_AGENTSUB = '[MANAGE-COMMAND APP] DELETE AGENTSUB'; //akisx
export const GET_AGENTSUB = '[MANAGE-COMMAND APP] GET AGENTSUB'; //akisx
export const CLEAR_AGENT2AGENTSUBS_STORE = '[MANAGE-COMMAND APP] CLEAR AGENT2AGENTSUBS STORE'; //akisx
export const SAVE_MULTI_AGENTS2AGENT_SUBS = '[MANAGE-COMMAND APP] SAVE MULTI AGENTS2AGENT SUBS'; //akisx
export const IS_MULTI_AGENT2AGENT = '[MANAGE-COMMAND APP] IS MULTI AGENT2AGENT'; //akisx
export const SET_SEARCH_FOR_TARGET_AGENTS = '[MANAGE-COMMAND APP] SET SEARCH FOR TARGET AGENTS'; //akisx

export function clearAgent2AgentStore() {
	return dispatch =>
		dispatch({
			type: CLEAR_AGENT2AGENTSUBS_STORE
		});
}

export function newAgent2AgentSub(data, message) {
	const request = axios.post('EpttaAdmin/AgentSubscription', data);

	return dispatch =>
		request
			.then(response => {
				//dispatch(showMessage({ variant: 'success', message: 'Subscription Saved' }));
				dispatch(showMessage({ variant: 'success', message: message }));
				return dispatch({
					type: NEW_AGENTSUB,
					payload: response.data
				});
			})
			.catch(e => {
				if (e?.response && e.response?.data) {
					if (e.response.data.error) {
						dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
					} else {
						dispatch(showMessage({ variant: 'error', message: e.response.data }));
					}

					//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
				} else {
					dispatch(showMessage({ variant: 'error', message: 'Unhandle ERROR' }));
				}
			});
}

export function deleteAgent2AgentSub(targetDeleteAgentSub, message) {
	const request = axios.delete(`EpttaAdmin/AgentSubscription?id=${targetDeleteAgentSub._id}`);

	return dispatch =>
		request
			.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				//dispatch(showMessage({ variant: 'success', message: 'Subscription Deleted' }));

				//console.log("delete response: " +JSON.stringify(targetDeleteAgentSub));

				return dispatch({
					type: DELETE_AGENTSUB,
					payload: targetDeleteAgentSub
				});
			})
			.catch(e => {
				if (e?.response && e.response?.data) {
					if (e.response.data.error) {
						dispatch(showMessage({ message: e.response.data.error }));
					} else {
						dispatch(showMessage({ message: e.response.data }));
					}

					//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
				} else {
					dispatch(showMessage({ message: 'Unhandle ERROR' }));
				}
			});
}

export function getAgent2AgentSubs(param, IsTarget, setIsGettingData) {
	console.log('getAgent2AgentSubs action ', param);
	if (setIsGettingData) setIsGettingData(true);
	if (param) {
		let request = '';
		if (IsTarget) {
			request = axios.get(`EpttaAdmin/AgentSubscriptions/GetByAgentIdTarget/${param}`);
		} else {
			request = axios.get(`EpttaAdmin/AgentSubscriptions/GetByAgentId/${param}`);
		}

		return dispatch =>
			request.then(response => {
				if (setIsGettingData) {
					setIsGettingData(false);
				}
				dispatch({
					type: GET_AGENT2AGENTSUBS,
					payload: response.data
				});
			});
	} else {
		if (setIsGettingData) {
			setIsGettingData(false);
		}

		return dispatch => dispatch({ type: GET_AGENT2AGENTSUBS, payload: [] });
	}
}

export function saveAgent2AgentSubs(data, message, setClearForm) {
	const request = axios.put('EpttaAdmin/AgentSubscription', data);

	return dispatch =>
		request
			.then(response => {
				//dispatch(showMessage({ variant: 'success', message: 'Subscriptions Saved' }));
				dispatch(showMessage({ variant: 'success', message: message }));

				setClearForm(data);
				return dispatch({
					type: SAVE_AGENT2AGENTSUBS,
					payload: response.data
				});
			})
			.catch(e => {
				if (e?.response && e.response?.data) {
					if (e.response.data.error) {
						dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
					} else {
						dispatch(showMessage({ variant: 'error', message: e.response.data }));
					}
				} else {
					dispatch(showMessage({ variant: 'error', message: 'Unhandle ERROR' }));
				}
			});
}

export function saveMultiAgent2AgentSubs(targetIdsList, data, message) {
	data._id = 'Multi';

	let multiAgent2AgentSubsObj = {
		agentSubscription: data,
		targetIdsList: targetIdsList
	};
	console.log('saveMultiAgent2AgentSubs ', multiAgent2AgentSubsObj);

	const request = axios.post(
		'EpttaAdmin/AgentSubscription/multiAgentSubscriptionImportation',
		multiAgent2AgentSubsObj
	);

	return dispatch =>
		request
			.then(response => {
				dispatch(showMessage({ variant: 'success', message: message }));
				return dispatch({
					type: SAVE_MULTI_AGENTS2AGENT_SUBS,
					payload: response.data,
					targetIdsList: targetIdsList
				});
			})
			.catch(err => {
				if (err?.response && err.response?.data) {
					if (err.response.data.error) {
						dispatch(showMessage({ variant: 'error', message: err.response.data.error }));
					} else {
						dispatch(showMessage({ variant: 'error', message: err.response.data }));
					}
				}
				// else {
				// 	console.log("SUPER ERRR", err)
				// 	dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
				// }
			});
}

export function isMultiAgent2AgentSubs(state) {
	return {
		type: IS_MULTI_AGENT2AGENT,
		payload: state
	};
}

export function newAgent2AgentSubTemplate(tenant, sourceAgentId) {
	const data = {
		//"_id": isMulti? "multi": "new",
		_id: 'new',
		created: '',
		lastUpdate: '',
		permissions: {
			allowAttachments: true,
			allowDirectPTT: true,
			allowE2EE: false,
			allowHistoryLocation: false,
			allowIndividualCall: false,
			allowIndividualVideoCall: false,
			allowLiveLocation: false,
			allowMute: true,
			allowTextMessages: true,
			allowEditAgentProtection: false,
			allowWorkFlowAssignment: false,
			allowHealthVitalSigns: false
		},
		remotePermissions: {
			lastMessage: '',
			lastMessageTimestamp: '',
			remoteMicControl: false,
			remoteMicNotification: false,
			remotePicControl: false,
			remotePicNotification: false,
			remoteScreenControl: false,
			remoteVideoControl: false,
			remoteVideoNotification: false,
			viewDeviceInfo: false
		},
		sourceAgentId: sourceAgentId,
		state: {},
		targetAgentId: ''
	};

	return {
		type: GET_AGENTSUB,
		payload: data
	};
}

export function setSelectedSourceAgent(agentId) {
	return {
		type: SET_SELECTED_SOURCE_AGENT,
		selectedSourceAgent: agentId
	};
}

export function clearSelectedSourceAgent() {
	let clear = '';
	return {
		type: CLEAR_SELECTED_SOURCE_AGENT,
		selectedSourceAgent: clear
	};
}

export function setSearchForTargetAgents(value) {
	return {
		type: SET_SEARCH_FOR_TARGET_AGENTS,
		searchForTargetAgents: value
	};
}
