import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { epttaCommandDarkfonLogo, epttaCommandLogo } from './EpttaIcons';
function PoweredByLinks() {
	let brand = process.env.REACT_APP_BRAND
	const theme = useTheme()
	return (
		<FuseAnimateGroup
			enter={{
				animation: 'transition.expandIn'
			}}
			className="hidden sm:flex items-center"
		>
			{brand === 'commandDarkFon' &&

				epttaCommandDarkfonLogo(theme.palette.secondary.main, 'w-208')
				// <img
				// 	src="assets/images/logos/commandSDS/logo.svg"
				// 	className="max-w-208 w-208"
				// />
			}

			{brand === 'command' &&
				epttaCommandLogo(theme.palette.secondary.main, 'w-208')
				// <img
				// 	src="assets/images/logos/command/logo.svg"
				// 	className="max-w-208 w-208"
				// />
			}

			{brand === 'vigilance' &&
				<img
					src="assets/images/logos/vigilance/logo.svg"
					className="max-w-68"
				/>
			}

			{brand === 'active' &&
				<img
					src='assets/images/logos/active/active logo_WHITE.svg'
					width={200}
				/>
			}

			{brand === 'aid' &&
				<img
					src='assets/images/logos/aid/aid logo.svg'
					width={150}
				/>
			}
			{brand === 'astratac' &&
				<img
					src='assets/images/logos/astratac/astratac logo2.svg'
					width={200}
				/>
			}
			{brand === 'protecting' &&
				<img
					src='assets/images/logos/protecting/protecting logo_WHITE.svg'
					width={350}
				/>
			}
			{brand === 'safe' &&
				<img
					src='assets/images/logos/safe/safe_in logo.svg'
					width={200}
				/>
			}
			{brand === 'dia_talk' &&
				<img
					src='assets/images/logos/dia_talk/DIA_TALK_2_PNG_HQ.png'
					width={150}
				/>
			}
			{brand === 'selecta' &&
				<img
					src='assets/images/logos/selecta/SelectaPTT Logo-WHITE.svg'
					width={200}
				/>
			}
			{brand === 'nova' &&
				<img
					src='assets/images/logos/nova/Nova-logo-horizontal-white.svg'
					width={200}
				/>
			}
			{brand === 'systelios' &&
				<img
					src='assets/images/logos/systelios/logo_systelios.png'
					width={200}
				/>
			}
		</FuseAnimateGroup>
	);
}

export default PoweredByLinks;
