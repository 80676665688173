import AnalyticsDashboardAppConfig from './dashboards/AnalyticsDashboardAppConfig';
import AgentsAppConfig from './agents-management/AgentsAppConfig';
import ChannelsAppConfig from './channels-management/ChannelsAppConfig';
import GroupsAppConfig from './groups-management/GroupsAppConfig';
import TenantAppConfig from './tenant/TenantAppConfig';
import SuperAdminAppConfig from './super-admin/SuperAdminAppConfig';
import ClientsDownloadAppConfig from '../../fuse-layouts/shared-components/clientsDownload/ClientsDownloadAppConfig'
import WeatherAppConfig from './super-admin/weather-app/WeatherAppConfig';

const appsConfigs = [
	AnalyticsDashboardAppConfig,
	AgentsAppConfig, //AkisX
	ChannelsAppConfig, //AkisX
	GroupsAppConfig, //AkisX
	TenantAppConfig,
	SuperAdminAppConfig,
	ClientsDownloadAppConfig,
	WeatherAppConfig
];

export default appsConfigs;
