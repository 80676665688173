import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import SecureLS from 'secure-ls'
import history from '@history';
import secureStorageKeys from '../../auth/secureStorageKeys'
import Agent from 'agentkeepalive'
import { HttpsAgent } from 'agentkeepalive'

/* eslint-disable camelcase */
var base64 = require('base-64');

const { SHA3 } = require('sha3');
const { detect } = require('detect-browser');
const browser = detect();
// const secureLocalStorageEncodingType = 'rc4'
// const secureLocalStorageEncryptionSecret = 's3cr3tPa$$w0rd@123'
// const serverUrlKey = 'jwt_server_url'
// const jwtSerialN = base64.encode('jwt_serialN');
// const jwtUUID = base64.encode('jwt_uuid');
// const jwtToken = base64.encode('jwt_access_token');



export const secureLocalStorage = new SecureLS({ encodingType: secureStorageKeys.secureLocalStorageEncodingType, isCompression: false, encryptionSecret: secureStorageKeys.secureLocalStorageEncryptionSecret });

const keepaliveHttpAgent = new Agent({
	maxSockets: 2000,
	maxFreeSockets: 500,
	timeout: 90000, // active socket keepalive for 60 seconds
	freeSocketTimeout: 60000, // free socket keepalive for 30 seconds
});


const keepaliveHttpsAgent = new HttpsAgent({
	maxSockets: 2000,
	maxFreeSockets: 500,
	timeout: 90000, // active socket keepalive for 60 seconds
	freeSocketTimeout: 60000, // free socket keepalive for 30 seconds
});

const loginTimout = 15000;

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}




	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response === undefined) {
						console.log("Throw error: ", err)
						this.emit('onNoAccessToken');
						this.setSession(null);
					}
					else if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						//this.emit('onAutoLogout', 'Invalid access_token');
						this.emit('Invalid access_token');
						this.setSession(null);

						//this.logout();
					}

					//console.log("Throw error: "+JSON.stringify(err))
					throw err;

				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			//this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			//this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then(response => {
				if (response.data.user) {
					this.setSession(response.data.access_token);
					resolve(response.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	axiosInit() {
		//let protocol = "https://"
		let apiPath = '/command-api'
		//axios.defaults.baseURL = `${protocol}${process.env.REACT_APP_COMMAND_API_URI}`;
		axios.defaults.baseURL = `${this.getServerURL()}${apiPath}`;
		axios.defaults.headers.common.Authorization = process.env.REACT_APP_API_VALUE;
		axios.defaults.headers.common[process.env.REACT_APP_API_KEY] = process.env.REACT_APP_API_VALUE;
		axios.defaults.headers.common[process.env.REACT_APP_JWT_KEY] = this.getAccessToken();
		axios.defaults.headers.common['Cache-Control'] = 'no-cache'
		axios.defaults.headers.common.Pragma = 'no-cache'
		axios.defaults.headers.common.Expires = '0'
		axios.defaults.httpAgent = keepaliveHttpAgent
		axios.defaults.httpsAgent = keepaliveHttpsAgent
		axios.defaults.maxRedirects = 500
		axios.defaults.timeout = 60000

	}

	customeUiObjectF(tenant, username, role) {
		let thisRole = 'admin';
		if (role) {
			role.forEach(element => {
				if (element === "tenantadmin") {
					thisRole = "superAdmin"
				}
			});
		}
		let dcustomeUiObject = {
			role: thisRole,
			data: {
				tenant: tenant,
				displayName: username,
				photoURL: 'assets/images/avatars/avatar.png',
				email: 'admin@admins.com',
				settings: {
					layout: {
						style: 'layout1',
						config: {
							scroll: 'content',
							navbar: {
								display: true,
								folded: true,
								position: 'left'
							},
							toolbar: {
								display: true,
								style: 'fixed',
								position: 'below'
							},
							footer: {
								display: true,
								style: 'fixed',
								position: 'below'
							},
							mode: 'fullwidth'
						}
					},
					customScrollbars: true,
					theme: {
						main: 'defaultDark',
						navbar: 'defaultDark',
						toolbar: 'defaultDark',
						footer: 'defaultDark'
					}
				},
				shortcuts: []
			}
		}

		return dcustomeUiObject
	}

	loginData(username, password, tenant, role) {
		let hashedPass = new SHA3(512);
		let stringToHash = tenant + password + username;
		//console.log("Final String to hash: "+stringToHash )
		hashedPass.update(stringToHash);
		let fixedHashedPass = hashedPass.digest('hex')
		//console.log("Hashed: "+JSON.stringify(fixedHashedPass))

		if (browser) {
			//console.log(browser.name);
			//console.log(browser.version);
			//console.log(browser.os);

			let info = browser
			//console.log("get infosss: "+JSON.stringify(info))
		}

		let uuid = this.getUUID();
		let serial = this.getSerial();

		let data = {
			IMEI: uuid,
			brandName: browser.name,
			firmware: browser.type,
			imei: uuid,
			modelVersion: browser.version,
			password: fixedHashedPass,
			platform: browser.os,
			serialNumber: serial,
			username: username + "@" + tenant
		}

		let defaultUiObject = this.customeUiObjectF(tenant, username, role)

		if (!uuid && !serial) {
			uuid = FuseUtils.generateGUID();
			serial = FuseUtils.generateGUID();
			data.IMEI = uuid;
			data.imei = uuid;
			data.serialNumber = serial;
		}

		//console.log("GATED GUID:"+uuid)

		return { data, defaultUiObject }
	}

	signInWithCredentials = (username, password, tenant) => {
		let data = this.loginData(username, password, tenant).data;

		this.axiosInit();

		return new Promise((resolve, reject) => {
			axios/////////////////////////////////////////////////////////////////////////////
				.post('EpttaAuth/EpttaLogin', data, { timeout: loginTimout })
				.then(response => {
					console.log("EpttaAuth/EpttaLogin response: ", response.data)
					if (response.data.token) {
						if (response.data.user.object) {
							let tenant = response.data.user.username.split('@')[1]
							response.data.user.object.data.tenant = tenant
							let myData = {
								user: response.data,
								uIUser: response.data.user.object
							}

							secureLocalStorage.set(secureStorageKeys.jwtUUID, data.imei)
							secureLocalStorage.set(secureStorageKeys.jwtSerialN, data.serialNumber)
							//localStorage.setItem('jwt_uuid', data.imei);
							//localStorage.setItem('jwt_serialN', data.serialNumber);
							this.setSession(response.data.token);
							resolve(myData);
						} else {
							let role = response.data.user.role

							let customeUiObject = this.loginData(username, password, tenant, role).defaultUiObject;
							let myData = {
								user: response.data,
								uIUser: customeUiObject
							}


							secureLocalStorage.set(secureStorageKeys.jwtUUID, data.imei)
							secureLocalStorage.set(secureStorageKeys.jwtSerialN, data.serialNumber)
							//localStorage.setItem('jwt_uuid', data.imei);
							//localStorage.setItem('jwt_serialN', data.serialNumber);
							this.setSession(response.data.token);
							resolve(myData);
						}


					} else {
						reject(response.data.error);//checkkkk
					}
				}).catch(e => {
					if (!e) {
						const error = {
							username: '',
							password: '',
							tenant: '',
							err: "Check Your Credentials"
						};
						reject(error);
						//Promise.reject(new Error('Failed to login with token.'));
					}
					else if (e.message === "Network Error") {
						const error = {
							username: '',
							password: '',
							tenant: '',
							err: "Local network problem or server is down"
						};
						//Promise.reject(new Error('Failed to login with token.'));
						reject(error);
					}
					else if (e.response?.status === 403) {
						const error = {
							username: '',
							password: '',
							tenant: '',
							err: "Check Your Credentials"
						};
						reject(error);
						//Promise.reject(new Error('Failed to login with token.'));
						//console.log("Fail Login Error: "+JSON.stringify(e.response.data))//akisx
					}
					else if (e.response?.status === 500) {
						const error = {
							username: '',
							password: '',
							tenant: '',
							err: "Check Your Credentials"
						};
						reject(error);
						//Promise.reject(new Error('Failed to login with token.'));
						//console.log("Fail Login Error: "+JSON.stringify(e.response.data))//akisx
					}
					else {
						let error

						if (e?.message.includes('timeout')) {
							error = {
								username: '',
								password: '',
								tenant: '',
								err: "Request Timeout"
							};
						} else {
							error = {
								username: '',
								password: '',
								tenant: '',
								err: "Local network problem or server is down" //or unhandledddddd
							};
						}


						//Promise.reject(new Error('Failed to login with token.'));
						reject(error);
					}
				});
		});
	};


	signInWithToken = () => {
		let data = this.loginData("", "", "", "").data;
		this.axiosInit();

		return new Promise((resolve, reject) => {
			axios
				.post('EpttaAuth/EpttaTokenLogin', data, { timeout: loginTimout })
				.then(response => {
					if (response.data.token) {
						this.setSession(response.data.token);
						if (response.data.user.object) {
							let tenant = response.data.user.username.split('@')[1]
							response.data.user.object.data.tenant = tenant
							let myData = {
								user: response.data,
								uIUser: response.data.user.object
							}
							//console.log("PASHASH: EpttaTokenLogin",myData.uIUser)
							resolve(myData);
						} else {
							let role = response.data.user.role
							let tenant = response.data.user.username.split('@')[1];
							let username = response.data.user.username.split('@')[0];
							let customeUiObject = this.loginData(username, "pass", tenant, role).defaultUiObject;
							let myData = {
								user: response.data,
								uIUser: customeUiObject
							}
							//console.log("PASHASH: EpttaTokenLogin2",myData.uIUser)
							resolve(myData);
						}

					} else {
						console.log("Login reject")
						reject(response.data.error);
						//this.logout();
					}
				})
				.catch(e => {
					//console.log("Response Data ERROR: "+JSON.stringify(e))
					/**
					const error = {
						username: '',
						password: '',
						tenant: '',
						err: "Local network problem or server is down" //or unhandledddddd
					}; */
					//Promise.reject(new Error('Failed to login with token.'));
					let timeout = e?.message.includes('timeout');
					let string = ('Failed to login with token. ' + timeout ? "Timeout" : "")
					reject(string);
				})
		});
	};

	updateUserData = user => {
		//console.log("Update user data called: "+JSON.stringify(user))
		//console.log("Item to update: ")
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	//akisx Test
	setSession = access_token => {
		//console.log('Access Token in set session: ' +access_token);
		if (access_token) {
			//localStorage.setItem('jwt_access_token', access_token);
			secureLocalStorage.set(secureStorageKeys.jwtToken, access_token)
			let authKey = process.env.REACT_APP_JWT_KEY
			axios.defaults.headers.common.Authorization = `${access_token}`;
			axios.defaults.headers.common[authKey] = `${access_token}`;
		} else {
			//localStorage.removeItem('jwt_uuid');
			//localStorage.removeItem('jwt_serialN');
			//localStorage.removeItem('jwt_access_token');

			secureLocalStorage.remove(secureStorageKeys.jwtToken)
			//delete axios.defaults.headers.common.Authorization; 
		}
	};

	logout = () => {
		console.log("Logout Called!")
		let data = this.loginData("", "", "").data;
		this.setSession(null);
		history.push('/login');
		axios
			.post('EpttaAuth/EpttaLogout', data)
			.then(response => {
				if (response) {
					this.setSession(null);
					//history.push('/logout');
				}
			})
			.catch(e => {
				//console.log("Response Data LOGOUT ERROR: "+JSON.stringify(e))
			})
	};

	isAuthTokenValid = access_token => {

		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			//console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {

		//console.log('getAccessToken From windows: '+ window.localStorage.getItem('jwt_access_token'));
		//return window.localStorage.getItem('jwt_access_token');
		return secureLocalStorage.get(secureStorageKeys.jwtToken)
		//let jwt_access = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoiQWdlbnQxMEBleWVvbml4IiwiaWF0IjoxNTg0Nzk0MDkyLCJleHAiOjE1ODU2OTQwOTJ9._3lziAZHUoQe78MSfS7nomJyfjhm8UmOSf74jKM_1LpW08NJgJCCD9QrjUvb5mTAYTrmVLeYEkVVXVO5hpcRRQ'
		//return	jwt_access;
	};

	getUUID = () => {
		//console.log('getAccessToken From windows: '+ window.localStorage.getItem('jwt_access_token'));
		//return window.localStorage.getItem('jwt_uuid');
		return secureLocalStorage.get(secureStorageKeys.jwtUUID);
		//let jwt_access = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJzb2Z0dGVrSldUIiwic3ViIjoiQWdlbnQxMEBleWVvbml4IiwiaWF0IjoxNTg0Nzk0MDkyLCJleHAiOjE1ODU2OTQwOTJ9._3lziAZHUoQe78MSfS7nomJyfjhm8UmOSf74jKM_1LpW08NJgJCCD9QrjUvb5mTAYTrmVLeYEkVVXVO5hpcRRQ'
		//return	jwt_access;
	};

	getSerial = () => {
		//console.log('getAccessToken From windows: '+ window.localStorage.getItem('jwt_access_token'));
		return secureLocalStorage.get(secureStorageKeys.jwtSerialN);
		//return window.localStorage.getItem('jwt_serialN');
	};

	// getTenant = () => {
	// 	//console.log('getAccessToken From windows: '+ window.localStorage.getItem('jwt_access_token'));
	// 	return window.localStorage.getItem('jwt_tenant');
	// };

	getServerURL = () => {
		return secureLocalStorage.get(secureStorageKeys.serverUrlKey);
	}
}

const instance = new JwtService();

export default instance;
