
import { authRoles } from 'react/app/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import ar from '../i18n/ar';
import en from '../i18n/en';
import tr from '../i18n/tr';
import gr from '../i18n/gr'; //Akisx

i18next.addResourceBundle('en', 'manageAgents', en);
i18next.addResourceBundle('tr', 'manageAgents', tr);
i18next.addResourceBundle('ar', 'manageAgents', ar);
i18next.addResourceBundle('gr', 'manageAgents', gr); //Akisx

const AgentsAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			//path: '/apps/agents-management/agents/:agentId/:agentName/:page?',			
			path: '/apps/agents-management/agents/:agentId/:agentName?',
			component: React.lazy(() => import('./agents/Agent'))
		},
		{
			path: '/apps/agents-management/agents',
			component: React.lazy(() => import('./agents/Agents'))
		},
		{
			path: '/apps/agents-management/agent2AgentSubs/:agentSubId/:agentSubName?',
			component: React.lazy(() => import('./agent2AgentSubs/AgentSub'))
		},
		{
			path: '/apps/agents-management/agent2AgentSubs',
			component: React.lazy(() => import('./agent2AgentSubs/Agent2AgentSubs'))
		},
		// {
		// 	path: '/apps/agents-management/channelsSubs/:channelSubId/:channelSubName?',
		// 	component: React.lazy(() => import('./channelsSubs/ChannelSub'))
		// },
		// {
		// 	path: '/apps/agents-management/channelsSubs',
		// 	component: React.lazy(() => import('./channelsSubs/ChannelsSubs'))
		// },
		{
			path: '/apps/agents-management/channelsSubs/:channelSubId/:channelSubName?',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/channelsSubs/ChannelSub'))
		},
		{
			path: '/apps/agents-management/channelsSubs',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/channelsSubs/ChannelsSubs'))
		},
		// {
		// 	path: '/apps/agents-management/groupsSubs/:groupSubId/:groupSubName?',
		// 	component: React.lazy(() => import('./groupsSubs/GroupSub'))
		// },
		// {
		// 	path: '/apps/agents-management/groupsSubs',
		// 	component: React.lazy(() => import('./groupsSubs/GroupsSubs'))
		// },
		{
			path: '/apps/agents-management/groupsSubs/:groupSubId/:groupSubName?',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/groupsSubs/GroupSub'))
		},
		{
			path: '/apps/agents-management/groupsSubs',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/groupsSubs/GroupsSubs'))
		},
		// {
		// 	path: '/apps/agents-management/agentDevices/:agentDeviceId/:agentDeviceName?',
		// 	component: React.lazy(() => import('./agentDevices/AgentDevice'))
		// },
		{
			path: '/apps/agents-management/agentDevices/:agentDeviceId/:agentDeviceName?',
			component: React.lazy(() => import('./agentDevices/AgentDevices'))
		},
		{
			path: '/apps/agents-management/agentDevices',
			component: React.lazy(() => import('./agentDevices/AgentDevices'))
		},
		{
			path: '/apps/agents-management',
			component: () => <Redirect to="/apps/agents-management/agents" />
		}
	]
};

export default AgentsAppConfig;
