import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import * as userActions from 'react/app/auth/store/actions';
//import auth0Service from 'react/app/services/auth0Service';
//import firebaseService from 'react/app/services/firebaseService';
import jwtService from 'react/app/services/jwtService';
import * as Actions from 'react/app/store/actions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import user from './store/reducers/user.reducer';

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		return Promise.all([
			// Comment the lines which you do not use
			//this.firebaseCheck(),
			//this.locaauth0Check(),
			this.jwtCheck()
		]).then(() => {
			//this.props.logout();
			this.setState({ waitAuthCheck: false });
		});
	}

	jwtCheck = () =>
		new Promise(resolve => {
			jwtService.on('onAutoLogin', () => {
				this.props.showMessage({ message: 'Logging in with JWT' });

				/**
				 * Sign in and retrieve user data from Api
				 */
				jwtService
					.signInWithToken()
					.then(user => {
						// console.log("UserTestUI to dispatch: "+JSON.stringify(user.uIUser));
						// console.log("UsertestUSER to dispatch: "+ JSON.stringify(user.user));
						this.props.setUserData(user.uIUser);
						this.props.setSRVUserData(user.user);

						resolve();
						this.props.showMessage({ variant: 'info', message: 'Logged in with JWT' });
					})
					.catch(error => {
						this.props.showMessage({ variant: 'error', message: error });
						resolve();
					});
			});

			jwtService.on('onAutoLogout', message => {
				if (message) {
					this.props.showMessage({ message });
				}

				this.props.logout();

				resolve();
			});

			jwtService.on('onNoAccessToken', () => {
				resolve();
			});

			jwtService.init();

			return Promise.resolve();
		});

	/*
	auth0Check = () =>
		new Promise(resolve => {
			auth0Service.init(success => {
				if (!success) {
					resolve();
				}
			});

			if (auth0Service.isAuthenticated()) {
				this.props.showMessage({ message: 'Logging in with Auth0' });

				/**
				 * Retrieve user data from Auth0
				 *
				auth0Service.getUserData().then(tokenData => {
					this.props.setUserDataAuth0(tokenData);

					resolve();

					this.props.showMessage({ message: 'Logged in with Auth0' });
				});
			} else {
				resolve();
			}

			return Promise.resolve();
		});
		
	firebaseCheck = () =>
		new Promise(resolve => {
			firebaseService.init(success => {
				if (!success) {
					resolve();
				}
			});

			firebaseService.onAuthStateChanged(authUser => {
				if (authUser) {
					this.props.showMessage({ message: 'Logging in with Firebase' });

					/**
					 * Retrieve user data from Firebase
					*
					firebaseService.getUserData(authUser.uid).then(
						user => {
							this.props.setUserDataFirebase(user, authUser);

							resolve();

							this.props.showMessage({ message: 'Logged in with Firebase' });
						},
						error => {
							resolve();
						}
					);
				} else {
					resolve();
				}
			});

			return Promise.resolve();
		});
	*/
	render() {
		//console.log("this state waitAuth: "+JSON.stringify(this.state.waitAuthCheck))
		return this.state.waitAuthCheck ? <></> : <>{this.props.children}</>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: userActions.logoutUser,
			setUserData: userActions.setUserData,
			setSRVUserData: userActions.setSRVUserData,
			//setUserDataAuth0: userActions.setUserDataAuth0,
			//setUserDataFirebase: userActions.setUserDataFirebase,
			showMessage: Actions.showMessage,
			hideMessage: Actions.hideMessage
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
