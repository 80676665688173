import { useTimeout } from '@fuse/hooks';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';

function FuseLoading(props) {
	const [showLoading, setShowLoading] = useState(!props.delay);
	const theme = useTheme();

	// useEffect(() => {
	// 	var expirationDate = new Date();
	// 	expirationDate.setDate(expirationDate.getDate() + 30);


	// 	if (!document.cookie.includes('bgColor')) {
	// 		document.cookie = `bgColor=${theme.palette.background.default}; expires=${expirationDate} path=/`;

	// 	}
	// 	if (!document.cookie.includes('secondaryMain')) {
	// 		document.cookie = `secondaryMain=${theme.palette.secondary.main}; expires=${expirationDate} path=/`;
	// 	}

	// }, [theme.palette.background.default, theme.palette.secondary.main])

	useTimeout(() => {
		setShowLoading(true);
	}, props.delay);


	if (!showLoading) {
		return null;
	}

	return (
		<div className="flex flex-1 flex-col items-center justify-center">
			<Typography className="text-20 mb-16" color="secondary">
				Loading...
			</Typography>
			<LinearProgress className="w-xs" color="secondary" />
		</div>
	);
}

FuseLoading.propTypes = {
	delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

FuseLoading.defaultProps = {
	delay: false
};

export default FuseLoading;
