import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	tenantSettingsData: [],//akisx
	searchText: '',
	tenantSettingData: null,
	tenantItemsData: null,
	delTenantSetting: null,
	tenantSettingsDialogdData:{
		id:"new",
		state:false
	},
	// tenantSettingsDialogStatusState: false,
	// tenantSettingsDialogStatusId: "new"
};

const tenantSettingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_TENANTSETTINGS: {
			if(action.payload.length > 0) {
				const sortetData = _.sortBy(action.payload,"key")
				return {
					...state,
					tenantSettingsData: sortetData
				};
			}else {

				return {
					...state,
					tenantSettingsData: []
				};
			}
		}
		case Actions.SET_TENANTSETTINGS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_TENANTSETTING: {
			let data = state.tenantSettingsData.concat(action.payload)
			return {
				...state,
				tenantSettingsData: data
			};
		}
		case Actions.GET_TENANTSETTING: {
			return {
				...state,
				tenantSettingData: action.payload
			};
		}
		case Actions.GET_TENANTITEMS: {
			return {
				...state,
				tenantItemsData: action.payload
			};
		}
		case Actions.SAVE_TENANTSETTING: {
			let data = state.tenantSettingsData.filter((data) => data._id !== action.payload._id)
			let data2 = data.concat(action.payload)
			return {
				...state,
				tenantSettingData: action.payload,
				tenantSettingsData: data2
			};
		}
		case Actions.TENANT_SETTINGS_DIALOG_DATA: {
			// return{
			// 	...state,
			// 	tenantSettingsDialogStatusState: action.payload.state,
			// 	tenantSettingsDialogStatusId: action.payload.id
			// }
			return{
				...state,
				tenantSettingsDialogdData:{
					state:action.payload.state,
					id:action.payload.id
				}
			}
		}
		case Actions.DELETE_TENANTSETTING: {
			return {
				...state,
				delTenantSetting: action,
				tenantSettingData: null,
				tenantSettingsData: state.tenantSettingsData.filter((data) => data._id !== action.payload._id)
			};
		}
		case Actions.CLEAR_TENANT_SETTINGS_STORE: {
			return {
				...initialState
			}
		}
		default: {
			return state;
		}
	}
};

export default tenantSettingsReducer;
