import * as Actions from '../actions';

const initialState = {
	selectedSourceAgent: "",
	selectedTargetGroup: "",
	groupsSubsData: null,//akisx
	deleteGroupSub: null,
	groupSubData: null,
	isMultiGroupSubs: false
};

const groupsSubsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.IS_MULTI_GROUP_SUBS: {
			return {
				...state,
				isMultiGroupSubs: action.payload
			}
		}
		case Actions.GET_GROUPSSUBS: {
			return {
				...state,
				groupsSubsData: action.payload,
				//filterGroupsSubsData: action.payload
			};			
		}
		case Actions.SET_SELECTED_GSOURCE_AGENT: {
			return {
				...state,
				selectedSourceAgent: action.selectedSourceAgent,
			};
			
		}
		case Actions.SET_SELECTED_GTARGET_GROUP: {
			return {
				...state,
				selectedTargetGroup: action.selectedTargetGroup,
			};
			
		}
		case Actions.SAVE_GROUPSSUBS: {
			let savedGroupSubs = action.payload
			var newSubsFilter = state.groupsSubsData
		
			for (let i = 0; i <savedGroupSubs.length; i++) {
				for (let j = 0; j < newSubsFilter.length; j++) {
					if (newSubsFilter[j]._id === savedGroupSubs[i]._id) {
						newSubsFilter[j] = savedGroupSubs[i];
						break;
					}
				}
			}
			return {
				...state,
				groupsSubsData: newSubsFilter,				
			};
		}
		case Actions.DELETE_GROUPSUB: {
			let filter1 = state.groupsSubsData?.filter(({_id}) => _id !== action.payload._id)
			return {
				...state,
				groupsSubsData: filter1			
			};
			
		}
		case Actions.NEW_GROUPSUB: {
			console.log('NEW_GROUPSUB ', action.payload)
			let concat1 = state.groupsSubsData.concat(action.payload)
			return {
				...state,
				groupsSubsData: concat1,
			};
		}
		case Actions.SAVE_MULTI_GROUP_SUBS: {
			console.log('SAVE_MULTI_GROUP_SUBS ', action.payload)
			let newSubs = []

			let subObject = action.payload.channelSubscription
			let channelIdsList = action.channelIdsList
			let subIdsList = action.payload.channelIdsList

			subIdsList.map((id,i)=>{
				let sub = Object.assign({},subObject)
				sub.channelId = channelIdsList[i]
				sub._id = id
				newSubs.push(sub)
			})

			return{
				...state,
				groupsSubsData: state.groupsSubsData.concat(newSubs)
			}
		}
		case Actions.GET_GROUPSUB: {
			return {
				...state,
				groupSubData: action.payload
			};
		}
		case Actions.CLEAR_GROUP_SUBS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default groupsSubsReducer;

