import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';
import * as Actions from '../actions'

export const GET_GROUPS = '[MANAGE-COMMAND APP] GET GROUPS'; //akisx
export const SET_GROUPS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET GROUP SEARCH TEXT';
export const NEW_GROUP = '[MANAGE-COMMAND APP] NEW GROUP'; //akisx

export const GET_GROUP = '[MANAGE-COMMAND APP] GET GROUP';//akisx
export const GET_GROUP_ITEMS = '[MANAGE-COMMAND APP] GET GROUP ITEMS';//akisx
export const SAVE_GROUP = '[MANAGE-COMMAND APP] SAVE GROUP'; //akisx
export const DELETE_GROUP = '[MANAGE-COMMAND APP] DELETE GROUP'; //akisx
export const CLEAR_GROUPS_STORE = '[MANAGE-COMMAND APP] CLEAR GROUPS STORE'; //akisx
export const GROUPS_DIALOG_DATA = '[MANAGE-COMMAND APP] GROUPS DIALOG DATA'; //akisx


export function clearGroupsStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_GROUPS_STORE
		})
		
}

export function groupsDialogData(state){
	return{
		type: GROUPS_DIALOG_DATA,
		payload: state
	}
}

export function newGroup(data, message) {
	const request = axios.post('EpttaAdmin/Channels', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Group Saved' }));

			return dispatch({
				type: NEW_GROUP,
				payload: response.data
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}


export function getGroups() {

	const request = axios.get('EpttaAdmin/Channels/Search?type=G');

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_GROUPS,
				payload: response.data
			})
		);
}

export function setGroupsSearchText(event) {
	return {
		type: SET_GROUPS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getGroup(groupName) {
	//console.log("reeeeeeeee2 ", groupName)

	const request = axios.get(`EpttaAdmin/Channels/Search?name=${groupName}&type=G`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_GROUP,
				payload: response.data[0]
			})
		);
}

export function getGroupItems(group, message) {
	const request = axios.get(`EpttaAdmin/Channels/Count/${group._id}`);

	return dispatch =>
		request.then(response => {
			//console.log("get agent items response: "+JSON.stringify(response.data));
			dispatch({
				type: GET_GROUP_ITEMS,
				payload: response.data
			});
			dispatch(Actions.setConfirmationDialogStatus(true))
			dispatch(Actions.setConfirmationActionType("group"))
			dispatch(Actions.setConfirmationDialogContent(`${message.deleteGroup}${message.groupSub}${response.data.channelSubscriptions}, ${message.messages}${response.data.messages} }`))
			dispatch(Actions.setConfirmationSelectedItem(group))


			// window.confirm(`Are you sure you wish to delete this Group? Items Info: { Group Members: ${response.data.channelSubscriptions}, Messages: ${response.data.messages} }`) &&
			// dispatch(deleteGroup(group));
		});
}

export function saveGroup(data, message) {
	const request = axios.put('EpttaAdmin/Channels', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Group Saved' }));

			console.log("save response: " ,response);
			data.lastUpdate = new Date()

			return dispatch({
				type: SAVE_GROUP,
				payload: data
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}

export function deleteGroup(data, message) {
	const request = axios.delete(`EpttaAdmin/Channels?id=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Group Deleted' }));
			//console.log("delete response: " +JSON.stringify(response));

			return dispatch({
				type: DELETE_GROUP,
				payload: data
			});
		})
}

export function newGroupTemplate(tenant) {

	const data = {
		"_id": "new",
		"color": "blue",
		"created": "",
		"description": "",
		"id": "new",
		"isDynamic": false,
		"isEmergency": true,
		"isEnabled": true,
		"lastMessage": "",
		"lastMessageString": "",
		"lastUpdate": "",
		"name": "New",
		"parentId": "",
		"tenantId": tenant,
		"type": "G"
	};

	return {
		type: GET_GROUP,
		payload: data
	};
}