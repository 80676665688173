import * as Actions from '../actions';
import { clearFocusOnMap } from '../actions';

const initialState = {
	geoFencesData: [],//akisx
	poisData: [],
	wayPointsData: [],
	mapDialog: false,
	selectedChannelFilter: "",
	showControls: false, //init false
	reRenderInt: true,
	maxClusterRadius: 100,
	trackingHistoryData: [],
	trackingHistoryActionsDialogStatus: false,
	showTrackPlayer: false,
	trackingItemID: null,
	dynamicMapGroupDialogStatus: false,
	dynamicEvent: "",
	selectedMapFocusedData: {
		latitude: "",
		longitude: "",
		zoom: ""
	},
	mapFiltersStatus: {
		geoState: true,
		agentsState: true,
		poisState: true,
		wpoisState: true,
		offlineAgents: true,
		nonTransmiting: true,
	},
	epttaMap: null
};

const map = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_TRACKING_HISTORY_DATA: {
			let payloadItem = action.payload

			var trackingCoords = []
			var markersCoords = []

			payloadItem.map(n => {
				//trackingCoords.concat([n.latitude,n.longitude])

				let markersCoordsObject = {
					latitude: n.latitude,
					longitude: n.longitude,
					altitude: n.altitude,
					speed: n.speed,
					timestamp: n.timestamp,
					heading: n.heading,
				}

				markersCoords = markersCoords.concat(markersCoordsObject)
				trackingCoords.push([markersCoordsObject.latitude, markersCoordsObject.longitude])
			})


			let trackingItem = {
				//_id: "f075fab8-bf60-449d-a74e-f1b467f2930f",
				sourceId: payloadItem[0].sourceId,
				sourceType: payloadItem[0].sourceType,
				trackingCoords: trackingCoords,
				markersCoords: markersCoords

			}

			return {
				...state,
				trackingHistoryData: state.trackingHistoryData.concat(trackingItem)
			};
		}
		case Actions.CLEAR_TRACKING_HISTORY_ITEM_DATA: {
			var hasToClearAllData = false;
			if (action.trackingItemId === null) {
				hasToClearAllData = true;
			}

			if (hasToClearAllData) {
				return {
					...state,
					trackingHistoryData: []
				}
			} else {
				let filteredTrackingHistoryData = state.trackingHistoryData.filter(trackingItem => trackingItem.sourceId !== action.trackingItemId)
				return {
					...state,
					trackingHistoryData: filteredTrackingHistoryData
				}
			}
		}
		case Actions.GET_GEO_DATA: {
			var geoFences = [];
			var pois = [];
			var wayPoints = [];

			for (const [index, value] of action.payload.entries()) {
				if (value.features.features[0].geometry.type === "Polygon") {
					geoFences.push(value)
				} else if (value.features.features[0].geometry.type === "Point") {
					pois.push(value)
				} else {
					wayPoints.push(value)
				}
			}
			return {
				...state,
				geoFencesData: geoFences,
				poisData: pois,
				wayPointsData: wayPoints
			};
		}
		case Actions.SET_FILTERS_DATA: {
			return {
				...state,
				mapFiltersStatus: action.filtersData
			}
		}
		case Actions.SET_SELECTED_CHANNEL_FILTER: {
			return {
				...state,
				selectedChannelFilter: action.selectedChannelId
			}
		}
		case Actions.SET_SELECTED_FOCUS_ON_MAP: {
			return {
				...state,
				selectedMapFocusedData: {
					latitude: action.mapFocusedData.latitude,
					longitude: action.mapFocusedData.longitude,
					zoom: action.mapFocusedData.zoom,
				}
			}
		}
		case Actions.CLEAR_SELECTED_FOCUS_ON_MAP: {
			return {
				...state,
				selectedMapFocusedData: {
					latitude: "",
					longitude: "",
					zoom: "",
					pathControl: true
				}
			}
		}
		case Actions.SET_MAP_GEO_DIALOG_STATUS: {
			return {
				...state,
				mapDialog: action.mapGeoDialogStatus
			}
		}
		case Actions.SET_MAX_CLUSTER_RADIUS: {
			return {
				...state,
				maxClusterRadius: action.radius
			}
		}
		case Actions.SET_SHOW_CONTROLS: {

			return {
				...state,
				showControls: action.show
			}
		}
		case Actions.ADD_NEW_GEO_ITEM: {
			let payload = action.payload

			let newGeoItem = {
				_id: payload._id,
				tenantId: payload.tenantId,
				name: payload.name,
				description: payload.description,
				features: payload.features,
				senderId: payload.senderId,
			}

			let type = newGeoItem.features.features[0].geometry.type
			if (type === "Polygon") {
				let newGeoData = state.geoFencesData.concat(action.payload)
				return {
					...state,
					geoFencesData: newGeoData
				}
			} else if (type === "LineString") {
				let newWayPoisPoisData = state.wayPointsData.concat(action.payload)
				return {
					...state,
					wayPointsData: newWayPoisPoisData
				}
			} else if (type === "Point") {
				let newPoisData = state.poisData.concat(action.payload)
				return {
					...state,
					poisData: newPoisData
				}
			}
		}
		case Actions.SET_RERENDER: {
			return {
				...state,
				reRenderInt: action.reRender
			}
		}
		case Actions.SET_TRACKING_HISTORY_ACTIONS_DIALOG_STATUS: {
			return {
				...state,
				trackingHistoryActionsDialogStatus: action.trackingHistoryStatus
			}
		}
		case Actions.DELETE_GEO_ITEM_DATA: {
			let type = action.payload.type
			if (type === "Polygon") {
				let geoData = state.geoFencesData.filter((data) => data._id !== action.payload._id)
				return {
					...state,
					geoFencesData: geoData
				}
			} else if (type === "LineString") {
				let wayPoisData = state.wayPointsData.filter((data) => data._id !== action.payload._id)
				return {
					...state,
					wayPointsData: wayPoisData
				}
			} else if (type === "Point") {
				let poisData = state.poisData.filter((data) => data._id !== action.payload._id)
				return {
					...state,
					poisData: poisData
				}
			}
		}
		case Actions.UPDATE_GEO_ITEM_DATA: {


			let type = action.payload.features.features[0].geometry.type
			if (type === "Polygon") {
				let geoData = state.geoFencesData.filter((data) => data._id !== action.payload._id)
				let newGeoData = geoData.concat(action.payload)
				return {
					...state,
					geoFencesData: newGeoData,
					reRenderInt: false
				}
			} else if (type === "LineString") {
				let wayPoisData = state.wayPointsData.filter((data) => data._id !== action.payload._id)
				let newWayPoisPoisData = wayPoisData.concat(action.payload)
				return {
					...state,
					wayPointsData: newWayPoisPoisData,
					reRenderInt: false
				}
			} else if (type === "Point") {
				let poisData = state.poisData.filter((data) => data._id !== action.payload._id)
				let newPoisData = poisData.concat(action.payload)
				return {
					...state,
					poisData: newPoisData,
					reRenderInt: false
				}
			}
		}
		case Actions.SET_SHOW_TRACK_PLAYER: {
			return {
				...state,
				showTrackPlayer: action.trackPlayer,
				trackingItemID: action.trackingItemID
			}
		}
		case Actions.SET_DYNAMIC_MAP_GROUP_ACTIONS_DIALOG_STATUS: {
			return {
				...state,
				dynamicMapGroupDialogStatus: action.dynamicMapGroupDialogStatus
			}
		}
		case Actions.SET_DYNAMIC_CIRCLE_EVENT: {
			return {
				...state,
				dynamicEvent: action.dynamicCircleEvent
			}
		}
		case Actions.SET_EPTTA_MAP: {
			return {
				...state,
				epttaMap: action.payload
			}
		}
		case Actions.CLEAR_MAP_STORE: {
			return {
				...initialState
			}
		}
		// case Actions.CREATE_GEO_ITEM_DATA: {
		// 	console.log("new geo dataaaa: ", action.payload)
		// 	if (action.payload === null) {
		// 		return {
		// 			...state
		// 		}
		// 	}
		// 	let type = action.payload.features.features[0].geometry.type
		// 	if (type === "Polygon") {
		// 		let newGeoData = state.geoFencesData.concat(action.payload)
		// 		return {
		// 			...state,
		// 			geoFencesData: newGeoData
		// 		}
		// 	} else if (type === "LineString") {
		// 		let newWayPoisPoisData = state.wayPointsData.concat(action.payload)
		// 		return {
		// 			...state,
		// 			wayPointsData: newWayPoisPoisData
		// 		}
		// 	} else if (type === "Point") {
		// 		let newPoisData = state.poisData.concat(action.payload)
		// 		return {
		// 			...state,
		// 			poisData: newPoisData
		// 		}
		// 	}
		// }
		default: {
			return state;
		}
	}
};



export default map;