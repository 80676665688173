import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	selectedSourceAgent: "",
	selectedTargetGroup: "",
	agentDevicesData: [],//akisx
	deleteAgentDevice: null,
	agentDeviceData: null,
	filterAgentDevicesData: [],
	agentDeviceDialogData: {
		state:false,
		id:"new"
	}
};

const agentDevicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_AGENT_DEVICES: {
			if(action.payload==="") {
				return {
					...state,
					agentDevicesData: [],
					filterAgentDevicesData: []
				};
			}
			else {
				const sortetData = _.sortBy(action.payload,"IMEI")
				return {
					...state,
					agentDevicesData: sortetData,
					filterAgentDevicesData: sortetData
				};
			}
			
		}
		case Actions.SET_SELECTED_DSOURCE_AGENT: {
			return {
				...state,
				selectedSourceAgent: action.selectedSourceAgent,
				filterAgentDevicesData: state.agentDevicesData.filter(({agentId}) => agentId === action.selectedSourceAgent)
			};
				
		}
		case Actions.CLEAR_SELECTED_DSOURCE_AGENT: {
			return {
				...state,
				selectedSourceAgent: action.selectedSourceAgent,
				filterAgentDevicesData: []
			};
		}
		case Actions.DELETE_AGENT_DEVICE: {
			let data1 = state.agentDevicesData?.filter(({_id}) => _id !== action.payload._id)
			let data2 = state.filterAgentDevicesData?.filter(({_id}) => _id !== action.payload._id)
			return {
				...state,
				agentDevicesData: data1,
				filterAgentDevicesData: data2
			};
			
		}
		case Actions.NEW_AGENT_DEVICE: {
			//console.log("action payload "+JSON.stringify(action.payload))
			if(action.payload.agentId === state.selectedSourceAgent) {
				let data1 = state.agentDevicesData.concat(action.payload)
				let sortetData1 = _.sortBy(data1,"IMEI")
				let data2 = state.filterAgentDevicesData.concat(action.payload)
				let sortetData2 = _.sortBy(data2,"IMEI")

				return {
					...state,
					agentDevicesData: sortetData1,
					filterAgentDevicesData: sortetData2
				};
			}else {
				return {
					...state
				};
			}
		}
		case Actions.GET_AGENT_DEVICE: {
			return {
				...state,
				agentDeviceData: action.payload
			};
		}
		case Actions.AGENT_DEVICE_DIALOG_DATA: {
			return {
				...state,
				agentDeviceDialogData:{
					state: action.payload.state,
					id: action.payload.id
				}
			}
		}
		case Actions.CLEAR_AGENT_DEVICES_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default agentDevicesReducer;
