//import firebaseService from 'react/app/services/firebaseService';
import jwtService from 'react/app/services/jwtService';
//import * as Actions from 'react/app/store/actions';
import * as UserActions from './user.actions';
import { showMessage } from 'react/app/store/actions/fuse';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin({ username, password, tenant }) {
	return dispatch =>
		jwtService
			
			.signInWithCredentials(username, password, tenant)
			.then(user => {
				//console.log("UserUI to dispatch: "+JSON.stringify(user.uIUser));
				//console.log("UserUSER to dispatch: "+JSON.stringify(user.user));
				dispatch(UserActions.setSRVUserData(user.user));
				dispatch(UserActions.setUserData(user.uIUser));

				return dispatch({
					type: LOGIN_SUCCESS
				});
			})
		
			.catch(error => {
				//console.log("Real Error: "+JSON.stringify(error))
				dispatch(showMessage({ message: error.err }));

				let errorString =  JSON.stringify(error);
				let fixedErrorString = errorString.split(',"err":"Check Your Credentials"').join('')
				let fixedErrorObject = JSON.parse(fixedErrorString)

				return dispatch({
					type: LOGIN_ERROR,
					payload: fixedErrorObject
				});
			});
}


/*export function submitLoginWithFireBase({ username, password }) {
	if (!firebaseService.auth) {
		console.warn("Firebase Service didn't initialize, check your configuration");

		return () => false;
	}
	
	return dispatch =>
		firebaseService.auth
			.signInWithEmailAndPassword(username, password)
			.then(() => {
				return dispatch({
					type: LOGIN_SUCCESS
				});
			})
			.catch(error => {
				console.info('error');
				const usernameErrorCodes = [
					'auth/email-already-in-use',
					'auth/invalid-email',
					'auth/operation-not-allowed',
					'auth/user-not-found',
					'auth/user-disabled'
				];
				const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];

				const response = {
					username: usernameErrorCodes.includes(error.code) ? error.message : null,
					password: passwordErrorCodes.includes(error.code) ? error.message : null
				};

				if (error.code === 'auth/invalid-api-key') {
					dispatch(Actions.showMessage({ message: error.message }));
				}

				return dispatch({
					type: LOGIN_ERROR,
					payload: response
				});
			});
}
*/