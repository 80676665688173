import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
//import Auth0LoginTab from './tabs/Auth0LoginTab';
//import FirebaseLoginTab from './tabs/FirebaseLoginTab';
import JWTLoginTab from './tabs/JWTLoginTab';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import { Slide } from '@material-ui/core';
import { epttaCommandDarkfonLogo, epttaCommandIcon, epttaCommandLogo } from 'react/app/fuse-layouts/shared-components/EpttaIcons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
            theme.palette.primary.dark,
            0.5
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },
    rootNova: {
        color: theme.palette.primary.contrastText,
        //backgroundImage:`url(require("assets/images/logos/nova/Capture3NOVA_desktop login.jpg"))`,
        background: `url('${process.env.PUBLIC_URL}/assets/images/logos/nova/Capture3NOVA_desktop login2.png')`,
        backgroundPosition: 'center -70px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }
}));

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


function Login() {
    const { t } = useTranslation('manageAgents')
    const theme = useTheme()
    let brand = process.env.REACT_APP_BRAND
    const classes = useStyles();
    const [width, height] = useWindowSize();


    const tabIcon = () => {
        switch (brand) {
            case 'commandDarkFon': {
                return epttaCommandIcon(theme.palette.secondary.main, 'w-56 mb-16')

            }

            case 'command': {
                return epttaCommandIcon(theme.palette.secondary.main, 'w-56 mb-16')
            }

            case 'vigilance': {
                return <img
                    className="w-84"
                    src="assets/images/logos/vigilance/icon.svg"
                    id="login"
                    alt="icon"
                />
            }
            case 'active': {
                return <img
                    className="w-84"
                    //src="assets/images/logos/active/active icon_semi.svg"
                    src="assets/images/logos/active/active icon_b.svg"
                    id="login"
                    alt="icon"
                />
            }
            case 'aid': {
                return <img
                    className="w-84"
                    src="assets/images/logos/aid/aid icon.svg"
                    //src="assets/images/logos/aid/aid icon_RED_WHITE_semi.svg"
                    id="login"
                    alt="icon"
                />
            }
            case 'astratac': {
                return <img
                    className="w-84"
                    src="assets/images/logos/astratac/astratac icon.svg"
                    id="login"
                    alt="icon"
                />
            }
            case 'protecting': {
                return <img
                    className="w-84"
                    src='assets/images/logos/protecting/protecting icon_SEMITRANSPARENT.svg'
                    id="login"
                    alt="icon"
                />
            }
            case 'safe': {
                return <img
                    className="w-84"
                    src="assets/images/logos/safe/safe_in icon.svg"
                    id="login"
                    alt="icon"
                />
            }
            case 'selecta': {
                return <img
                    src='assets/images/logos/selecta/SelectaPTT Logo-Colour.svg'
                    width={200}
                    id="login"
                    alt="icon"
                    className="mb-12"
                />
            }
            case 'dia_talk': {
                return <img
                    src='assets/images/logos/dia_talk/icon.png'
                    width={150}
                    id="login"
                    alt="icon"
                    className="mb-12"
                />
            }
            case 'tempest': {
                return <img
                    src='assets/images/logos/tempest/tempest_icon_GREY.svg'
                    width={50}
                    id="login"
                    alt="icon"
                    className="mb-12"
                />
            }
            case 'nova': {
                return <img
                    src='assets/images/logos/nova/nova_logo_black.png'
                    width={150}
                    id="login"
                    alt="icon"
                    className="mb-12"
                />
            }
            case 'systelios': {
                return <img
                    src='assets/images/logos/systelios/logo_systelios_dark_icon.svg'
                    id="login"
                    alt="icon"
                    className="w-72 mb-32"
                />
            }
        }
    }

    const LogoIcon = () => {

        switch (brand) {
            // case 'commandSDS': {
            //     return <img className="w-full mb-32" src="assets/images/logos/commandSDS/logo.svg" alt="logo" />
            // }
            // case 'command': {
            //     return <img className="w-full mb-32" src="assets/images/logos/command/logo.svg" alt="logo" />
            // }
            case 'command': {
                return epttaCommandLogo(theme.palette.secondary.main, 'w-full mb-64')

            }
            case 'commandDarkFon': {
                return epttaCommandDarkfonLogo(theme.palette.secondary.main, 'w-full mb-64')

            }
            case 'vigilance': {
                return <img className="mb-32" src="assets/images/logos/vigilance/logo.svg" alt="logo" />
            }
            case 'active': {
                return <img className="w-full mb-64" src='assets/images/logos/active/active logo_WHITE.svg' />
            }
            case 'aid': {
                return <img className="w-full mb-32" src='assets/images/logos/aid/aid logo.svg' />
            }
            case 'astratac': {
                return <img className="w-full mb-128" src='assets/images/logos/astratac/astratac logo2.svg' />
            }
            case 'protecting': {
                return <img className="w-full mb-64" src='assets/images/logos/protecting/protecting_logo_WHITE.svg' />
            }
            case 'safe': {
                return <img className="mb-64 w-full" src='assets/images/logos/safe/safe_in logo.svg' />
            }
            case 'dia_talk': {
                return <img className="mb-64 w-full" src='assets/images/logos/dia_talk/logo.png' />
            }
            case 'selecta': {
                return <img className="w-full mb-32" src='assets/images/logos/selecta/SelectaPTT Logo-WHITE.svg' />
            }
            case 'tempest': {
                return <img className="w-full mb-32" src='assets/images/logos/tempest/tempest_LOGO_transparent.svg' />
            }
            case 'nova': {
                return <img className="mb-64 w-full" src='assets/images/logos/nova/nova_logo_white.png' />
            }
            case 'systelios': {
                return <img className="w-full mb-128" src='assets/images/logos/systelios/logo_systelios.png' />

            }
        }
    }

    const welcomeText = () => {
        switch (brand) {
            case 'commandDarkFon': {
                return t('COMMAND_WELCOME')
                //return t('COMMANDSDS_WELCOME')
            }
            case 'vigilance': {
                return t('VIGILANCE_WELCOME')
            }
            case 'command': {
                return t('COMMAND_WELCOME')
            }
            case 'safe': {
                return t('SAFEIN_WELCOME')
            }
            case 'active': {
                return t('ACTIVE_WELCOME')
            }
            case 'aid': {
                return t('AID_WELCOME')
            }
            case 'astratac': {
                return t('ASTRATAC_WELCOME')
            }
            case 'protecting': {
                return t('PROTECTING_WELCOME')
            }
            case 'selecta': {
                return t("SELECTA_WELCOME")
            }
            case 'tempest': {
                return t("TEMPEST_WELCOME")
            }
            case 'dia_talk': {
                return t("DIA_WELCOME")
            }
            case 'nova': {
                return t("NOVA_WELCOME")
            }
            case 'systelios': {
                return t("SYSTELIOS_WELCOME")
            }
        }
    }

    return (
        <div
            style={{ backgroundPosition: height >= 1080 ? 'center' : 'center -70px' }}
            className={brand === "nova" ? clsx(classes.rootNova, 'flex flex-col flex-1 flex-shrink-0 p-25 md:flex-row md:p-0 md:overflow-y-hidden md:overflow-x-hidden') : clsx(classes.root, 'flex flex-col flex-1 flex-shrink-0 p-25 md:flex-row md:p-0 md:overflow-y-hidden md:overflow-x-hidden')}
        >
            <div className="flex flex-1 flex-col items-center md:justify-center text-white p-16 text-center md:pb-128 md:pl-128 md:pr-128 md:items-start md:flex-shrink-0 md:flex-1">
                <Slide in={true} direction="down" timeout={1000}>
                    <div className="flex w-full justify-center">
                        <LogoIcon />
                    </div>
                </Slide>
                <Slide in={true} direction="up" timeout={1000}>
                    <Typography variant="h3" color="inherit" className="font-light w-full text-center">
                        {welcomeText()}
                    </Typography>
                </Slide>
            </div>

            <div className="flex items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
                <Slide in={true} direction="left" timeout={500}>
                    <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
                        <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 " style={{ paddingBottom: brand !== "dia_talk" ? "12.8rem" : "" }}>
                            <Typography variant="h6" className="text-center md:w-full mb-48">
                                {t('LOGIN')}
                            </Typography>

                            <Typography className="flex w-full items-center justify-center">
                                {tabIcon()}
                            </Typography>

                            <Divider className="w-full mb-32" style={{ backgroundColor: theme.palette.secondary.main }} />

                            <JWTLoginTab />
                        </CardContent>
                    </Card>
                </Slide>
            </div>
        </div>
    );
}

export default Login;
