import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';
import { getTenantAdminLicenseData } from './licenses.actions'
import * as Actions from '../actions'
export const GET_AGENTS = '[MANAGE-COMMAND APP] GET AGENTS'; //akisx
export const SET_AGENTS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET PRODUCTS SEARCH TEXT';
export const NEW_AGENT = '[MANAGE-COMMAND APP] NEW AGENT'; //akisx

export const GET_AGENT = '[MANAGE-COMMAND APP] GET AGENT';//akisx
export const GET_AGENT_ITEMS = '[MANAGE-COMMAND APP] GET AGENT ITEMS';//akisx
export const GET_AGENT_EMERG_CHANS = '[MANAGE-COMMAND APP] GET AGENT EMERG CHANS';//akisx
export const SAVE_AGENT = '[MANAGE-COMMAND APP] SAVE AGENT'; //akisx
export const DELETE_AGENT = '[MANAGE-COMMAND APP] DELETE AGENT'; //akisx
export const CLEAR_AGENTS_STORE = '[MANAGE-COMMAND APP] CLEAR AGENTS STORE'; //akisx
export const AGENT_MARKER_PREVIEW_DIALOG_STATE = '[MANAGE-COMMAND APP] AGENT MARKER PREVIEW DIALOG STATE'; //akisx
export const SAVE_MULTI_AGENTS = '[MANAGE-COMMAND APP] SAVE MULTI AGENTS'; //akisx
export const IS_MULTI_AGENT_IMPORT = '[MANAGE-COMMAND APP] IS MULTI AGENT IMPORT'; //akisx

export function clearAgentsStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_AGENTS_STORE
		})

}

export function agentMarkerPreviewDialogState(state) {
	return {
		type: AGENT_MARKER_PREVIEW_DIALOG_STATE,
		payload: state
	}
}

/** 
axios.interceptors.request.use(request => {
	//console.log('Starting Request', request)
	return request
  })
  
  axios.interceptors.response.use(response => {
	//console.log('Response:', response)
	return response
  })*/


export function newAgent(data, message) {
	const request = axios.post('EpttaAdmin/Agents', data);

	return dispatch =>
		request.then(response => {
			dispatch(getTenantAdminLicenseData())
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Agent Saved' }));
			return dispatch({
				type: NEW_AGENT,
				payload: response.data.agent
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				console.log("SUPER ERRR", e)
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}


export function getAgents() {

	const request = axios.get('EpttaAdmin/Agents?page=0&size=9999');

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_AGENTS,
				payload: response.data.content
			})
		);
}

export function setAgentsSearchText(event) {
	return {
		type: SET_AGENTS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getAgent(params) {
	const request = axios.get(`EpttaAdmin/Agents/Agent/${params.agentId}`);

	return dispatch =>
		request.then(response => {
			//console.log("get agent response: "+JSON.stringify(response.data));
			dispatch({
				type: GET_AGENT,
				payload: response.data
			})
		});
}

export function getAgentItems(agent, message) {
	const request = axios.get(`EpttaAdmin/Agent/Count/${agent._id}`);

	return dispatch =>
		request.then(response => {
			//console.log("get agent items response: "+JSON.stringify(response.data));
			dispatch({
				type: GET_AGENT_ITEMS,
				payload: response.data
			});

			dispatch(Actions.setConfirmationDialogStatus(true))
			dispatch(Actions.setConfirmationActionType("agent"))
			dispatch(Actions.setConfirmationDialogContent(`${message.deleteAgent}${message.agentSub}${response.data.agentSubsciptionRecords}, ${message.channelSub}${response.data.channelSubscriptionRecords}, ${message.groupSub}${response.data.groupMemberRecords}, ${message.messages}${response.data.messageRecords} }`))
			dispatch(Actions.setConfirmationSelectedItem(agent))

			// window.confirm("Are you sure you wish to delete this Agent? Items Info: "+JSON.stringify(response.data)) &&
			// dispatch(deleteAgent(agent));
		});
}

export function getAgentEmergChans(params) {
	const request = axios.get(`EpttaAdmin/Agents/AgentEmergencyChannels/${params.agentId}`);

	return dispatch =>
		request.then(response => {
			//console.log("get emergency channels response: "+JSON.stringify(response.data));
			dispatch({
				type: GET_AGENT_EMERG_CHANS,
				payload: response.data
			})
		});
}


export function saveAgent(data, message) {
	const request = axios.put('EpttaAdmin/Agents', data);

	return dispatch =>
		request.then(response => {
			dispatch(getTenantAdminLicenseData())
			dispatch(showMessage({ variant: 'success', message: message }));

			//dispatch(showMessage({ variant: 'success', message: 'Agent Saved' }));
			//console.log("save response: " +JSON.stringify(response));

			return dispatch({
				type: SAVE_AGENT,
				payload: data
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}

export function deleteAgent(agent, message) {
	const request = axios.delete(`EpttaAdmin/Agents?id=${agent._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(getTenantAdminLicenseData())
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Agent Deleted' }));
			//console.log("delete response: " +JSON.stringify(response));
			return dispatch({
				type: DELETE_AGENT,
				payload: agent
			});
		})
}


export function saveMultiAgents(multiAgents, data, message) {
	//console.log("saveMultiAgents data", multiAgents, data)

	var agentNames = []
	for (let i = multiAgents.countFrom; i <= multiAgents.countTo; i++) {
		let agentName = multiAgents.agentsStartingName + i
		agentNames.push(agentName)
	}

	data._id = "multi"
	data.agentName = ""
	const multiAgentsObj = {
		agent: data,
		agentNames: agentNames
	}

	const request = axios.post('EpttaAdmin/Agents/multiAgentImportation', multiAgentsObj)

	return dispatch =>
		request.then(response => {
			dispatch(getTenantAdminLicenseData())
			setTimeout(() => {
				dispatch(getTenantAdminLicenseData())
			}, 1000 * (agentNames.length / 150))
			dispatch(showMessage({ variant: 'success', message: message }));

			return dispatch({
				type: SAVE_MULTI_AGENTS,
				payload: response.data,
				names: multiAgentsObj.agentNames
			});
		}).catch(err => {
			if (err?.response && err.response?.data) {
				if (err.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: err.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: err.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				//dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		})

}

export function isMultiAgentImport(state) {
	return {
		type: IS_MULTI_AGENT_IMPORT,
		payload: state
	}
}

export function newAgentTemplate(tenant) {

	const data = {
		"_id": "new",
		"tenantId": tenant,
		"agentName": "New",
		"allowIds": [
		],
		"settings": {
			"_id": "new",
			"communicationSettings": {
				"emergencyChannelId": "",
				"allowDoNotDisturb": true,
				"allowHQ": true,
				"allowMakeGroupCalls": true,
				"allowReceiveGroupCalls": true,
				"allowMakeIndividualCalls": true,
				"allowReceiveIndividualCalls": true,
				"allowMakeDirectPTT": true,
				"allowReceiveDirectPTT": true,
				"allowSendTextMessages": true,
				"allowReceiveTextMessages": true,
				"allowSendAttachments": true,
				"allowReceiveAttachments": true,
				"allowMakeE2EE": false,
				"allowReceiveE2EE": false,
				"allowMakeEmergency": true,
				"allowReceiveEmergency": true
			},
			"deviceSettings": {
				"enableDeviceInfoSharing": true,
				"deviceInfoSharingInterval": 300,
				"allowAgentProtection": false,
				"allowSelfAgentProtection": false,
				"enableHealthVitalSignsSharing": false,
				"healthVitalSignsSharingInterval": 300
			},
			"locationSettings": {
				"enableLocationSharing": true,
				"locationSharingInterval": 30,
				"locationUpdateDistance": 30,
				"locationUpdateMode": "i",
				"allowReceiveNavigation": true,
				'forceGPS': false
			},
			"historySettings": {
				"maxHistoryItems": 100,
				"maxHistoryPeriod": 60
			},
			"remoteSettings": {
				"allowRemoteMicControl": false,
				"allowRemoteMicSharing": false,
				"allowMicNotifications": false,
				"allowRemotePictureControl": false,
				"allowRemotePictureSharing": false,
				"allowPictureNotifications": false,
				"allowRemoteVideoControl": false,
				"allowRemoteVideoSharing": false,
				"allowVideoNotifications": false
			},
			"UISettings": {
				"allowAgentList": true,
				"allowChannelList": true,
				"allowGroupList": true,
				"allowChatView": true,
				//"allowTasks": false,
				"allowMaps": true,
				"allowIndoorMaps": false,
				"allowMapToolsEditor": false,
				"allowCommandCenter": false,
				"allowDGNAView": false,
				"allowJournal": false,
				"allowWeatherForecast": false,
				"allowWorkFlow": false,
				"allowHealthVitalSigns": false,
			},
			// "workFlowSettings": {
			// 	'allowCreate': false,
			// 	'allowUpdate': false,
			// 	'allowDelete': false,
			// },
			"created": 1581954318514,
			"lastUpdate": 1581954318514
		},
		"state": [

		],
		"created": null,
		"lastUpdate": 1581954318521

	};

	return {
		type: GET_AGENT,
		payload: data
	};
}

