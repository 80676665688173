import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { epttaCommandIcon } from 'react/app/fuse-layouts/shared-components/EpttaIcons';
import { useSelector } from 'react-redux';


function FuseSplashScreen() {
	let brand = process?.env.REACT_APP_BRAND
	const theme = useTheme();

	return (
		<div id="fuse-splash-screen" style={{ backgroundColor: theme.palette.background.default }}>
			<div className="center">
				<div className="logo">
					{/* Command loading icon */}
					{/* <img width="1024" height="1024" src="assets/images/logos/command.svg" alt="logo" /> */}

					{brand === 'commandDarkFon' &&

						epttaCommandIcon(theme.palette.secondary.main)
						//<img src="assets/images/logos/commandSDS/icon.svg" alt="logo" />
					}

					{brand === 'command' &&
						epttaCommandIcon(theme.palette.secondary.main)
						// < img src="assets/images/logos/command/icon.svg" alt="logo" />
					}

					{brand === 'vigilance' &&
						<img src="assets/images/logos/vigilance/icon.svg" alt="logo" />
					}

					{brand === 'active' &&
						<img

							src='assets/images/logos/active/active icon_b.svg'
						//src='assets/images/logos/active/active icon_semi.svg'
						/>
					}

					{brand === 'aid' &&
						<img
							//src='assets/images/logos/aid/aid icon_RED_WHITE_semi.svg'
							src='assets/images/logos/aid/aid icon.svg'
							alt="logo"
						/>
					}
					{brand === 'astratac' &&
						<img

							src='assets/images/logos/astratac/astratac icon.svg'
							alt="logo"
						/>
					}
					{brand === 'protecting' &&
						<img
							//src='assets/images/logos/protecting/protecting icon_SEMITRANSPARENT.svg'
							alt="logo"
							src='assets/images/logos/protecting/protecting_icon_white.svg'
						/>
					}
					{brand === 'safe' &&
						<img
							src='assets/images/logos/safe/safe_in icon.svg'
							alt="logo"
						/>
					}
					{brand === 'selecta' &&
						<img
							src='assets/images/logos/selecta/SELECTA icon WHITE.svg'
							alt="logo"
						/>
					}
					{brand === 'tempest' &&
						<img
							src='assets/images/logos/tempest/tempest_icon_GREY.svg'
							alt="logo"
						/>
					}
					{brand === 'dia_talk' &&
						<img
							src='assets/images/logos/dia_talk/icon.png'
							alt="logo"
						/>
					}
					{brand === 'nova' &&
						<img
							src='assets/images/logos/nova/nova_logo_white.svg'
							alt="logo"
						/>
					}
					{brand === 'systelios' &&
						<img
							src='assets/images/logos/systelios/logo_systelios__icon.svg'
							alt="logo"
						/>
					}

				</div>
				<div className="spinner-wrapper">
					<div className="spinner">
						<div className="inner">
							<div className="gap" />
							<div className="left">
								<div className="half-circle" style={{ borderColor: theme.palette.secondary.main, borderBottomColor: "transparent", borderRightColor: "transparent" }} />
							</div>
							<div className="right">
								<div className="half-circle" style={{ borderColor: theme.palette.secondary.main, borderBottomColor: "transparent", borderLeftColor: "transparent" }} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(FuseSplashScreen);
