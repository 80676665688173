import history from '@history';
import _ from '@lodash';
import axios from 'axios';
//import { showMessage } from 'react/app/store/actions/fuse';
//import auth0Service from 'react/app/services/auth0Service';
//import firebaseService from 'react/app/services/firebaseService';
import jwtService from 'react/app/services/jwtService';
import * as MessageActions from 'react/app/store/actions/fuse/message.actions';
import * as FuseActions from 'react/app/store/actions/fuse';
//import Axios from 'axios';
//import firebase from 'firebase/app';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const SET_SRV_USER_DATA = '[USER] SET SRV USER DATA';

var customeUiObject = {
	role: 'admin',
	data: {
		tenant: 'First Login',
		displayName: 'Super Admin',
		photoURL: 'assets/images/avatars/avatar.png',
		email: 'admin@admins.com',
		settings: {
			layout: {
				style: 'layout1',
				config: {
					scroll: 'content',
					navbar: {
						display: true,
						folded: true,
						position: 'left'
					},
					toolbar: {
						display: true,
						style: 'fixed',
						position: 'below'
					},
					footer: {
						display: true,
						style: 'fixed',
						position: 'below'
					},
					mode: 'fullwidth'
				}
			},
			customScrollbars: true,
			theme: {
				main: 'defaultDark',
				navbar: 'defaultDark',
				toolbar: 'defaultDark',
				footer: 'defaultDark'
			}
		},
		shortcuts: []
	}
}
//export const SAVE_UI_SETTINGS = '[USER] SAVE SAVE_UI_SETTINGS'
/**
 * Set user data from Auth0 token data
 
export function setUserDataAuth0(tokenData) {
	const user = {
		role: ['admin'],
		from: 'auth0',
		data: {
			displayName: tokenData.username,
			photoURL: tokenData.picture,
			email: tokenData.email,
			settings:
				tokenData.user_metadata && tokenData.user_metadata.settings ? tokenData.user_metadata.settings : {},
			shortcuts:
				tokenData.user_metadata && tokenData.user_metadata.shortcuts ? tokenData.user_metadata.shortcuts : []
		}
	};

	return setUserData(user);
}
*/

/**
 * Set user data from Firebase data
 

export function setUserDataFirebase(user, authUser) {
	if (
		user &&
		user.data &&
		user.data.settings &&
		user.data.settings.theme &&
		user.data.settings.layout &&
		user.data.settings.layout.style
	) {
		// Set user data but do not update
		return setUserData(user);
	}

	// Create missing user settings
	return createUserSettingsFirebase(authUser);
}
*/

/**
 * Create User Settings with Firebase data
 

export function createUserSettingsFirebase(authUser) {
	return (dispatch, getState) => {
		const guestUser = getState().auth.user;
		const fuseDefaultSettings = getState().fuse.settings.defaults;
		const { currentUser } = firebase.auth();

		/**
		 * Merge with current Settings
		 
		
		const user = _.merge({}, guestUser, {
			uid: authUser.uid,
			from: 'firebase',
			role: ['admin'],
			data: {
				displayName: authUser.displayName,
				email: authUser.email,
				settings: { ...fuseDefaultSettings }
			}
		});
		currentUser.updateProfile(user.data);

		updateUserData(user, dispatch);
		return dispatch(setUserData(user));
	};
}
*


/**
 * Set User Data
 */
export function setUserData(user) {
	return dispatch => {
		/*
		You can redirect the logged-in user to a specific route depending on his role
		 */
		// history.location.state = {
		//     redirectUrl: user.redirectUrl // for example 'apps/academy'
		// }

		//console.log("UserTest userActions",history)
		if (user.role === "superAdmin") {
			// history.location.state = {
			//     redirectUrl: '/apps/super-admin/tenants' // for example 'apps/academy'
			// }
			history.push('/apps/super-admin/tenants')
		}

		/*
		Set User Settings
		 */
		//console.log("set user data settings caleed: "+JSON.stringify(user));
		dispatch(FuseActions.setDefaultSettings(user.data.settings));

		/*
		Set User Data
		 */
		dispatch({
			type: SET_USER_DATA,
			payload: user
		});
	};
}

export function setSRVUserData(user) {
	return dispatch => {

		/*
		Set Server User Data
		 */
		dispatch({
			type: SET_SRV_USER_DATA,
			payload: user
		});
	};
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings, adminData) {
	//console.log("adminData to be saved: "+JSON.stringify(adminData))
	if (!adminData?.user?.object?.data) {

		if (adminData?.user?.role?.includes("admin")) {
			adminData.user.object = customeUiObject
			adminData.user.object.role = "admin"
			adminData.user.object.data.tenant = adminData?.user.username.split("@")[1]
			adminData.user.object.data.email = "admins@email.com"
		} else if (adminData?.user?.role?.includes("tenantadmin")) {
			adminData.user.object = customeUiObject
			adminData.user.object.role = "superAdmin"
			adminData.user.object.data.tenant = adminData?.user.username.split("@")[1]
			adminData.user.object.data.email = "superAdmins@email.com"
		}

	}
	//console.log("OBJECTTTT: "+JSON.stringify(adminData.user.object))
	adminData.user.object.data.settings = settings
	adminData.user.object.data.displayName = adminData.user.username
	//console.log("admin to be saved: "+JSON.stringify(adminData.user))

	let request
	if (adminData?.user?.role?.includes("admin")) {
		request = axios.put('EpttaAdmin/Users', adminData.user);
	} else {
		request = axios.put('EpttaTenantAdmin/Users', adminData.user);
	}


	return (dispatch, getState) => {
		request.then(response => {
			//dispatch(showMessage({ variant: 'success', message: 'Agent Saved' }));
			const oldUser = getState().auth.user.data;
			const user = _.merge({}, oldUser, { data: { settings } });
			//console.log("User to be saved: "+JSON.stringify(user))
			updateUserData(user, dispatch);


			return dispatch(setUserData(user));
		})

	};
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts, srvUser) {
	//console.log("update USer sortcut called: "+JSON.stringify(shortcuts))

	srvUser.user.object.data.shortcuts = shortcuts
	let realUser = srvUser.user.object
	//console.log("sortcutsss USer: "+JSON.stringify(srvUser))

	const request = axios.put('EpttaAdmin/Users', srvUser.user);

	return (dispatch, getState) => {
		//const { user } = getState().auth
		const newUser = {
			...realUser,
			data: {
				...realUser.data,
				shortcuts
			}
		};

		updateUserData(newUser, dispatch);

		return dispatch(setUserData(newUser));
	};
}

/**
 * Remove User Data
 */
export function removeUserData() {
	return {
		type: REMOVE_USER_DATA
	};
}

/**
 * Logout
 */
export function logoutUser() {

	return (dispatch, getState) => {
		//console.log("LOGOUTTTT CALLEEDD")
		const { user } = getState().auth
		//const { user } = auth?.user
		let myUser = user?.srvData?.user?.object
		//console.log("uauth statteee: "+JSON.stringify(user))
		//console.log("user statteee: "+JSON.stringify(myUser))

		if (myUser && (!myUser.role || myUser.role.length === 0)) {
			// is guest
			history.push({
				pathname: '/login'
			});
			return null;
		}

		// history.push({
		// 	pathname: '/login'
		// });




		let service = 'JWT'

		switch (service) {
			/*
			case 'firebase': {
				firebaseService.signOut();
				break;
			}
			case 'auth0': {
				auth0Service.logout();
				break;
			}
			*/
			case 'JWT': {

				jwtService.logout();
				history.push({
					pathname: '/login'
				});
				break;
			}
			default: {

				history.push({
					pathname: '/login'
				});
				//jwtService.logout();
			}
		}

		dispatch(FuseActions.setInitialSettings());

		return dispatch({
			type: USER_LOGGED_OUT
		});
	};
}

/**
 * Update User Data
 */
function updateUserData(user, dispatch) {
	//console.log("update user data: "+JSON.stringify(user))
	let thisUser = user;
	if (!thisUser.role || thisUser.role.length === 0) {
		// is guest
		return;
	}

	switch (thisUser.from) {
		/*
		case 'firebase': {
			firebaseService
				.updateUserData(user)
				.then(() => {
					dispatch(MessageActions.showMessage({ message: 'User data saved to firebase' }));
				})
				.catch(error => {
					dispatch(MessageActions.showMessage({ message: error.message }));
				});
			break;
		}
		case 'auth0': {
			auth0Service
				.updateUserData({
					settings: user.data.settings,
					shortcuts: user.data.shortcuts
				})
				.then(() => {
					dispatch(MessageActions.showMessage({ message: 'User data saved to auth0' }));
				})
				.catch(error => {
					dispatch(MessageActions.showMessage({ message: error.message }));
				});
			break;
		}
		*/
		default: {
			jwtService
				.updateUserData(thisUser)
				.then(() => {
					dispatch(MessageActions.showMessage({ variant: 'success', message: 'Saved' }));
				})
				.catch(error => {
					dispatch(MessageActions.showMessage({ variant: 'error', message: error.message }));
				});
			break;
		}
	}
}
