import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_SUPER_ADMINS = '[MANAGE-COMMAND APP] GET SUPER_ADMINS'; //akisx
export const SET_SUPER_ADMINS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET SUPER _DMINS_SEARCH_TEXT';
export const NEW_SUPER_ADMIN = '[MANAGE-COMMAND APP] NEW SUPER_ADMIN'; //akisx

export const GET_SUPER_ADMIN = '[MANAGE-COMMAND APP] GET SUPER_ADMIN';//akisx
export const SAVE_SUPER_ADMIN = '[MANAGE-COMMAND APP] SAVE SUPER_ADMIN'; //akisx
export const DELETE_SUPER_ADMIN = '[MANAGE-COMMAND APP] DELETE SUPER_ADMIN'; //akisx
export const CLEAR_SUPER_ADMINS_STORE = '[MANAGE-COMMAND APP] CLEAR SUPER ADMINS STORE'; //akisx
export const SUPPERADMIN_DIALOG_DATA = '[MANAGE-COMMAND APP] SUPPERADMIN DIALOG DATA'; //akisx


export function clearSuperAdminsStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_SUPER_ADMINS_STORE
		})
		
}

export function superAdminDialogData(payload){
	return {
		type : SUPPERADMIN_DIALOG_DATA,
		payload : payload
	}
}

export function newSuperAdmin(data, message) { //new superAdmin just update my last superAdmin
	const request = axios.post('EpttaTenantAdmin/Users', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Super Admin Saved' }));

			return dispatch({
				type: NEW_SUPER_ADMIN,
				payload: response.data
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}


export function getSuperAdmins(authUser) {

	const request = axios.get(`EpttaTenantAdmin/Users/GetAdmins`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SUPER_ADMINS,
				// payload: response.data
				payload: response.data,
				authUser: authUser
			})
		);
}

export function setSuperAdminsSearchText(event) {
	return {
		type: SET_SUPER_ADMINS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getSuperAdmin(superAdminsId) {
	const request = axios.get(`EpttaTenantAdmin/Users/GetUserById?id=${superAdminsId}`);//need paris get by id

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SUPER_ADMIN,
				payload: response.data
			})
		);
}

export function saveSuperAdmin(data, message, isTimeToLogOut) {

	const request = axios.put('EpttaTenantAdmin/Users', data);
	return dispatch =>
		request.then(response => {
			//dispatch(showMessage({ variant: 'success', message: 'SuperAdmin Saved' }));
			dispatch(showMessage({ variant: 'success', message: message }));
			//console.log("save response: " +JSON.stringify(response));
			if (response.status === 200 && !isTimeToLogOut) {
				return dispatch({
					type: SAVE_SUPER_ADMIN,
					payload: data
				});

			} else if(response.status !== 200){
				dispatch(showMessage({ variant: 'error', message: 'Response Status: ' + response.status }));
			}


		}).catch(e => {
			console.log("Unhandle ERROR", e)
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}

				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});


}

export function deleteSuperAdmin(data, message) {
	const request = axios.delete(`EpttaTenantAdmin/Users?id=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Super Admin Deleted' }));
			//console.log("delete response: " +JSON.stringify(response));

			return dispatch({
				type: DELETE_SUPER_ADMIN,
				payload: data
			});
		})
}

export function newSuperAdminTemplate(tenant, tenantId) {

	const data = {
		"_id": "new",
		"created": "",
		"deviceToken": [],
		"isActive": true,
		"lastUpdate": "",
		"object": {
			"role": "superAdmin",
			"data": {
				"tenant": tenant,//need tenantName here to pass to props
				"displayName": "SuperAdmin",//pass a name
				"photoURL": "assets/images/avatars/avatar.png",//have to set a default
				"email": "superAdmins@email.com", //set the email
				"settings": {
					"layout": {
						"style": "layout1",
						"config": {
							"scroll": "content",
							"navbar": {
								"display": true,
								"folded": true,
								"position": "left"
							},
							"toolbar": {
								"display": true,
								"style": "fixed",
								"position": "below"
							},
							"footer": {
								"display": true,
								"style": "fixed",
								"position": "below"
							},
							"mode": "fullwidth"
						}
					},
					"customScrollbars": true,
					"theme": {
						"main": "defaultDark",
						"navbar": "defaultDark",
						"toolbar": "defaultDark",
						"footer": "defaultDark"
					}
				},
				"shortcuts": [

				]
			}
		},
		"password": "",
		"role": [
			"tenantadmin"
		],
		"salt": "",
		"saltString": "",
		"tenantId": tenantId,
		"username": "New"
	};

	return {
		type: GET_SUPER_ADMIN,
		payload: data
	};
}

