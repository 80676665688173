import axios from 'axios';
import * as Actions from '../actions'
import { showMessage } from 'react/app/store/actions/fuse';
export const GET_TENANTS = '[MANAGE-COMMAND APP] GET TENANTS'; //akisx
export const SET_TENANTS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET TENANTS SEARCH TEXT';
export const NEW_TENANT = '[MANAGE-COMMAND APP] NEW TENANT'; //akisx

export const GET_TENANT = '[MANAGE-COMMAND APP] GET TENANT';//akisx
export const GET_TENANT_ITEMS = '[MANAGE-COMMAND APP] GET TENANT ITEMS';//akisx
export const SAVE_TENANT = '[MANAGE-COMMAND APP] SAVE TENANT'; //akisx
export const DELETE_TENANT = '[MANAGE-COMMAND APP] DELETE TENANT'; //akisx
export const CLEAR_TENANTS_STORE = '[MANAGE-COMMAND APP] CLEAR TENANTS STORE'; //akisx
export const TENANT_DIALOG_DATA = '[MANAGE-COMMAND APP] TENANT DIALOG DATA'; //akisx


export function clearTenantStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_TENANTS_STORE
		})
		
}

export function tenantDialogData(state) {
	return {
		type: TENANT_DIALOG_DATA,
		payload: state
	}
}

export function newTenant(data, message) {	
	const request = axios.post('EpttaTenantAdmin/Tenant', data);	

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Tenant Saved' }));


			return dispatch({
				type: NEW_TENANT,
				payload: response.data
			});
		}).catch(e => {
			if(e?.response && e.response?.data) {
				if(e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				}else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}
				
				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}


export function getTenants() {

	const request = axios.get(`EpttaTenantAdmin/Tenants`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_TENANTS,
				payload: response.data
			})
		);	
}

export function getTenantsItems(data, message) {
	const request = axios.get(`EpttaTenantAdmin/Tenants/Count/${data._id}`);
	
	return dispatch =>
		request.then(response => {
			
			dispatch({
				type: GET_TENANT_ITEMS,
				payload: response.data
			});
			//console.log("get agent items response: ", response.data)
			dispatch(Actions.setConfirmationDialogStatus(true))
			dispatch(Actions.setConfirmationActionType("tenant"))

			// spasta MapData geofence,pois,wpois 
			// dispatch(Actions.setConfirmationDialogContent(`${message.title}
			// ${message.users} : ${response.data.users}, 
			// ${message.agentSubscriptions} : ${response.data.agentSubscriptions}, 
			// ${message.agentLocations} : ${response.data.agentLocations}, 
			// ${message.channels} : ${response.data.channels},
			// ${message.channelSubscriptions} : ${response.data.channelSubscriptions}, 
			// ${message.groups} : ${response.data.groups},
			// ${message.groupSubscriptions} : ${response.data.groups}, 
			// ${message.devices} : ${response.data.devices}, 
			// ${message.deviceInfos} : ${response.data.deviceInfos}, 
			// ${message.files} : ${response.data.files}, 
			// ${message.messages} : ${response.data.messages}, 
			// ${message.tenantSettings} : ${response.data.tenantSettings}, 
			// ${message.mapProviders} : ${response.data.mapProviders}, 
			// ${message.geoFences} : ${response.data.geoFences}, 
			// ${message.wayPoints} : ${response.data.wayPoints}, 
			// ${message.pois} : ${response.data.pois}`))
			dispatch(Actions.setConfirmationDialogContent(`${message.title}
			${message.users} : ${response.data.users}, 
			${message.agentSubscriptions} : ${response.data.agentSubscriptions}, 
			${message.agentLocations} : ${response.data.agentLocations}, 
			${message.channels} : ${response.data.channels},
			${message.channelSubscriptions} : ${response.data.channelSubscriptions}, 
			${message.groups} : ${response.data.groups},
			${message.groupSubscriptions} : ${response.data.groups}, 
			${message.devices} : ${response.data.devices}, 
			${message.deviceInfos} : ${response.data.deviceInfos}, 
			${message.files} : ${response.data.files}, 
			${message.messages} : ${response.data.messages}, 
			${message.tenantSettings} : ${response.data.tenantSettings}, 
			${message.mapProviders} : ${response.data.mapProviders}, 
			${message.mapData} : ${response.data.geoFences}`))

			dispatch(Actions.setConfirmationSelectedItem(data))

			// window.confirm("Are you sure you wish to delete this Tenant? Items Info: "+JSON.stringify(response.data)) &&
			// dispatch(deleteTenant(data));
		});
}

export function setTenantsSearchText(event) {
	return {
		type: SET_TENANTS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getTenant(tenantsId) {
	const request = axios.get(`EpttaTenantAdmin/Tenant/${tenantsId}`);//need paris get by id
	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_TENANT,
				payload: response.data
			})
		);
}

export function saveTenant(data, message) {
	const request = axios.put('EpttaTenantAdmin/Tenant', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			// dispatch(showMessage({ variant: 'success', message: 'Tenant Saved' }));
			//console.log("save response: " +JSON.stringify(response));

			return dispatch({
				type: SAVE_TENANT,
				payload: data
			});
		}).catch(e => {
			if(e?.response && e.response?.data) {
				if(e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				}else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}
				
				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}

export function deleteTenant(data, message) {
	const request = axios.delete(`EpttaTenantAdmin/Tenant?tenantId=${data._id}`);	

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Tenant Deleted' }));
			//console.log("delete response: " +JSON.stringify(response));

			return dispatch({
				type: DELETE_TENANT,
				payload: data
			});
		})
}

export function newTenantTemplate(tenantId) {
	
	const data = {
		"_id": tenantId,
		"created": "",
		"description": "new",
		"id": tenantId,
		"lastUpdate": "",
		"name": "new"
	  }

	return {
		type: GET_TENANT,
		payload: data
	};
}

