import * as Actions from '../actions';

const initialState = {
	agentsLocationsData: null,//akisx
	fullAgentsLocationsData: [],
};

const agentsLocations = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_AGENTS_LOCATIONS: {
			if(action.payload) {
				return {
					...state,
					agentsLocationsData: action.payload
				};
			}else {
				return {
					...state,
					agentsLocationsData: []
				}
			}
			
		
		}
		case Actions.CLEAR_AGENTS_LOCATIONS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default agentsLocations;