import { authRoles } from 'react/app/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import ar from '../i18n/ar';
import en from '../i18n/en';
import tr from '../i18n/tr';
import gr from '../i18n/gr'; //Akisx

i18next.addResourceBundle('en', 'manageAgents', en);
i18next.addResourceBundle('tr', 'manageAgents', tr);
i18next.addResourceBundle('ar', 'manageAgents', ar);
i18next.addResourceBundle('gr', 'manageAgents', gr); //Akisx

const SuperAdminAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superAdmin,
	routes: [
		{
			path: '/apps/super-admin/superAdmins/:superAdminsId/:superAdminsName?',
			component: React.lazy(() => import('./superAdmins/SuperAdmins'))
		},
		{
			path: '/apps/super-admin/superAdmins',
			component: React.lazy(() => import('./superAdmins/SuperAdmins'))
		},
		{
			path: '/apps/super-admin/tenants/:tenantsId/:tenantsName?',
			component: React.lazy(() => import('./tenants/Tenants'))
		},
		{
			path: '/apps/super-admin/tenants',
			component: React.lazy(() => import('./tenants/Tenants'))
		},
		{
			path: '/apps/super-admin/license',
			component: React.lazy(() => import('./license/Licenses'))
		},
		{
			path: '/apps/super-admin/chart',
			component: React.lazy(() => import('./chart/Charts'))
		},
		// {
		// 	path: '/apps/super-admin/clientsDownload',
		// 	component: React.lazy(() => import('../clientsDownload/ClientsDownload'))
		// },
		{
			path: '/apps/super-admin/clientsDownload',
			component: React.lazy(() => import('../../../fuse-layouts/shared-components/clientsDownload/ClientsDownload'))
		},
		{
			path: '/apps/super-admin/tenantSettings',
			component: React.lazy(() => import('../tenant/tenantSettings/TenantSettings'))
		}
		// {
		// 	path: '/apps/super-admin',
		// 	component: () => <Redirect to="/apps/super-admin/tenantSettings" />
		// }
	]
};

export default SuperAdminAppConfig;
