const primaryLocale = {

	COMMANDSDS_WELCOME: 'Welcome to CommandSDS Platform!',
	COMMAND_WELCOME: 'Welcome to Command Platform!',
	VIGILANCE_WELCOME: 'Welcome to Vigilance Platform!',
	WELCOME: 'Welcome to Command Administration Platform!',
	SAFEIN_WELCOME: 'Welcome to Safe In Platform!',
	ACTIVE_WELCOME: 'Welcome to Active PTT Platform!',
	AID_WELCOME: 'Welcome to Aid Platform!',
	ASTRATAC_WELCOME: 'Welcome to Astratac Platform!',
	PROTECTING_WELCOME: 'Welcome to Protecting Platform!',
	SELECTA_WELCOME: 'Welcome to Selecta PTT Platform!',
	TEMPEST_WELCOME: 'Welcome to Tempest Platform!',
	NOVA_WELCOME: 'Welcome to Nova PTT Platform!',
	DIA_WELCOME: 'Welcome to DIA Platform!',
	SYSTELIOS_WELCOME: 'Welcome to SyStelios PTT Platform!',

	LOGIN: 'Login to your account',
	AGENTS: 'Agents',
	SEARCH: 'search',
	ADDNEWAGENT: 'Add New Agent',
	ADDMULTIAGENTS: 'Add Multiple Agents',
	MULTI: 'Multiple ',
	NEW: 'New',
	AGENT2AGENTSUBS: 'Agent Subscriptions',
	CHANNELS: 'Channels',
	GROUPS: 'Groups',
	//SEARCH: 'search',

	//Agent+basic
	SAVE: 'Save',
	BASICINFO: 'Information',
	AGENTSETTINGS: 'Agent Settings',
	REMOTESETTINGS: 'Remote Settings',
	AGENTDEVICES: 'Agent Devices',
	AGENTNAME: 'Agent Name',
	USERNAME: 'Username',
	USERSETTINGS: 'User Settings',
	USERSAVED: 'User Saved',
	USERACTIVATE: 'Activate User',
	SETNEWPASS: 'Set New Password',
	NEWPASS: 'New Password',
	REPASS: 'Re-enter Password',
	CANCEL: 'Cancel',
	UPDATEUSER: 'Update',
	//AgentSettings
	EMERGENCYCHANNEL: 'Emergency Channel',
	AGENTUISETTINGS: 'Agent UI Settings',
	ALAGENTLIST: 'Agent List',
	ALCHANNELLIST: 'Channel List',
	ALGROUPLIST: 'Group List',
	ALCHATVIEW: 'Chat View',
	ALTASKS: 'Tasks',
	ALMAPS: 'Maps',
	ALINMAPS: 'Indoor Maps',
	ALJOURNAL: 'Journal',
	WEATHERFORECAST: 'Weather Forecast',
	COMMUNICATIONSSETTINGS: 'Communications Settings',
	ALDONOTDIST: 'Do Not Disturb',
	ALHQ: 'HQ',
	ALMAKEDPTT: 'Make Direct PTT',
	ALREDPTT: 'Receive Direct PTT',
	ALMAKEE2EE: 'Make E2EE',
	ALREE2EE: 'Receive E2EE',
	ALMAKEEMERGENCY: 'Make Emergency',
	ALREEMERGENCY: 'Receive Emergency',
	ALMAKEGROUPCALL: 'Make Group Call',
	ALREGROUPCALL: 'Receive Group Call',
	ALMAKEINDCALL: 'Make Individual Call',
	ALREINDCALL: 'Receive Individual Call',
	ALSENDTXT: 'Send Text Messages',
	ALRETXT: 'Receive Text Messages',
	ALSENDATT: 'Send Attachments',
	ALREATT: 'Receive Attachments',
	LOCATIONSETT: 'Location Settings',
	ALLOWRECEIVENAVIGATIONS: 'Receive Navigation',
	ENLOCATSETT: 'Enable Location Sharing',
	LOCSHARINT: 'Location Sharing Interval',
	LOCUPDATEDIST: 'Location Update Distance',
	METERS: 'Meters',
	LOCUPDATEMODE: 'Location Update Mode',
	INTERVAL: 'Interval',
	DISTANCE: 'Distance',
	HYBRID: 'Hybrid',
	HISTORYSETT: 'History Settings',
	MAXHISTITEMS: 'Max History Items',
	ITEMS: 'Items',
	MAXHISTPERIOD: 'Max History Period',
	MIN: 'Min',
	SECONDS: 'Seconds',
	COMMANDCENTERSETT: 'Command Center Settings',
	ALCOMMCENTER: 'Command Center',
	ALMAPTEDITOR: 'Map Tools Editor',
	ALDGNAVIEW: 'DGNA',
	DEVSETT: 'Device Settings',
	DEVICEINFORMATION: 'Device Information',
	//ALDEVINFOSHARING: 'Device Info Sharing',
	//DEVINFOSHARINT: 'Device Info Sharing Interval',
	//Agent Remote Settings
	REMICSETT: 'Remote Microphone Settings',
	ALRMICCONTROL: 'Remote Microphone Control',
	ALRMICSHAR: 'Remote Microphone Share',
	ALRMICNOT: 'Remote Microphone Notification',
	REPICSETT: 'Remote Picture Settings',
	ALRPICCONTROL: 'Remote Picture Control',
	ALRPICSHAR: 'Remote Picture Share',
	ALRPICNOT: 'Remote Picture Notification',
	REVIDSETT: 'Remote Video Settings',
	ALRVIDCONTROL: 'Video Picture Control',
	ALRVIDSHAR: 'Remote Video Share',
	ALRVIDNOT: 'Remote Video Notification',
	DEVICES: 'Devices',
	//AGENTSTABLEHEADER
	NAME: 'Name',
	CURRCHUNNEL: 'Current Channel',
	ISEMERG: 'Emergency',
	DNDIST: 'Do Not Desturb',
	ISONLINE: 'Online',
	LASTACT: 'Last Activity',
	LASTLOC: 'Last Location',

	//AGENT2AGENTSUBS
	SOURCEAGENT: 'Source Agent',
	NONE: '---',
	NEWSUB: 'New Subscription',
	TARGETAGENT: 'Target Agent',
	INDICALL: 'Individual Calls',
	DIRPTT: 'Direct PTT',
	E2EE: 'E2EE',
	MUTE: 'Mute',
	TXTMES: 'Text Messaging',
	ATTACH: 'Attachments',
	RMMICCONT: 'Remote Microphone Control',
	RMPICCONT: 'Remote Picture Control',
	RMVIDCONT: 'Remote Video Control',
	RMMICNOTIF: 'Remote Microphone Notification',
	RMPICNOTIF: 'Remote Picture Notification',
	RMVIDNOTIF: 'Remote Video Notification',
	RMSCREENCONT: 'Remote Screen Control',
	VIEWDEVINFO: 'Device Info',
	ALLIVELOC: 'Live Location',
	ALLHISLOC: 'Location history',

	//AGENT2AGENTSUB
	AGENTSSELECTION: 'Agents Selection',
	PERMIS: 'Permissions',
	RMPERM: 'Remote Permissions',
	MICPERM: 'Microphone Permissions',
	PICPERM: 'Picture Permissions',
	VIDPERM: 'Video Permissions',
	DEVPERM: 'Device Permissions',

	//CHANNELSSUBS
	CHANNELSSUBS: 'Channel Subscriptions',
	CHANNELSSUB: 'Channel Subscription',
	CHANNELSSTATUS: 'Channel Subscriptions Status',
	TARGETCHANN: 'Target Channel',

	//GROUPSSUBS
	ADDNEWGROUP: 'Add New Group',
	GROUPSSUBS: 'Group Subscriptions',
	GROUPSSUB: 'Group Subscription',
	GROUPSSTATUS: 'Group Subscriptions Status',
	TARGETGROUP: 'Target Group',
	AGENT: 'Agent',
	GROUP: 'Group',
	//TABLE
	ALJOIN: 'Join',
	ALSPEAK: 'Speak',
	PRIORITY: 'Priority',
	ALLISTEN: 'Listen',
	//CHANNELSUB
	SELECTION: 'Selection',
	COMMPERM: 'Communication Permissions',
	MSGPERM: 'Messaging Permissions',

	//CHANNELSMNGMENT
	//chanel
	NEWCHANNEL: 'New Channel',
	CHANNELINFO: 'Channel Info',
	CHANNELNAME: 'Channel Name',
	DESCRIPTION: 'Description',
	CHANNCOLOR: 'Channel Color',
	SETTINGS: 'Settings',
	ISDYNAMIC: 'Dynamic',
	ISENABLED: 'Enabled',
	ISMUTED: 'Muted',
	//channels	
	COLOR: 'Color',
	LASTMESSAGE: 'Last Message',
	LASTUPDATE: 'Last Update',
	//channelStatus
	CHANELLSUBSSTAT: 'Channel Subscriptions Status',
	ALWAYSLISTENING: 'Always Listening',
	DEVICE: 'Device',
	SESSION: 'Session',

	//GROUPSMNGMENT
	//GROUP
	NEWGROUP: 'New Group',
	GROUPINFO: 'Group Info',
	GROUPNAME: 'Group Name',
	GROUPCOLOR: 'Group Color',
	//GROUPStatus
	GROUPSUBSSTAT: 'Group Subscriptions Status',

	//TENSETTINGS
	TENANTSETTINGS: 'Tenant Settings',
	ADDNEWSETT: 'Add New',
	KEY: 'Key',
	VALUE: 'Value',
	VALUETYPE: 'Value Type',

	//MAP PROVIDERS
	MAPPROVS: 'Map Providers',
	URI: 'URI',
	SECKEY: 'Secondary Key',
	EXTDATA: 'Extra Data',

	//ADMINS
	ROLES: 'Roles',
	ISACTIVE: 'Active',
	TENANTADMINS: 'Tenant Admins',
	UPDATEADMIN: 'Update Admin',
	CREATENEWADMIN: 'Create New Admin',
	ADMINSETTINGS: 'Admin Settings',

	SETPASS: 'Set Password',
	SETCREDS: 'Set Credentials',
	SETCREDSFOR: 'Set Credentials for ',
	PASS: 'Password',
	AGENTDEVICE: 'Agent Device',
	SUPERADMINS: 'Super Admins',


	IMEI: 'IMEI',
	BRANDNAME: 'Brand Name',
	FIRMW: 'Firmware',
	MODELVER: 'Model Version',
	PLATFORM: 'Platform',
	SERIALN: 'Serial Number',
	SELECTAGENT: 'Select Agent',
	NEWDEVICE: 'New Device',
	ROLE: 'Role',
	TENANTS: 'Tenants',
	TENANT: 'Tenant',
	CREATED: 'Created',
	USERITEMS: 'User Items',
	ISADMIN: 'Admin:',


	//newwww

	INFOS: 'Infos',
	MOREINFO: 'More Info',
	USERS: 'Users',
	MAPSINFO: 'Maps Info',
	AGENTLOCATIONS: 'Agent Locations',
	POIS: 'Pois',
	WAYPOINTS: 'Way Poins',
	GEOFENCES: 'Geofences',
	FILES: 'Files',
	//Chenck
	DEVICEINFO: 'Devices Information',
	CLICKSETTINGS: 'Click for settings',
	SETTINGS: 'Settings',
	V2IMPLEMENTATION: 'Implementation in Version 2.0',
	TOOLTIPS: 'Tooltips',
	BATTERYOPT: 'Battery Optimization',
	MESGES: 'Messages',

	//confirmation dialog
	CONFIRMATIONDIALOGTITLE: 'Action Confirmation',
	DELETESUB: 'Are you sure you wish to delete this Subscription?',
	DELETEADMIN: 'Are you sure you wish to delete this Admin?',
	DELETEMAPPROVIDER: 'Are you sure you wish to delete this Map Provider?',
	DELETETENANTSET: 'Are you sure you wish to delete this TenantSetting?',
	DELETESUPERADMIN: 'Are you sure you wish to delete this Super Admin?',
	DELETEDEVICE: 'Are you sure you wish to delete this Device?',
	DELETEAGENT: 'Are you sure you wish to delete this Agent? Agent Info: { ',
	AGENTSSUB: 'Agent Subs: ',
	CHANNELSSUB: 'Channel Subs: ',
	GROUPSUB: 'Group Subs: ',
	MESSAGES: 'Messages: ',
	DELETECHANNEL: 'Are you sure you wish to delete this Channel? Channel Info: { ',
	DELETEGROUP: 'Are you sure you wish to delete this Group? Group Info: { ',
	DELETETENANT: 'Are you sure you wish to delete this Tenant? Items Info: ',
	//show message
	AGENTSAVED: 'Agent Saved',
	AGENTSSAVED: 'Agents Saved',
	AGENTDELETED: 'Agent Deleted',
	ADMINSAVED: 'Admin Saved',
	ADMINROLEDELETED: 'Admin Role Deleted',
	ADMINDELETED: 'Admin Deleted',
	SUBSAVED: 'Subscription Saved',
	SUBSSAVED: 'Subscriptions Saved',
	SUBDELETED: 'Subscription Deleted',
	DEVICESAVED: 'Device Added',
	DEVICEDELETED: 'Device Deleted',
	CHANNELSAVED: 'Channel Saved',
	CHANNELDELETED: 'Channel Deleted',
	GROUPSAVED: 'Group Saved',
	GROUPDELETED: 'Group Deleted',
	MAPPROVSAVED: 'Map Provider Saved',
	MAPPROVDELETED: 'Map Provider Deleted',
	DELETEDSUPERADMIN: 'Super Admin Deleted',
	SAVEDSUPERADMIN: 'Super Admin Saved',
	TENANTSAVED: 'Tenant Saved',
	TENANTDELETED: 'Tenant Deleted',
	TENANTSETTINGSSAVED: 'Tenant Setting Saved',
	TENANTSETTINGSDELETED: 'Tenant Setting Deleted',


	LICENSEGENINFO: 'License General Information',
	LICENSE: 'License',
	EXPDATE: 'Expiration Date',
	DATE: 'Date',
	TOTALAGENTSALLOWED: 'Total Agents Allowed',
	TOTALUSERSSALLOWED: 'General Users',
	TOTALCCALLOWED: 'Command Centers Users',
	ONLINEAGENTSALLOWED: 'Online Agents Allowed',
	CCONLINEALLOWED: 'Total Command Centers Users Online Allowed',
	AGENTSONLINEALLOWED: 'Total Users Online Allowed',
	AGENTSCREATED: 'Agents Created',
	TOTALAGENTSCREATED: 'Total Agents Created',
	TOTALCCCREATED: 'Total Command Centers Users Created',
	ONLINEUSER: 'Online Users',
	AGENTSONLINE: 'Agents Online',
	CCONLINE: 'Command Center Users Online',
	AGENTSSTATE: 'Agents State',

	ALLOWAGENTPROTECTION: "Agent Protection",
	ALLOWSELFAGENTPROTECTION: "Self Agent Protection",
	ALLOWEDITAGENTPROTECTION: "Edit Agent Protection",
	ENABLECOMMANDCENTER: 'Enable Allow Command Center first',
	ENABLEALLOWAP: 'Enable Allow Agent Protection first',
	ENABLEDGNA: "Enable DGNA Management",
	CHARTS: "Charts-Analytics",
	DISPLAYNAME: 'Display Name',
	ADDNEWMAPPROV: 'Add New Map Provider',
	UPADTEMAPPROV: 'Update Map Provider',
	ADDNEWTENANTSET: 'Add New Tenant Setting',
	UPADTETENANTSET: 'Update Tenant Setting',
	ADDNEWTENANT: 'Add New Tenant',
	UPADTETENANT: 'Update Tenant',
	UPDATEGROUP: 'Update Group',
	ADDNEWGROUP: 'Add New Group',
	UPDATECHANNEL: 'Update Channel',
	ADDNEWCHANNEL: 'Add New Channel',
	CENTERCLIENT: ' Center Client',
	MOBILECLIENT: ' Mobile Client',
	ADMINCLIENT: ' Administration Client',
	POWEREDBYEYEONIX: 'Powered by Eyeonix',
	DOWNLOADEXE: 'Press the download button below, when download completes run the .exe file.',
	DOWNLOADAPK: 'Press the download button below, when download completes run the .apk file in your Android mobile.',
	DOWNLOADADMIN: 'Press the download button below, when the download completes contact our support team.',
	DOWNLOAD: 'Download',
	BASICTENANTINFO: 'Tenant Information',
	DLCLIENTS: "Download Clients",
	ENTERNAME: "Name cannot be empty",
	ENTERDESCR: "Description cannot be empty",
	MAPDATA: "Map Data",
	CREATEMULTIAGENTS: 'Create Multiple Agents',
	AG2AGMULTISUB: 'Mutli Agent to Agents Subscriptions',
	CREATEAG2AGMULTISUB: 'Create Multiple Agent Subscriptions',
	AG2AGESINGLESUB: 'Agent to Agent Subscription',
	CREATEMULTICHANNELSUBS: 'Create Multiple Channel Subscriptions',
	CREATESINGLECHANNELSUB: 'Create Channel Subscription',
	MULTICHANNELSUBS: 'Multi Channel Subscriptions',

	MULTIGROUPSUBS: 'Multi Group Subscriptions',
	MULTISUBS_SUCCESS_CREATED: 'Multiple subscriptions successfully created',
	CREATEMULTIGROUPSUBS: 'Create Multiple Group Subscriptions',
	CREATESINGLEGROUPSUB: 'Create Group Subscription',
	AGENTSLEFT: 'Agents Left',
	CCLEFT: 'CommandCenters left',
	LATITUDE: 'Latitude',
	LONGITUDE: 'Longitude',
	COORDS: 'Coordinates',
	SEARCHINFO: 'Search Information',
	MAPSEARCH: 'Enter address/coordinates',
	CLOSE: 'Close',
	SEARCHTARGETAGENTS: 'Search for Target Agents',
	SEARCHTARGETCHANNELS: 'Search for Target Channels',
	SEARCHTARGETGROUPS: 'Search for Target Groups',
	WORKFLOW: 'FlowLogic',
	WORKFLOWSETTINGS: 'WorkFlow Settings',
	CREATE: 'Create',
	UPDATE: 'Update',
	DELETE: 'Delete',
	WORKFLOWASSINGMENT: 'Workflow Assingment',
	SCREENPERM: 'Screen Permissions',
	COMMUNICATIONPERMISSIONS: 'Communication Permissions',
	DESCRIPTION: 'Description',
	FORCEGPS: 'Force GPS',
	HEALTHVITALSIGNS: 'Health Vital Signs',
	ENABLESHARING: 'Enable Sharing',
	SHARINGINTERVAL: 'Sharing Interval',
	INDIVIDEOCALL: 'Individual Video Calls',
	EDITUSERCREDENTIAL: 'Edit user credential',
	LICENSE_EXCEEDED: "License Exceeded",
	REMAIN: "Remaining",
	COMMANDCENTERS: "Command Centers",
	FROM: "From",
	TO: "To"
};

const locale = {
	...primaryLocale,
	DELETEAGENTTOOLTIP: `${primaryLocale.DELETE} agent`,
	DELETECHANNELSUB: `${primaryLocale.DELETE} channel subscription`,
	DELETEGROUPSUB: `${primaryLocale.DELETE} group subscription`,
	DELETEA2ASUB: `${primaryLocale.DELETE} agent subscription`,
	DELETEAGENTDEVICE: `${primaryLocale.DELETE} agent device`,
	DELETECHANNELTOOLTIP: `${primaryLocale.DELETE} channel`,
	DELETEGROUPTOOLTIP: `${primaryLocale.DELETE} group`,
	DELETETENANTTOOLTIP: `${primaryLocale.DELETE} tenant`,
	DELETEMAPPROVIDERTOOLTIP: `${primaryLocale.DELETE} map provider`,
	DELETETENANTSETTINGTOOLTIP: `${primaryLocale.DELETE} tenant setting`,
	AVAILABLE_CHANNELS: "Available Channels",
	SELECTED_CHANNELS: "Selected Channels",
	AVAILABLE_GROUPS: "Available Groups",
	SELECTED_GROUPS: "Selected Groups",
	AVAILABLE_AGENTS: "Available Agents",
	SELECTED_AGENTS: "Selected Agents",
	DAYS_LEFT: "Days Left",
	EXPIRED: "Expired",
	DAYS_AGO: "Days Ago",
	CC_CLIENT_NOT_AVAILABLE: "Desktop application not available, please contact to your system administrator",
	CM_CLIENT_NOT_AVAILABLE: "Mobile application not available, please contact to your system administrator"



}

export default locale;