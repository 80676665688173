import * as Actions from '../actions';

const initialState = {
	userData: null,
	clearUser: null,
	userCredentialDialogState: false
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_USER: {
			return {
				...state,
				userData: action.payload
			};
		}
		case Actions.CLEAR_USER: {
			return {
				...state,
				userData: null
			};
		}
		case Actions.SAVE_USER: {
			
			return {
				...state,
				userData: action.payload
			};
		}
		case Actions.USER_CREDENTIAL_DIALOG_STATE :{
			
			return{
				...state,
				userCredentialDialogState:action.payload
			}
		}
		case Actions.CLEAR_USER_STORE: {
			return {
				...initialState
			}
		}
		default: {
			return state;
		}
	}
};

export default userReducer;
