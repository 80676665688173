import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	groupsData: null,//akisx
	searchText: '',
	groupData: null,
	groupItemsData: null,
	delGroup: null,
	groupDialogData: {
		state: false,
		id: "new",
		groupName: ""
	},
	groupsMap: null
};

const groupsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_GROUPS: {
			if(action.payload.length > 0) {
				const sortetData = _.sortBy(action.payload,"name")
				//console.log("sort groups by name before save: "+JSON.stringify(sortetData))

				let groupsMap = new Map()
				sortetData.map((group)=>{
					groupsMap.set(group._id, group)
				})
				return {
					...state,
					groupsData: sortetData,
					groupsMap: groupsMap
				};
			}else {

				return {
					...state,
					groupsData: [],
					groupsMap: new Map()
				};
			}
		}
		case Actions.SET_GROUPS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_GROUP: {
			//console.log("Save new agent! "+JSON.stringify(action.payload))
			//console.log("Clear agent list! "+JSON.stringify(state.agentData))
			//console.log("Concat agent! "+JSON.stringify(state.agentData.push(action.payload)))
			return {
				...state,
				groupsData: state.groupsData.concat(action.payload),
				groupsMap: state.groupsMap.set(action.payload._id, action.payload)
			};
		}
		case Actions.GET_GROUP: {
			return {
				...state,
				groupData: action.payload
			};
		}
		case Actions.GET_GROUP_ITEMS: {
			return {
				...state,
				groupItemsData: action.payload
			};
		}
		case Actions.SAVE_GROUP: {
			let data = state.groupsData.filter((data) => data._id !== action.payload._id)
			let data2 = data.concat(action.payload)
			return {
				...state,
				groupData: action.payload,
				groupsData: data2,
				groupsMap: state.groupsMap.set(action.payload._id, action.payload)
			};
		}
		case Actions.GROUPS_DIALOG_DATA : {
			return {
				...state,
				groupDialogData:{
					state:action.payload.state,
					id: action.payload.id,
					groupName: action.payload.groupName
				}				
			}
		}
		case Actions.DELETE_GROUP: {
			
			let newMap = state.groupsMap
			newMap.delete(action.payload._id)
			
			return {
				...state,
				delGroup: action.payload,
				groupData: null,
				groupsData: state.groupsData.filter((data) => data._id !== action.payload._id),
				groupsMap: newMap
			};
		}
		case Actions.CLEAR_GROUPS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default groupsReducer;