import { authRoles } from 'react/app/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

import ar from '../../../main/apps/i18n/ar';
import en from '../../../main/apps/i18n/en';
import tr from '../../../main/apps/i18n/tr';
import gr from '../../../main/apps/i18n/gr';

i18next.addResourceBundle('en', 'manageChannels', en);
i18next.addResourceBundle('tr', 'manageChannels', tr);
i18next.addResourceBundle('ar', 'manageChannels', ar);
i18next.addResourceBundle('gr', 'manageChannels', gr); //Akisx

const ClientsDownloadAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/apps/clientsDownload',
			component: React.lazy(() => import('./ClientsDownload'))
		},
	]
};

export default ClientsDownloadAppConfig;
