import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../main/apps/store/actions';

var isGetChannelsCalled = false;
var prevDGNAManagementState = false;

export const useDGNAChannels = () => {
	const dispatch = useDispatch();
	const dgnaManagementState = useSelector(({ fuse }) => fuse.settings.dgnaManagementState);
	const isAdmin = useSelector(({ auth }) => auth.user?.data?.role === 'admin');

	useEffect(() => {
		if (!isAdmin) {
			isGetChannelsCalled = false;
			return;
		}

		if (!isGetChannelsCalled || dgnaManagementState !== prevDGNAManagementState) {
			prevDGNAManagementState = dgnaManagementState;
			isGetChannelsCalled = true;
			dispatch(Actions.getChannels(dgnaManagementState));
		}
	}, [dgnaManagementState, isAdmin]);

	return null;
};
