import axios from 'axios';
import { showMessage } from 'react/app/store/actions/fuse';

export const GET_MAPPROVIDERS = '[MANAGE-COMMAND APP] GET MAPPROVIDERS'; //akisx
export const SET_MAPPROVIDERS_SEARCH_TEXT = '[MANAGE-COMMAND APP] SET MAPPROVIDERS SEARCH TEXT';
export const NEW_MAPPROVIDER = '[MANAGE-COMMAND APP] NEW MAPPROVIDER'; //akisx

export const GET_MAPPROVIDER = '[MANAGE-COMMAND APP] GET MAPPROVIDER';//akisx
export const SAVE_MAPPROVIDER = '[MANAGE-COMMAND APP] SAVE MAPPROVIDER'; //akisx
export const DELETE_MAPPROVIDER = '[MANAGE-COMMAND APP] DELETE MAPPROVIDER'; //akisxs
export const CLEAR_MPROVIDERS_STORE = '[MANAGE-COMMAND APP] CLEAR MPROVIDERS STORE'; //akisx
export const MAPPROVIDER_DIALOG_DATA = '[MANAGE-COMMAND APP] MAPPROVIDER DIALOG DATA';
export const MULTIMAPPROVIDER_DIALOG_STATUS_STATE = '[MANAGE-COMMAND APP] MULTIMAPPROVIDER DIALOG STATUS STATE';
export const ENABLE_MULTI_IMPORT_MAP_PROVIDERS = '[MANAGE-COMMAND APP] ENABLE MULTI IMPORT MAP PROVIDERS';

export function clearMapProvidersStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_MPROVIDERS_STORE
		})
		
}

export function enableMultiImportMapProviders(state){
	return{
		type: ENABLE_MULTI_IMPORT_MAP_PROVIDERS,
		payload: state
	}
}

export function mapProviderDialogData(state){
	return{
		type: MAPPROVIDER_DIALOG_DATA,
		payload: state
	}
}

export function mulitMapProviderDialogStatusState(state){
	return{
		type: MULTIMAPPROVIDER_DIALOG_STATUS_STATE,
		payload: state
	}
}

export function newMapProvider(data, message) {
	const request = axios.post('EpttaAdmin/MapProviders', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'MapProvider Saved' }));

			return dispatch({
				type: NEW_MAPPROVIDER,
				payload: response.data
			});
		})
}


export function getMapProviders() {

	const request = axios.get(`EpttaAdmin/MapProviders`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_MAPPROVIDERS,
				payload: response.data
			})
		);	
}

export function setMapProvidersSearchText(event) {
	return {
		type: SET_MAPPROVIDERS_SEARCH_TEXT,
		searchText: event.target.value
	};
}

export function getMapProvider(mapProvidersId) {
	const request = axios.get(`EpttaAdmin/MapProviders/${mapProvidersId}`);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_MAPPROVIDER,
				payload: response.data
			})
		);
}

export function saveMapProvider(data, message) {
	const request = axios.put('EpttaAdmin/MapProviders', data);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Map Provider Saved' }));
			//console.log("save response: " +JSON.stringify(response));

			return dispatch({
				type: SAVE_MAPPROVIDER,
				payload: data
			});
		})
}

export function deleteMapProvider(data, message) {
	const request = axios.delete(`EpttaAdmin/MapProviders?id=${data._id}`);

	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message }));
			//dispatch(showMessage({ variant: 'success', message: 'Map Provider Deleted' }));
			//console.log("delete response: " +JSON.stringify(response));

			return dispatch({
				type: DELETE_MAPPROVIDER,
				payload: data
			});
		})
}

export function newMapProviderTemplate(tenant) {
	
	const data = {
		"_id": "new",
		"created": "2020-03-30T12:13:24.854Z",
		"description": "new",
		"extraData": "",
		//"id": "string",
		"isEnabled": true,
		"key": "",
		"lastUpdate": "2020-03-30T12:13:24.854Z",
		"name": "new",
		"secondaryKey": "",
		"tenantId": tenant,
		"uri": ""
	  };

	return {
		type: GET_MAPPROVIDER,
		payload: data
	};
}