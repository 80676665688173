//import FuseUtils from '@fuse/utils';
import { showMessage } from 'react/app/store/actions/fuse';
import axios from 'axios';

export const GET_USER = '[MANAGE-COMMAND APP] GET USER';//akisx
export const CLEAR_USER = '[MANAGE-COMMAND APP] CLEAR USER'
export const SAVE_USER = '[MANAGE-COMMAND APP] SAVE USER'
export const CLEAR_USER_STORE = '[MANAGE-COMMAND APP] CLEAR USER STORE'; //akisx
export const USER_CREDENTIAL_DIALOG_STATE = '[MANAGE-COMMAND APP] USER CREDENTIAL DIALOG STATE'; //akisx


export function clearUserStore() {

	return dispatch =>
		dispatch({
			type: CLEAR_USER_STORE
		})

}

export function userCredentialDialogState(state){
	return {
		type: USER_CREDENTIAL_DIALOG_STATE,
		payload: state
	}
}

export function saveUser(data, message) {
	const request = axios.put('EpttaAdmin/Users', data);
	console.log("User data to save: ", data)
	return dispatch =>
		request.then(response => {
			dispatch(showMessage({ variant: 'success', message: message +" " + data.username.split("@")[0] }));
			return dispatch({
				type: SAVE_USER,
				payload: response.data
			});
		}).catch(e => {
			if (e?.response && e.response?.data) {
				if (e.response.data.error) {
					dispatch(showMessage({ variant: 'error', message: e.response.data.error }));
				} else {
					dispatch(showMessage({ variant: 'error', message: e.response.data }));
				}
				//console.log("SUPER ERRR"+JSON.stringify(e.response.data))
			}
			else {
				dispatch(showMessage({ variant: 'error', message: "Unhandle ERROR" }));
			}
		});
}


export function getUser(params) {
	const request = axios.get(`EpttaAdmin/Users/GetUserById?id=${params.agentId}`);

	return dispatch =>

		request.then(response =>
			dispatch({
				type: GET_USER,
				payload: response.data
			})
		);
}

export function clearUser() {
	return {
		type: CLEAR_USER,
		clearUser: null
	};
}

