//import ChatPanel from 'react/app/fuse-layouts/shared-components/chatPanel/ChatPanel';
//import QuickPanel from 'react/app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import React from 'react';

function RightSideLayout2() {
	return (
		<>
			{/**
			<ChatPanel />

			
			 <QuickPanel /> */}
		</>
	);
}

export default RightSideLayout2;
