import * as Actions from '../actions';
import * as _ from "lodash";

const initialState = {
	channelsData: null,//akisx
	searchText: '',
	channelData: null,
	channelItemsData: null,
	delChannel: null,
	channelDialogData:{
		state: false,
		id: "new",
		channelName: ""
	},
	channelsMap: null
};

const channelsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_CHANNELS: {
			if(action.payload.length > 0) {
				
				//const sortetData = action.payload.sort((a, b) => a.name > b.name)
				let channels =[]
				if(action.dgnaManagementState){
					channels = _.sortBy(action.payload,"name")					
				}else{
					channels = action.payload.filter(channel=>!channel.isDynamic)
				}
				
				const sortetData = _.sortBy(channels,"name")
				let channelsMap = new Map()
				channels.map(channel => {
					channelsMap.set(channel._id, channel)
				})
				//const sortetData = _.sortBy(action.payload,"name")

				//console.log("sort channels by name before save: "+JSON.stringify(sortetData))
				
				return {
					...state,
					channelsData: sortetData,
					channelsMap: channelsMap
				};
			}else {

				return {
					...state,
					channelsData: [],
					channelsMap: new Map()
				};
			}
		}
		case Actions.SET_CHANNELS_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		case Actions.NEW_CHANNEL: {
			return {
				...state,
				channelsData: state.channelsData.concat(action.payload),
				channelsMap: state.channelsMap.set(action.payload._id, action.payload)
			};
		}
		case Actions.GET_CHANNEL: {
			console.log("Get Channel", action.payload)
			return {
				...state,
				channelData: action.payload,
			};
		}
		case Actions.GET_CHANNEL_ITEMS: {
			return {
				...state,
				channelItemsData: action.payload
			};
		}
		case Actions.SAVE_CHANNEL: {
			let data = state.channelsData.filter((data) => data._id !== action.payload._id)
			let data2 = data.concat(action.payload)

			return {
				
				...state,
				channelData: action.payload,
				channelsData: data2,
				channelsMap: state.channelsMap.set(action.payload._id,action.payload)
			};
		}

		case Actions.CHANNEL_DIALOG_DATA: {
			console.log("channel reducer",action.payload)
			// return{
			// 	...state,
			// 	channelDialogStatusState: action.payload.state,
			// 	channelDialogStatusId: action.payload.id,
			// 	cahnnelDialogStatusName :action.payload.channelName
			// }
			return{
				...state,
				channelDialogData: {
					state: action.payload.state,
					id: action.payload.id,
					channelName: action.payload.channelName
				},				
			}
		}
		case Actions.DELETE_CHANNEL: {
			let newMap = state.channelsMap
			newMap.delete(action.payload._id)
			

			return {
				...state,
				delChannel: action.payload,
				channelData: null,
				channelsData: state.channelsData.filter((data) => data._id !== action.payload._id),
				channelsMap: newMap
			};
		}
		case Actions.CLEAR_CHANNELS_STORE: {
			return {
				...initialState
			};
		}
		default: {
			return state;
		}
	}
};

export default channelsReducer;